import React,{useState,useEffect} from 'react'
import {Button,TextField} from '@material-ui/core'
import AutoCompleteSelect from 'components/Select'
import { MuiThemeProvider, createMuiTheme,withStyles,makeStyles
} from '@material-ui/core/styles'
import {getTribe,getEmployee,getClient,getContact} from 'redux/actions/master'
import {setFilesAction,getProjWorkModal,updateParticipant,setAddProjectPayload,getProduct,postProject,postParticipant} from 'redux/actions/files'
import { useDispatch, useSelector } from "react-redux";
import {debounce,isEmpty} from 'lodash'
import {getClient as getForClient,setClientAction,clearState} from 'redux/actions/client'
import InputMask from 'react-input-mask'
import {modalToggleReset} from 'redux/actions/general'
const themeButton = createMuiTheme({ 
    palette: { 
        primary: {
            main:'#ffc466',
            contrastText: '#FFFFFF',
        },
        secondary: {
            main:'#65B7FF',
            contrastText: '#FFFFFF',
        },
    } 
})
const themeField = createMuiTheme({ 
    palette: { 
        primary: {
            main:'#A8E090',
            contrastText: '#FFFFFF',
        },
        secondary: {
            main:'#65B7FF',
            contrastText: '#FFFFFF',
        },
    } 
})
const themeButton2 = createMuiTheme({ 
    palette: { 
        primary: {
            main:'#A8E090',
            contrastText: '#FFFFFF',
        },
        secondary: {
            main:'#65B7FF',
            contrastText: '#FFFFFF',
        },
    } 
})
const useStyles = makeStyles(theme => ({
    textField: {
      [`& fieldset`]: {
        borderRadius: 10,

      },
      width:'100%',
      marginBottom:15,
  },
 
  
}));
export default function Participant(props) {
    const classes=useStyles()
    const dispatch=useDispatch()
    const [search2,setSearch2]=useState('')
    const [textSelect,setTextSelect]=useState('Type company name')
    const [focus,setFocus]=useState({index1:null,index2:null})
    const master=useSelector(state=>state.master)
    const files=useSelector(state=>state.files)
    
    const [loading,setLoading]=useState(false)
    const [participant,setParticipant]=useState([
        {
            clientId:null,
            participant_info:[
                {
                    id:0,
                    participant_name:'',
                    email:'',
                    phone_no:'+62',
                    department:'',
                    position:'',
                }
            ]
        }
    ])
    useEffect(()=>{
        let {modal_data}=props   

        if(modal_data!==null){
            let new_part=[]
            modal_data.map((data,i)=>{
                let new_part_info=[]
                if(isEmpty(data.participants)){
                    new_part_info.push({
                        id:0,
                        participant_name:'',
                        email:'',
                        phone_no:'+62',
                        department:'',
                        position:'',
                    })
                }
                data.participants.map((data2)=>{
                    new_part_info.push({id:data2.id,participant_name:data2.name,email:data2.email,phone_no:data2.phone,department:data2.department,position:data2.position})
                })
                new_part.push({
                    clientId:{label:data.client.text,value:data.client.id},
                    participant_info:new_part_info
                })
            })
            setParticipant(new_part)
            console.log('modal_data', modal_data)
            // participant[0].clientId={label:modal_data.client.text,value:modal_data.client.id}
            // let new_part_info=[]
            // modal_data.participants.map((data)=>{
            //     new_part_info.push({id:data.id,participant_name:data.name,email:data.email,phone_no:data.phone,department:data.department,position:data.position})
            // })
            // participant[0].participant_info=new_part_info
            // setParticipant([...participant])
        }
    },[])
    const handleFocus=(index1,index2)=>{
        let new_part={
            id:0,
            participant_name:'',
            email:'',
            phone_no:'+62',
            department:'',
            position:'',
        }
        if(index2!==focus.index2||index1!==focus.index1){
            setFocus({index1,index2})
            participant[index1].participant_info=[...participant[index1].participant_info,new_part]
            setParticipant([...participant])
        }
        
    }

    const moreCompany=()=>{
        let dum={
            clientId:null,
            participant_info:[
                {
                    id:0,
                    participant_name:'',
                    email:'',
                    phone_no:'+62',
                    department:'',
                    position:'',
                }
            ]
        }
        setParticipant([...participant,dum])
    }
    const searchToggle2=debounce(async (value)=>{
        setSearch2(value)
        // setTextSelect('No options')

        if(value!==search2&&value.length>0){
            // console.log('value eh', value,value.length)
            setLoading(true)
            let res=await dispatch(getClient(props.token,value))
            if(isEmpty(res)){
                setTextSelect('No options')
            }
            setLoading(false)
        }
        
    },1000)
    const onSelectCompany=(data,index)=>{
        participant[index].clientId=data
        setParticipant(participant)

    }
    const onChange=(e,index1,index2)=>{
        let {name,value}=e.target
        participant[index1].participant_info[index2][name]=value
        setParticipant([...participant])
    }
    const onClickSave=()=>{
        if(props.modal_action==='add_participant'){
            let new_part=[]
            participant.map((data)=>{
                if(data.clientId!==''){
                let new_info=[]
                data.participant_info.pop()
                data.participant_info.map((info)=>{
                    // if(info.name!==''||info.email!==''||info.phone!==''||info.department!==)
                    new_info.push({
                        id:0,
                        name:info.participant_name,
                        email:info.email,
                        phone:info.phone_no,
                        department:info.department,
                        position:info.position
                    })
                })
                new_part.push({
                    clientId:data.clientId.value,
                    participants:[...new_info]
                })
            }
            })
            let data={
                projectId:files.project_id,
                userId:props.profile.id,
                add:new_part
            }
            dispatch(postParticipant(props.token,data,null))
        }else{
            let new_part=[]
            participant.map((data)=>{
                if(data.clientId!==''){
                let new_info=[]
                
                // data.participant_info.pop()
                data.participant_info.map((info)=>{
                    if(info.participant_name!==''){
                        new_info.push({
                            id:info.id,
                            name:info.participant_name,
                            email:info.email,
                            phone:info.phone_no,
                            department:info.department,
                            position:info.position
                        })
                    }
                    
                })
                new_part.push({
                    clientId:data.clientId.value,
                    participants:[...new_info]
                })
            }
            })
            let data={
                projectId:files.project_id,
                userId:props.profile.id,
                add:new_part
            }
            console.log('data', data)
            dispatch(updateParticipant(props.token,data,null))
        }
        
    }
    const addClient=async ()=>{
        dispatch(clearState())
        dispatch(setClientAction('add_client'))
        await dispatch(getForClient(props.token,`/0/0/0/1/10/*`))
        dispatch(modalToggleReset())
        dispatch(setFilesAction('add_client','add_project'))
    }
    // console.log('participant', files.project_id)
    return (
        <div className='participant-modal' >
            {participant.map((data,index1)=>(
            <>
            <div style={{width:'50%'}}>
                <div className='div-flex div-space-between div-align-center'>
                    <p className='semi-bold'>Company participant information</p>
                    <MuiThemeProvider theme={themeButton}>
                        <Button onClick={addClient} size='small' variant='text' color='secondary' className='btn-remove-capital'>New client company</Button>
                    </MuiThemeProvider>
                </div>
                <div style={{width:'100%'}}>
                    <AutoCompleteSelect
                         color='primary'
                         noOptionsText={textSelect}
                         loading={loading}
                         disableClearable={true}
                         onChange={(event,value)=>onSelectCompany(value,index1)}
                         options={master.client}
                         getOptionLabel={(option) => option.label}
                         onInputChange={(event,e)=>searchToggle2(e)}
                        label={<>Company name</>}
                        value={participant[index1].clientId}

                    />
                </div>
            </div>
            <p className='semi-bold'>Participant information</p>
            {data.participant_info.map((partinfo,index2)=>(
                <>
                <MuiThemeProvider theme={themeField}>
                <div className='div-flex'>
                <TextField
                    label='Participant name'
                    // type='text'
                    value={participant[index1].participant_info[index2].participant_name}

                    onChange={(e)=>onChange(e,index1,index2)}
                    color='primary'
                    variant='outlined'
                    size='small'
                    name='participant_name'
                    className={classes.textField}

                />&nbsp;&nbsp;
                <TextField
                    label='Email'
                    // type='text'
                    // value={event.address}
                    value={participant[index1].participant_info[index2].email}
                    onChange={(e)=>onChange(e,index1,index2)}
                    color='primary'
                    variant='outlined'
                    size='small'
                    name='email'
                    className={classes.textField}

                />&nbsp;&nbsp;
                <InputMask
                    maskChar={null}
                    mask="(+99)999-9999-9999"
                    value={participant[index1].participant_info[index2].phone_no}
                    onChange={(e)=>onChange(e,index1,index2)}
                >
                {() =>
                        <TextField
                        label='Phone No.'
                        // type='text'
                        // value={event.address}
                        
                        color='primary'
                        variant='outlined'
                        size='small'
                        name='phone_no'
                        className={classes.textField}
    
                    />
                }
                </InputMask>
               &nbsp;&nbsp;
                <TextField
                    label='Department'
                    // type='text'
                    // value={event.address}
                    value={participant[index1].participant_info[index2].department}
                    onChange={(e)=>onChange(e,index1,index2)}
                    color='primary'
                    variant='outlined'
                    size='small'
                    name='department'
                    className={classes.textField}

                />&nbsp;&nbsp;
                <TextField
                    label='Position'
                    // type='text'
                    // value={event.address}
                    value={participant[index1].participant_info[index2].position}
                    onFocus={()=>handleFocus(index1,index2)}
                    onChange={(e)=>onChange(e,index1,index2)}
                    color='primary'
                    variant='outlined'
                    size='small'
                    name='position'
                    className={classes.textField}

                />&nbsp;&nbsp;
            </div>
            </MuiThemeProvider>
            </>
            ))}
            <br/>
            <div style={{borderBottom:'2px dashed #A8E090',width:'100%',position:'relative'}}>
                <MuiThemeProvider theme={themeButton2}>
                <Button style={{position:'absolute',right:0,top:-15}} onClick={()=>moreCompany()} size='small' variant='contained' color='primary' className='btn-remove-capital  btn-rounded'>Add more company</Button>
                </MuiThemeProvider>
            </div>
            <br/>
            </>
            ))}
            <div style={{display:'flex',justifyContent:'flex-end',margin:20}}>
            <MuiThemeProvider theme={themeButton}>
                <Button  onClick={()=>onClickSave()} size='small' variant='contained' color='primary' className='btn-remove-capital  btn-rounded'>Save</Button>
                </MuiThemeProvider>
            </div>
        </div>
    )
}
