import React ,{useEffect,useState}from 'react'
import './style.css'
import {Button, Backdrop} from '@material-ui/core'
import { useDispatch, useSelector } from "react-redux";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {modalToggle} from 'redux/actions/general'
import {getTree,setFile,setFolder,setQuick,setBreadCrum,getProject,viewFile,updateFile,viewVideo} from 'redux/actions/files'
import {isEmpty} from 'lodash'
import EmptyFolder from 'assets/image/EmptyFolder.png'
import Skeleton  from 'react-loading-skeleton';
import general from 'redux/reducers/general';
import moment from 'moment'
import {handleFile,getBase64} from 'components/handleFile'
import { MuiThemeProvider, createMuiTheme,withStyles,
} from '@material-ui/core/styles'
import {getPlatform} from 'redux/actions/master'
import PDFicon from 'assets/icon/PDFicon.svg'
import Allfileicon from 'assets/icon/Allfileicon.svg'
import Musicicon from 'assets/icon/Musicicon.svg'
import Movieicon from 'assets/icon/Movieicon.svg'
import Imageicon from 'assets/icon/Imageicon.svg'
import Wordicon from 'assets/icon/Wordicon.svg'
import PPTicon from 'assets/icon/PPTicon.svg'
import ExcelIcon from 'assets/icon/ExcelIcon.svg'
import CSVicon from 'assets/icon/CSVicon.svg'
const themeButton = createMuiTheme({ 
    palette: { 
        primary: {
            main:'#FF6E79',
            contrastText: '#FFFFFF',
        },
        secondary: {
            main:'#FFC466',
            contrastText: '#FFFFFF',
        }
    } 
})
export default function Folder(props) {
    const files=useSelector(state=>state.files)
    const general=useSelector(state=>state.general)
    const [option_modal,setModal]=useState('')
    const [backdrop,setBackdrop]=useState(false)
    const [active_file,setActiveFile]=useState(null)
    const {folder,bread_crum,active_folder,quick_access,file_access,folder_access}=files
    const dispatch=useDispatch()
    let quick=[]
    let file=[]
    let folder_dum=[]
    useEffect(()=>{
        
    },[])
    const toSubFolder=(data)=>{
        let new_bread=[
            ...bread_crum,
            {
                text:data.name,
                ...data
            }
        ]
        dispatch(getProject(props.token,`/${0}/${data.id}`))
        dispatch(setBreadCrum(new_bread))

    }
    const viewFiles=(id,file)=>{
        if(file.fileType==='mp4'){
            dispatch(viewVideo(props.token,`/4/${id}/${props.profile.id}`))

        }else{
            dispatch(viewFile(props.token,`/4/${id}/${props.profile.id}`))
        }
    }
    const editFolder=async (data)=>{
        let res=await dispatch(getPlatform(props.token))
        if(res){
            dispatch(modalToggle({
                modal_open: true,
                modal_title: "Edit folder",
                modal_component: "add_folder",
                modal_data:data ,
                modal_size:300,
                modal_action:'edit_folder'
            }))
        }
        
    }
    const deleteFileOrFolder=(data)=>{
        dispatch(modalToggle({ 
            modal_open: true,
            modal_title: `File / Folder`,
            modal_component: "confirm_delete",
            modal_size:400,
            modal_type:'confirm',
            modal_data:{
                title:'File / Folder',
                id:data.id,
                userId:props.profile.id,
                msg:`<p>Delete <b>${data.name}</b> will impact to project or workshop connected to <b>${data.name}</b>. make sure you have downloaded file project or workshop.</p>`
            },
            modal_action:'delete_folderfile'
        }))
    }
    const openModal=(id)=>{
        backdropToggle()

        setModal({
            [id]:!option_modal[id]
        })
    }
    const backdropToggle=()=>{
        setBackdrop(!backdrop)
        setModal('')
    }
    const onChangeUpdate=(evt,fileId)=>{
        let handle=handleFile(evt)
        let last_bread=files.bread_crum[files.bread_crum.length-1]

        if(handle.file_error===null){
            getBase64(handle.file,(result)=>{
                let data={
                    fileId:fileId,
                    userId:props.profile.id,
                    filename:handle.file_name,
                    fileBase64:result.split("base64,")[1]
                }
                dispatch(updateFile(props.token,data,`/${0}/${files.bread_crum.length===1?0:last_bread.id}`))
            })
           
        }else{
            console.log('handle.file_error', handle.file_error)
            // setError(handle.file_error)
        }
    }
    const renderIcon=(type)=>{
        switch (type) {
            case 'pdf':
                return PDFicon
                break;
            case 'doc':
                return Wordicon
                break;
            case 'docx':
                return Wordicon
                break;
            case 'ppt':
                return PPTicon
                break;
            case 'pptx':
                return PPTicon
                break;
            case 'xls':
                return ExcelIcon
                break;
            case 'xlsx':
                return ExcelIcon
                break;
            case 'png':
                return Imageicon
                break;
            case 'jpg':
                return Imageicon
                break;
        
            default:
                return Allfileicon
                break;
        }
    }
    const Folder=(props)=>{
        console.log('props', props)
        return(
            <div className='card-access' >
                <div className='card-access-body'>
                    <div onClick={()=>toSubFolder(props.data)} style={{height:200}}>
                    <div className='div-flex div-space-between' >
                        <p  className='card-access-title' style={{margin:0}}>{props.data.name}</p>
                        <div style={{width:30,height:20,borderRadius:5,backgroundColor:'rgb(173, 191, 247)'}}></div>
                    </div>
                    <p className='card-access-subtitle' style={{marginTop:10}}>{props.data.owner}</p>
                    </div>
                    <div>
                    <div className='div-flex div-space-between div-align-center' style={{position:'relative'}}>
                    <p className='card-access-subtitle'>Last update {moment(props.data.date).format('DD MMM YYYY')}</p>
                    <MoreVertIcon onClick={()=>openModal(props.data.id)} color="disabled" style={{cursor:'pointer'}}/>
                    
                    <div className={`option-modal ${option_modal[props.data.id]&&'option-modal-active'}`}>
                        <p className='blue-text' onClick={()=>editFolder(props.data)}>Edit Folder</p>
                        <p className='red-text' onClick={()=>deleteFileOrFolder(props.data)}>Delete Folder</p>
                    </div>
                    </div>
                    </div>
                </div>
            </div>
        )
    }
    const File=(props)=>{
        console.log('props', props)
        return(
            <div className='card-access'>
                <div className='card-access-body'>
                    {/* <div className='div-flex div-space-between' >
                        <p onClick={()=>toSubFolder(props.data)} className='card-access-title' style={{margin:0}}>{props.data.name}</p>
                        <div style={{width:30,height:20,borderRadius:5,backgroundColor:'#ff9aa2'}}></div>
                        
                    </div> */}
                    <div className='div-flex div-space-between' style={{position:'relative'}}>
                        <p  className='card-access-title' style={{margin:0,width:'85%'}} title={props.data.name}>{props.data.name.length>25?`${props.data.name.substring(0,25)}...`:props.data.name}</p>
                        <img style={{width:20,position:'absolute',right:0}} src={renderIcon(props.data.fileType)}/>
                        {/* <div style={{width:30,height:20,borderRadius:5,backgroundColor:'#ff9aa2'}}></div> */}
                    </div>
                    <div>
                    <p className='card-access-subtitle'>{props.data.location}</p>
                    <div className='div-flex div-space-between div-align-center' style={{position:'relative'}}>
                    <p className='card-access-subtitle'>Last update {moment(props.data.date).format('DD MMM YYYY')}</p>
                    
                    </div>
                    </div>
                </div>

                <div style={{width:'100%',height:1,backgroundColor:'#ccc'}}></div>
                <div className='card-access-footer'>
                    {/* <Button onClick={null} size='small' style={{height:30,fontSize:12,margin:0}} color='primary' variant='text' className='remove-capital'>Download</Button> */}
                    <Button onClick={()=>viewFiles(props.data.id,props.data)} size='small' style={{height:30,fontSize:12,margin:0,width:'20px!important'}} color='primary' variant='text' className='remove-capital'>View</Button>
                    <MoreVertIcon color="disabled" onClick={()=>openModal(props.data.id)}/>
                    &nbsp;
                    <div className={`option-modal ${option_modal[props.data.id]&&'option-modal-active'}`}>
                    <MuiThemeProvider theme={themeButton}>
                    <Button component='label' onClick={null} size='small' variant='text' color='secondary' className='btn-remove-capital'>Change File
                    <input
                            type="file"
                            style={{ display: "none" }}
                            onChange={(evt)=>onChangeUpdate(evt,props.data.id)}
                        />
                    </Button>
                    <Button onClick={()=>deleteFileOrFolder(props.data)} size='small' variant='text' color='primary' className='btn-remove-capital'>Delete File</Button>
                        {/* <p className='yellow-text'>Change File
                        <input
                            type="file"
                            style={{ display: "none" }}
                            onChange={onChangeUpdate}
                        />
                        </p>
                        <p className='red-text' onClick={()=>deleteFileOrFolder(props.data)}>Delete File</p> */}
                        </MuiThemeProvider>
                    </div>

                </div>
            </div>
        )
    }
    const Quick=(props)=>{
        console.log('props', props)
        return(
            <div className='card-access'>
                <div className='card-access-body'>
                    {/* <div className='div-flex div-space-between' >
                        <p onClick={()=>toSubFolder(props.data)} className='card-access-title' style={{margin:0}}>{props.data.name}</p>
                        <div style={{width:30,height:20,borderRadius:5,backgroundColor:'#ff9aa2'}}></div>
                    </div> */}
                    <div className='div-flex div-space-between' style={{position:'relative'}}>
                        <p  className='card-access-title' style={{margin:0,width:'85%'}} title={props.data.filename}>{props.data.filename.length>25?`${props.data.filename.substring(0,25)}...`:props.data.filename}</p>
                        <img style={{width:20,position:'absolute',right:0}} src={renderIcon(props.data.fileType)}/>
                        {/* <div style={{width:30,height:20,borderRadius:5,backgroundColor:'#ff9aa2'}}></div> */}
                    </div>
                    <div>
                    <p className='card-access-subtitle'>{props.data.location}</p>
                    <div className='div-flex div-space-between div-align-center' style={{position:'relative'}}>
                    <p className='card-access-subtitle'>Last update {moment(props.data.date).format('DD MMM YYYY')}</p>
                    
                    </div>
                    </div>
                </div>

                <div style={{width:'100%',height:1,backgroundColor:'#ccc'}}></div>
                <div className='card-access-footer'>
                    {/* <Button onClick={null} size='small' style={{height:30,fontSize:12,margin:0}} color='primary' variant='text' className='remove-capital'>Download</Button> */}
                    <Button onClick={()=>openModal(props.data.id)} size='small' style={{height:30,fontSize:12,margin:0,width:'20px!important'}} color='primary' variant='text' className='remove-capital'>View</Button>
                    <MoreVertIcon color="disabled" onClick={()=>openModal(props.data.id)}/>
                    &nbsp;
                    <div className={`option-modal ${option_modal[props.data.id]&&'option-modal-active'}`}>
                        <p className='yellow-text' onClick={()=>alert('asdf')}>Change File</p>
                        <p className='red-text' onClick={()=>deleteFileOrFolder(props.data)}>Delete File</p>
                    </div>

                </div>
            </div>
        )
    }
    const renderLoading=()=>{
        return(
            <div>
                <div style={{marginTop:10}}>
                    <div style={{width:200,marginBottom:10}}><Skeleton height={30}/></div>
                    <div className='grid-access'>
                        <Skeleton height={100}/>
                        <Skeleton height={100}/>
                        <Skeleton height={100}/>
                        <Skeleton height={100}/>
                        <Skeleton height={100}/>
                        <Skeleton height={100}/>
                        <Skeleton height={100}/>
                        <Skeleton height={100}/>
                        
                        
                    </div>
                </div>
                <div style={{marginTop:10}}>
                    <div style={{width:200,marginBottom:10}}><Skeleton height={30}/></div>
                    <div className='grid-access'>
                        <Skeleton height={100}/>
                        <Skeleton height={100}/>
                        <Skeleton height={100}/>
                        <Skeleton height={100}/>
                        <Skeleton height={100}/>
                        <Skeleton height={100}/>
                        <Skeleton height={100}/>
                        <Skeleton height={100}/>
                        
                        
                    </div>
                </div>
            </div>
           
        )
    }
    return (
        <div style={{position:'relative'}}>
            <div onClick={backdropToggle} className='backdrop' style={{display:backdrop?'block':'none'}}></div>
            {isEmpty(quick_access)&&isEmpty(folder_access)&&isEmpty(file_access)&&
            <div  className='empty-folder div-flex div-align-center'>
                <img src={EmptyFolder} style={{width:'50%'}}/>
                <h3>Folder still empty</h3>
                <h6>Start upload your document</h6>
            </div>
            }
            {general.isLoadingTable2?
            renderLoading()
            :
            <>
            {quick_access.length>0&&
            <div>
                <div className='div-flex div-space-between div-align-center access-header'>
                    <p>Quick access</p>
                    <Button onClick={()=>dispatch(setQuick([]))} size='small' style={{height:30}} color='primary' variant='text' className='remove-capital'>Hide</Button>

                </div>
                <div className='grid-access'>
                    {quick_access.map((data,i)=>(
                        <Quick backdropToggle={backdropToggle} key={i} data={data}/>
                    ))}
                </div>
                <br/><br/>
                <div style={{width:'100%',height:1.2,backgroundColor:'rgba(119, 119, 119, 0.5)',margin:'10px 0px 10px 0px'}}></div>
                <br/>

            </div>
            }
            {folder_access.length>0&&
            <div>
                <div className='div-flex div-space-between div-align-center access-header2'>
                    <p>Folders</p>
                    {/* <div className='div-flex'>
                        <Button onClick={null} size='small' style={{height:30}} color='primary' variant='text' className='remove-capital'>Filter</Button>
                        &nbsp;&nbsp;
                        <Button onClick={null} size='small' style={{height:30}} color='primary' variant='text' className='remove-capital'>Short</Button>
                        
                    </div> */}

                </div>
                <div className='grid-access'>
                    {folder_access.map((data,i)=>(
                        <Folder backdropToggle={backdropToggle} key={i} data={data}/>
                    ))}
                </div>
                <br/><br/>
                <div style={{width:'100%',height:1.2,backgroundColor:'rgba(119, 119, 119, 0.5)',margin:'10px 0px 10px 0px'}}></div>
                <br/>
            </div>
            }
            {file_access.length>0&&
            <div>
                <div className='div-flex div-space-between div-align-center access-header2'>
                    <p>Files</p>
                    {/* <div className='div-flex'>
                        <Button onClick={null} size='small' style={{height:30}} color='primary' variant='text' className='remove-capital'>Filter</Button>
                        &nbsp;&nbsp;
                        <Button onClick={null} size='small' style={{height:30}} color='primary' variant='text' className='remove-capital'>Short</Button>
                    </div> */}

                </div>
                <div className='grid-access'>
                    {file_access.map((data,i)=>(
                        <File backdropToggle={backdropToggle} key={i} data={data}/>
                    ))}
                </div>
                <br/><br/>
            </div>
            }
            </>
            }

        </div>
    )
}
