import React,{useState,useEffect} from 'react'
import {Button,TextField,FormControl,InputLabel,OutlinedInput,MenuItem,Select,
    FormHelperText,Modal,FormControlLabel,Checkbox,Table,TableHead,TableRow,TableCell,TableBody} from '@material-ui/core'
import { MuiThemeProvider, createMuiTheme,withStyles,makeStyles } from '@material-ui/core/styles'
import AutoCompleteSelect from 'components/Select'
import {postFolder,putFolder} from 'redux/actions/files'
import {useDispatch,useSelector} from 'react-redux'
import {modalToggleReset} from 'redux/actions/general'
import ChipInput from 'material-ui-chip-input'

const themeField = createMuiTheme({  
    palette: {  
        primary: {
            main:'#a8e090',
            contrastText: '#FFFFFF',

        },
        secondary:{
            main:'#65b7ff',
            contrastText: '#FFFFFF',

        }
    } 
})
const themeButton = createMuiTheme({ 
    palette: { 
        primary: {
            main:'#a8e090',
        },
        secondary:{
            main:'#ffc466',
            contrastText: '#FFFFFF',

        }
    } 
})
const useStyles = makeStyles(theme => ({
    textField: {
      [`& fieldset`]: {
        borderRadius: 10,
      },
      width:'100%',
      marginBottom:15
  },
    textField2: {
      [`& fieldset`]: {
        borderRadius: 10,
      },
      width:'100%',
    //   marginBottom:15
  }
  
}));
export default function Add_folder(props) {
    const classes=useStyles()
    const dispatch=useDispatch()
    const master=useSelector(state=>state.master)
    const files=useSelector(state=>state.files)
    const [state,setState]=useState({
        name:'',
        owner:null,
        deskripsi:'',
        id:0
    })
    useEffect(()=>{
        if(props.modal_data!==null){
            console.log('props.modal_data', props.modal_data)
            setState({
                ...state,
                name:props.modal_data.name,
                owner:{id:props.modal_data.ownerId,text:props.modal_data.owner},
                deskripsi:props.modal_data.description,
                id:props.modal_data.id
            })
                
        }
    },[])
    
    const addFolder=()=>{
        let last_bread=files.bread_crum[files.bread_crum.length-1]
        let data={
            id:state.id,
            name:state.name,
            parentId:files.bread_crum.length===1?0:last_bread.id,
            projectId:0,
            onegml:1,
            userId:props.profile.id,
            ownerId:state.owner.id,
            description:state.deskripsi
        }
        if(props.modal_action==='edit_folder'){
            
            dispatch(putFolder(props.token,data,`/${0}/${files.bread_crum.length===1?0:last_bread.id}`))

        }else{
            dispatch(postFolder(props.token,data,`/${0}/${files.bread_crum.length===1?0:last_bread.id}`))

        }
    }
    const handleDisable=()=>{
        if(state.deskripsi!==''&&state.name!==''&&state.owner!==null){
            return false
        }else{
            return true
        }
    }
    return (
        <div>
             <MuiThemeProvider theme={themeField}>
                    <TextField
                        label='Folder name'
                        type='text'
                        value={state.name}
                        onChange={(e)=>setState({...state,name:e.target.value})}
                        variant='outlined'
                        size='small'
                        className={classes.textField}

                    />
                     <AutoCompleteSelect
                        // multiple
                        onChange={(event,value)=>setState({...state,owner:value})}
                        options={master.platform}
                        getOptionLabel={(option) => option.text}
                        label={<>Owner</>}
                        value={state.owner}

                    />
                    <TextField
                        label='Deskripsi'
                        type='text'
                        value={state.deskripsi}
                        onChange={(e)=>setState({...state,deskripsi:e.target.value})}
                        variant='outlined'
                        size='small'
                        className={classes.textField}

                    />
             </MuiThemeProvider>
             <MuiThemeProvider theme={themeButton}>
                <div className='modal-footer'>
                    <Button disabled={handleDisable()} onClick={addFolder}  size='small' color='secondary' variant='contained' className='btn-remove-capital btn-rounded' style={{width:120,height:30}}>{props.modal_action==='edit_folder'?'Update':'Save'}</Button>
                </div>
            </MuiThemeProvider>
        </div>
    )
}
