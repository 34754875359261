import * as actionTypes from '../constants/files'
import moment from 'moment'
const initialState={
    active_folder:0,
    bread_crum:[],
    folder:[
        
    ],
    side_bar:[
        
    ],
    quick_access:[],
    file_access:[],
    folder_access:[],
    data_project:[],
    last_activity:[],
    files_action:'files',
    files_back:'add_project',
    
    tribe_id:0,
    client_id:0,
    year:moment().format('YYYY'),
    work_proj:[],
    project_info:null,
    active_project:null,
    list_project_for_modal:[],
    list_workshop_for_modal:[],
    work_proj_pagination:null,

    add_project:{
        name:'',
        status:'',
        venue:'',
        startDate:moment(),
        endDate:moment(),
        keyWords:[],
        products:[],
        clientId:null,
        tribeId:null,
        workshopTypeId:null,
        userId:null,
        trainerId:[],
        projectAdvisorId:null,
        projectLeaderId:null,
        projectMemberIds:[],
        clientProjectOwnerId:null,
        clientProjectLeaderId:null,
        clientProjectMemberIds:[],
        clientProjectOproductIdswnerId:[],
    },
    product:[],
    detail_after_post:null,

    upload:[],
    
    project_id:null,
    participant:[]
}

export default (state=initialState, action)=>{
    switch (action.type) {
        case actionTypes.SET_FILE:
            return{
                ...state,
                file_access:action.payload
            }
        case actionTypes.SET_FOLDER:
            return{
                ...state,
                folder_access:action.payload
            }
        case actionTypes.SET_QUICK:
            return{
                ...state,
                quick_access:action.payload
            }
        case actionTypes.SET_BREAD_CRUM:
            return{
                ...state,
                bread_crum:action.payload
            }
        case actionTypes.SET_SIDE_BAR:
            return{
                ...state,
                side_bar:action.payload
            }
        case actionTypes.SET_DATA_PROJECT:
            return{
                ...state,
                data_project:action.payload
            }
        case actionTypes.SET_ACTIVE_FOLDER:
            return{
                ...state,
                active_folder:action.payload
            }
        case actionTypes.SET_FILES_ACTION:
            return{
                ...state,
                files_action:action.payload.tab_active,
                files_back:action.payload.tab_back
            }
        case actionTypes.SET_LAST_ACTIVITY:
            return{
                ...state,
                last_activity:action.payload
            }
        case actionTypes.SET_TRIBE_ID:
            return{
                ...state,
                tribe_id:action.payload
            }
        case actionTypes.SET_CLIENT_ID:
            return{
                ...state,
                client_id:action.payload
            }
        case actionTypes.SET_YEAR:
            return{
                ...state,
                year:action.payload
            }
        case actionTypes.SET_WORK_PROJ:
            return{
                ...state,
                work_proj:action.payload
            }
        case actionTypes.GET_PROJECT_INFO:
            return{
                ...state,
                project_info:action.payload
            }
        case actionTypes.SET_ACTIVE_PROJECT:
            return{
                ...state,
                active_project:action.payload
            }
        case actionTypes.GET_PRODUCT:
            return{
                ...state,
                product:action.payload
            }
        case actionTypes.LIST_PROJECT_FOR_MODAL:
            return{
                ...state,
                list_project_for_modal:action.payload.project,
                work_proj_pagination:action.payload.pagination
            }
        case actionTypes.LIST_WORKSHOP_FOR_MODAL:
            return{
                ...state,
                list_workshop_for_modal:action.payload.workshop,
                work_proj_pagination:action.payload.pagination
            }
        case actionTypes.SET_ADD_PROJECT_PAYLOAD:
            return{
                ...state,
                add_project:{...state.add_project,[Object.keys(action.payload)]:Object.values(action.payload)[0]}
            }
        case actionTypes.DETAIL_AFTER_POST_PROJ_WORK:
            return{
                ...state,
                detail_after_post:action.payload
            }
        case actionTypes.SET_UPLOAD:
            return{
                ...state,
                upload:action.payload
            }
        case actionTypes.SET_PROJECT_ID:
            return{
                ...state,
                project_id:action.payload
            }
        case actionTypes.GET_PARTICIPANT:
            return{
                ...state,
                participant:action.payload
            }
        case actionTypes.RESET_ADD_PROJECT:
            return{
                ...state,
                add_project:{
                    name:'',
                    status:'',
                    venue:'',
                    startDate:moment(),
                    endDate:moment(),
                    keyWords:[],
                    products:[],
                    clientId:null,
                    tribeId:null,
                    workshopTypeId:null,
                    userId:null,
                    trainerId:[],
                    projectAdvisorId:null,
                    projectLeaderId:null,
                    projectMemberIds:[],
                    clientProjectOwnerId:null,
                    clientProjectLeaderId:null,
                    clientProjectMemberIds:[],
                    clientProjectOproductIdswnerId:[],
                }
            }
        default:
            return state;
    }
}