export const MODAL_RESET = "MODAL_RESET";
export const MODAL_SHOW = "MODAL_SHOW";
export const SET_LOADING = "SET_LOADING";
export const SET_LOADING_TABLE = "SET_LOADING_TABLE";
export const SET_LOADING_TABLE2 = "SET_LOADING_TABLE2";
export const SET_LOADING_TABLE3 = "SET_LOADING_TABLE3";
export const SET_LOADING_SEARCH = "SET_LOADING_SEARCH";

export const SET_ERROR = "SET_ERROR";
export const ALERT_RESET = "ALERT_RESET";
export const ALERT_SHOW = "ALERT_SHOW";