import * as actionTypes from '../constants/master'

const getTabByUrl=(url)=>{
    switch (url) {
        case '/onegml':
            return 'onegml'
            break;
        default:
            break;
    }
}
const initialState={
    rm:[],
    rm_full:[],
    rm_text:[],
    segments:[],
    tribes:[],
    branches:[],
    stages:[],
    proposalTypes:[],
    client:[],
    contact:[],
    employee:[],
    proposalTypes:[],
    detail_client:null,
    tab_active:getTabByUrl(window.location.pathname),
    tab_back:getTabByUrl(window.location.pathname),

    bread_crum:[],
    platform:[],

    topic:[]
}
export default (state=initialState,action)=>{
    switch (action.type) {
        case actionTypes.GET_MASTER_DATA:
            return{
                ...state,
                rm:action.payload.rm,
                rm_full:action.payload.rm_full,
                segments:action.payload.segments,
                tribes:action.payload.tribes,
                branches:action.payload.branches,
                stages:action.payload.stages,
                proposalTypes:action.payload.proposalTypes,
                rm_text:action.payload.rm_text
            }
        case actionTypes.GET_MASTER_DATA:
            return{
                ...state,
                rm:action.payload,
               
            }
        case actionTypes.GET_CONTACT:
            return{
                ...state,
                contact:action.payload,
               
            }
        case actionTypes.GET_DETAIL_CLIENT:
            return{
                ...state,
                detail_client:action.payload,
               
            }
        case actionTypes.GET_CLIENT_SEARCH:
            return{
                ...state,
                client:action.payload,
               
            }
        case actionTypes.SET_TAB:
            return{
                ...state,
                tab_active:action.payload.tab_active,
                tab_back:action.payload.tab_back,
            }
        case actionTypes.GET_EMPLOYEE:
            return{
                ...state,
                employee:action.payload
            }
        case actionTypes.GET_TOPIC:
            return{
                ...state,
                topic:action.payload
            }
        case actionTypes.SET_BREAD_CRUM:
            return{
                ...state,
                bread_crum:action.payload
            }
        case actionTypes.GET_TRIBE:
            return{
                ...state,
                tribes:action.payload
            }
        case actionTypes.GET_PLATFOFRM:
            return{
                ...state,
                platform:action.payload
            }
        default:
            return state;
    }
}