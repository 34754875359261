import React from 'react'
import { Player } from 'video-react';
import "video-react/dist/video-react.css";
export default function View_video(props) {
    return (
        <div>
            <Player
            // height={200}
            // fluid={false}
      playsInline
    //   poster={Dumm}
      src={props.modal_data.url}
    />
    <br/>
        </div>
    )
}
