import React,{useState,useEffect} from 'react'
import './style.css'
import { MuiThemeProvider, createMuiTheme,withStyles,makeStyles
} from '@material-ui/core/styles'
import {Button,Modal,TextField,FormControl,InputLabel,OutlinedInput,MenuItem,Select,
    FormHelperText } from '@material-ui/core'
import AutoCompleteSelect from 'components/Select'
import {
    DatePicker,
    TimePicker,
    DateTimePicker,
    MuiPickersUtilsProvider,
    } from '@material-ui/pickers';
    import MomentUtils from '@date-io/moment';
import Upload from './upload_file'
import {modalToggle} from 'redux/actions/general'
import {getTribe,getEmployee,getClient,getContact} from 'redux/actions/master'
import {setFilesAction,getProjWorkModal,getProject,putProject,setProjectId,setAddProjectPayload,getProduct,postProject,setUpload} from 'redux/actions/files'
import { useDispatch, useSelector } from "react-redux";
import {debounce,isEmpty} from 'lodash'
import {getClient as getForClient,setClientAction,clearState} from 'redux/actions/client'
import ReactTag from 'components/ReactTag'
import ChipInput from 'material-ui-chip-input'
const themeButton2 = createMuiTheme({ 
    palette: { 
        primary:{
            main:'#ffc466',
            contrastText: '#FFFFFF',
        },
        secondary:{
            main:'#65b7ff',
            contrastText: '#FFFFFF',
        },

    } 
})

const useStyles = makeStyles(theme => ({
    textField: {
      [`& fieldset`]: {
        borderRadius: 10,

      },
      width:'100%',
      marginBottom:15,
  },
 
  
}));
export default function Project(props) {
    const classes=useStyles()
    const dispatch=useDispatch()
    const [tab,tabActive]=useState('info')
    const [search2,setSearch2]=useState('')
    const [loading,setLoading]=useState(false)
    const [textSelect,setTextSelect]=useState('Type company name')
    const master=useSelector(state=>state.master)
    const files=useSelector(state=>state.files)
    const {add_project}=files
    useEffect(()=>{
        dispatch(getEmployee(props.token))
        dispatch(getTribe(props.token))
        dispatch(getProduct(props.token))
    },[])
    const tabToggle=(slug)=>{
        tabActive(slug)
    }
    const chooseProject=async ()=>{
        
        
            dispatch(modalToggle({ 
                modal_open: true,
                modal_title: "Select project",
                modal_component: "choose_project",
                modal_size:800,
                modal_action:'choose_project'
            })) 
        
        
    }
    const newClient=async ()=>{
        dispatch(clearState())
        dispatch(setClientAction('add_client'))
        await dispatch(getForClient(props.token,`/0/0/0/1/10/*`))
        props.tabToggle('add_client','add_project')
    }
    const searchToggle2=debounce(async (value)=>{
        setSearch2(value)
        // setTextSelect('No options')

        if(value!==search2&&value.length>0){
            // console.log('value eh', value,value.length)
            setLoading(true)
            let res=await dispatch(getClient(props.token,value))
            if(isEmpty(res)){
                setTextSelect('No options')
            }
            setLoading(false)
        }
        
    },1000)
    const onSelectCompany=(data)=>{
        dispatch(setAddProjectPayload('clientId',data))

        dispatch(getContact(props.token,data.value))
    }
    
    const deleteKeyword=(chip,index)=>{
        let filter=add_project.keyWords.filter((data)=>{
            return data!==chip
        })
        dispatch(setAddProjectPayload('keyWords',filter))
    }
    const handleDisable=()=>{
        if(add_project.clientId!==null&&add_project.tribeId!==null&&add_project.name!==''){
            return false
        }else{
            return true
        }
    }
    const addProject=async ()=>{
        console.log('files.project_id', files.project_id)
        let new_project_member_id=[]
        let new_clientProjectMemberIds=[]
        let newProd=[]
        add_project.projectMemberIds.map((data)=>{
            new_project_member_id.push(data.value)
        })
        add_project.clientProjectMemberIds.map((data)=>{
            new_clientProjectMemberIds.push(data.id)
        })
        add_project.products.map((data)=>{
            newProd.push(data.id)
        })
        if(files.project_id!==null){
            let data={
                id:files.project_id,
                name:add_project.name,
                status:add_project.status,
                venue:add_project.venue,
                startDate:add_project.startDate.format('YYYY-MM-DD'),
                endDate:add_project.endDate.format('YYYY-MM-DD'),
                keyWords:add_project.keyWords,
                clientId:add_project.clientId.value,
                tribeId:add_project.tribeId.id,
                workshopTypeId:0,
                userId:props.profile.id,
                trainerIds:[],
                projectAdvisorId:add_project.projectAdvisorId!==null?add_project.projectAdvisorId.value:0,
                projectLeaderId:add_project.projectLeaderId!==null?add_project.projectLeaderId.value:0,
                projectMemberIds:new_project_member_id,
                clientProjectOwnerId:add_project.clientProjectOwnerId!==null?add_project.clientProjectOwnerId.id:0,
                clientProjectLeaderId:add_project.clientProjectLeaderId!==null?add_project.clientProjectLeaderId.id:0,
                clientProjectMemberIds:new_clientProjectMemberIds,
                productIds:newProd
            }
            await dispatch(putProject(props.token,data))
            let res=await dispatch(getProject(props.token,`/${files.project_id}/${0}`))
            if(res){
                let new_upload=[]
                res.content.folders.map((data,i)=>{
                    new_upload.push({
                        files:{name:data.name},
                        type:'folder',
                        id:data.id,
                        parent:0,
                        exist:true
                    })
                })
                res.content.files.map((data,i)=>{
                    new_upload.push({
                        files:{name:data.name},
                        type:'file',
                        parent:0,
                        exist:true,
                        id:data.id
                    })
                })
                dispatch(setUpload(new_upload))
                tabToggle('upload')

            }
        }else{

        let data={
            name:add_project.name,
            status:add_project.status,
            venue:add_project.venue,
            startDate:add_project.startDate.format('YYYY-MM-DD'),
            endDate:add_project.endDate.format('YYYY-MM-DD'),
            keyWords:add_project.keyWords,
            clientId:add_project.clientId.value,
            tribeId:add_project.tribeId.id,
            workshopTypeId:0,
            userId:props.profile.id,
            trainerIds:[],
            projectAdvisorId:add_project.projectAdvisorId!==null?add_project.projectAdvisorId.value:0,
            projectLeaderId:add_project.projectLeaderId!==null?add_project.projectLeaderId.value:0,
            projectMemberIds:new_project_member_id,
            clientProjectOwnerId:add_project.clientProjectOwnerId!==null?add_project.clientProjectOwnerId.id:0,
            clientProjectLeaderId:add_project.clientProjectLeaderId!==null?add_project.clientProjectLeaderId.id:0,
            clientProjectMemberIds:new_clientProjectMemberIds,
            productIds:newProd
        }
        
        let res=await dispatch(postProject(props.token,data))
        if(res){
            dispatch(setProjectId(res.project.id))
            tabToggle('upload')

        }
        }

    }
    // console.log('master.employee',add_project)
    return (
        <div>
            <div className='add-file-grid'>
                <div>
                    <div className='card-content' style={{padding:20,minHeight:100}}>
                    {tab==='info'&&
                        <>
                        <div className='div-flex div-space-between div-align-center' style={{marginBottom:10}}>
                            <p className='semi-bold'>Project information</p>
                            <MuiThemeProvider theme={themeButton2}>
                                <Button onClick={chooseProject} size='small' variant='text' color='secondary' className='btn-remove-capital'>Choose project</Button>
                            </MuiThemeProvider>
                        </div>
                       
                        
                        <TextField
                            label='Project name'
                            // type='text'
                            value={add_project.name}

                            onChange={(e)=>dispatch(setAddProjectPayload('name',e.target.value))}
                            color='primary'
                            variant='outlined'
                            size='small'
                            name='name'
                            className={classes.textField}

                        />
                        <div className='div-flex div-space-between'>
                            <div style={{width:'49%'}}>
                            <AutoCompleteSelect
                                onChange={(event,value)=>dispatch(setAddProjectPayload('tribeId',value))}
                                options={master.tribes}
                                value={add_project.tribeId}
                                getOptionLabel={(option) => option.text}
                                label={<>Tribe</>}

                            />
                            </div>
                            <div style={{width:'49%'}}>
                            <FormControl style={{width:'100%'}} variant="outlined" size="small" className='add-proposal__field' >
                                <InputLabel  htmlFor="category">Status</InputLabel>
                                <Select  color='primary' name='segmentId'  value={files.add_project.status}  onChange={(e)=>dispatch(setAddProjectPayload('status',e.target.value))} labelId="label" id="select"  labelWidth={70} className='field-radius'>
                                    <MenuItem value="Open">Open</MenuItem>
                                    <MenuItem value="Closed">Close</MenuItem>
                                </Select>
                            </FormControl>
                            </div>
                        </div>
                        
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                        <div className='div-flex div-space-between'>
                            <div style={{width:'49%'}}>
                            <DatePicker format={'DD MMM YYYY'} color='primary' value={add_project.startDate} onChange={(data)=>dispatch(setAddProjectPayload('startDate',data))}  className={classes.textField}  label='Start date' clearable={true} size='small' inputVariant='outlined'  />
                            </div>
                            <div style={{width:'49%'}}>
                            <DatePicker format={'DD MMM YYYY'} color='primary' value={add_project.endDate} onChange={(data)=>dispatch(setAddProjectPayload('endDate',data))}  className={classes.textField}  label='End date' clearable={true} size='small' inputVariant='outlined'  />
                            </div>
                        
                        

                        </div>
                        </MuiPickersUtilsProvider>
                        <AutoCompleteSelect
                                onChange={(event,value)=>dispatch(setAddProjectPayload('products',value))}
                                options={files.product}
                                value={add_project.products}
                                getOptionLabel={(option) => option.text}
                                label={<>Products</>}
                                multiple
                            />
                        <ChipInput
                            label="Keyword"
                            value={add_project.keyWords}
                            onAdd={(chip) =>dispatch(setAddProjectPayload('keyWords',[...add_project.keyWords,chip]))}
                            // onAdd={(chip) =>console.log('chip', chip)}
                            onDelete={(chip, index) => deleteKeyword(chip,index)}
                            variant="outlined"
                            className={classes.textField}
                        />
                        <div className='div-flex div-space-between div-align-center' style={{marginBottom:10}}>
                            <p className='semi-bold'>Project team member</p>
                           
                        </div>
                        <div className='div-flex div-space-between'>
                            <div style={{width:'49%'}}>
                            <AutoCompleteSelect
                                onChange={(event,value)=>dispatch(setAddProjectPayload('projectAdvisorId',value))}
                                options={master.employee}
                                value={add_project.projectAdvisorId}
                                getOptionLabel={(option) => option.label}
                                label={<>Project advisor</>}

                            />
                            </div>
                            <div style={{width:'49%'}}>
                            <AutoCompleteSelect
                                onChange={(event,value)=>dispatch(setAddProjectPayload('projectLeaderId',value))}
                                options={master.employee}
                                value={add_project.projectLeaderId}
                                getOptionLabel={(option) => option.label}
                                label={<>Project leader</>}

                            />
                            </div>
                            
                        </div>
                        <div style={{width:'100%'}}>
                            <AutoCompleteSelect
                                onChange={(event,value)=>dispatch(setAddProjectPayload('projectMemberIds',value))}
                                options={master.employee}
                                value={add_project.projectMemberIds}
                                getOptionLabel={(option) => option.label}
                                label={<>Project member</>}
                                multiple
                            />
                        </div>
                        <div className='div-flex div-space-between div-align-center' style={{marginBottom:10}}>
                            <p className='semi-bold'>Client information</p>
                            <MuiThemeProvider theme={themeButton2}>
                                <Button onClick={newClient} size='small' variant='text' color='secondary' className='btn-remove-capital'>Add new client</Button>
                            </MuiThemeProvider>
                        </div>
                        <div style={{width:'100%'}}>
                        <AutoCompleteSelect
                            color='primary'
                            noOptionsText={textSelect}
                            loading={loading}
                            disableClearable={true}
                            onChange={(event,value)=>onSelectCompany(value)}
                            options={master.client}
                            getOptionLabel={(option) => option.label}
                            onInputChange={(event,e)=>searchToggle2(e)}
                            label={<>Client company name</>}
                            value={add_project.clientId}
                        />
                        </div>
                        <div className='div-flex div-space-between'>
                            <div style={{width:'49%'}}>
                            <AutoCompleteSelect
                                onChange={(event,value)=>dispatch(setAddProjectPayload('clientProjectOwnerId',value))}
                                options={master.contact}
                                value={add_project.clientProjectOwnerId}
                                getOptionLabel={(option) => option.text}
                                label={<>Client project owner name</>}

                            />
                            </div>
                            <div style={{width:'49%'}}>
                            <AutoCompleteSelect
                                onChange={(event,value)=>dispatch(setAddProjectPayload('clientProjectLeaderId',value))}
                                options={master.contact}
                                value={add_project.clientProjectLeaderId}
                                getOptionLabel={(option) => option.text}
                                label={<>Client project leader name</>}

                            />
                            </div>
                            
                        </div>
                        <div style={{width:'100%'}}>
                            <AutoCompleteSelect
                                onChange={(event,value)=>dispatch(setAddProjectPayload('clientProjectMemberIds',value))}
                                options={master.contact}
                                value={add_project.clientProjectMemberIds}
                                getOptionLabel={(option) => option.text}
                                label={<>Client project member</>}
                                multiple
                            />
                        </div>
                        <div style={{display:'flex',justifyContent:"flex-end"}}>
                        <MuiThemeProvider theme={themeButton2}>
                        <Button disabled={handleDisable()} onClick={()=>addProject()} size='small' variant='contained' color='primary' className='btn-remove-capital  btn-rounded'>Go to upload file</Button>

                        </MuiThemeProvider>
                        </div>
                        </>
                        }
                        {tab==='upload'&&<Upload token={props.token} profile={props.profile}  tabToggle={tabToggle}/>}
                    </div>
                </div>
                <div style={{paddingLeft:20}}>
                    <div className='add-file-timeline-container'>
                        <div className={`add-file-timeline-item ${tab==='info'||files.project_id!==null?'timeline-active':''}`}>
                            <h2>Complete project information and client</h2>
                            <p>Before Upload file you must define project . Name of project, Tribe in charge, etc.</p>
                        </div>
                        <div className={`add-file-timeline-item ${tab==='upload'&&'timeline-active'}`}>
                            <h2>Upload file</h2>
                            <p>Upload file project, in case the project have more then one workshop just create new folder.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
