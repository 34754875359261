import { combineReducers } from "redux";
import  general from './general'
import client from './client'
import master from './master'
import files from './files'
import insight from './insight'
import search from './search'
export default combineReducers({
    general,
    client,
    master,
    files,
    insight,
    search
})