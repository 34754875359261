import "./style.css";
import handshake from "assets/icon/handshake.svg";
import Institution from "assets/icon/Institution.svg";
import Storage from "assets/icon/Storage.svg";
import ChevronDown from "assets/icon/ChevronDown.svg";
import Folder from "assets/icon/Folder.svg";
import Publication from "assets/icon/Publication.svg";
import Insight from "assets/icon/Insight.svg";
import {
  getTree,
  getQuick,
  setSideBar,
  setBreadCrum,
  setActiveFolder,
  setFilesAction,
  getFilterProject,
  setTribeId,
  setClientId,
  setYear,
  setFile,
  setFolder,
  setActiveProject,
  setProjectId,
  resetAddProject,
  setQuick,
} from "redux/actions/files";
import { useDispatch, useSelector } from "react-redux";

import React, { useEffect, useState } from "react";
import { Button, Collapse } from "@material-ui/core";
import Plus from "assets/icon/Plus.svg";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import * as actionType from "redux/constants/files";
import { withRouter } from "react-router-dom";
import {
  disableBodyScroll,
  enableBodyScroll,
  clearAllBodyScrollLocks,
} from "body-scroll-lock";
function Index(props) {
  const dispatch = useDispatch();
  const files = useSelector((state) => state.files);
  const general = useSelector((state) => state.general);
  const [activeSide, setActiveSide] = useState([]);
  const [activeParent, setActiveParent] = useState(null);
  const [modal_add, setModal] = useState(false);
  const [publication_collapse, setPublicationCollapse] = useState(false);
  const { side_bar, tribe_id, client_id, year } = files;
  let bread_crum = [];
  let targetElement = null;
  useEffect(() => {
    let url = props.location.pathname;
    let publication = url.includes("/publication");
    setPublicationCollapse(publication);
    if (side_bar.length < 1) {
      getTrees();
    }
    if (files.bread_crum.length > 0) {
      if (files.bread_crum.length < 4) {
        if (files.bread_crum[0].text !== "ONE GML") {
          // const element= document.getElementById(files.bread_crum[files.bread_crum.length-1].slug);
          // console.log('element', element)
          // element.scrollIntoView({behavior: 'smooth',block:'nearest'});
          // element.scrollIntoView(false);
        }
      } else {
        // const element= document.getElementById(files.bread_crum[1].slug);
        // element.scrollIntoView({behavior: 'smooth',block:'nearest'});
        // element.scrollIntoView(false);
      }
    }
  }, [files.bread_crum]);
  const getTrees = async () => {
    let res = await dispatch(getTree(props.token));
    if (res) {
      let slug = decodeURI(props.location.hash.substring(1));
      let find_tree = res.filter((data) => {
        return data.slug === slug.toString();
      });
      if (slug === "onegml-folder") {
        openOneGML();
      }
      if (find_tree.length > 0) {
        clickMenu(find_tree[0], find_tree[0].slug, "");
      }
    }
  };
  const renderLoading = () => {
    return (
      <>
        <div style={{ margin: "10px 0px 10px 0px" }}>
          <Skeleton height={35} />
        </div>
        <div style={{ margin: "10px 0px 10px 0px" }}>
          <Skeleton height={35} />
        </div>
        <div style={{ margin: "10px 0px 10px 0px" }}>
          <Skeleton height={35} />
        </div>
        <div style={{ margin: "10px 0px 10px 0px" }}>
          <Skeleton height={35} />
        </div>
      </>
    );
  };

  let last_bread = files.bread_crum[files.bread_crum.length - 1];
  let findThree = (id) => {
    let filter = side_bar.filter((data) => {
      return data.id_sidebar === id;
    });
    return filter[0];
  };

  const renderActive = (data) => {
    if (files.bread_crum.length > 2) {
      if (data.slug === files.bread_crum[2].slug) {
        return true;
      } else {
        return false;
      }
    } else {
      if (Object.is(data, last_bread)) {
        return true;
      } else {
        return false;
      }
    }
  };
  const renderOpen = (data) => {
    let filter = files.bread_crum.filter((filter) => {
      return filter.slug === data.slug;
    });
    if (filter.length > 0) {
      return true;
    } else {
      return false;
    }
  };
  const clickMenu = async (data, slug, parentSlug) => {
    props.searchToggle(false);
    dispatch(setActiveProject(null));
    dispatch(setProjectId(null));
    dispatch(setFilesAction("files", ""));
    dispatch(setFile([]));
    dispatch(setFolder([]));
    if (files.bread_crum.length > 0) {
      if (files.bread_crum[0].slug === slug) {
        dispatch(setTribeId(0));
        dispatch({
          type: actionType.SET_WORK_PROJ,
          payload: [],
        });
        dispatch(setBreadCrum([]));
      } else {
        dispatch(setTribeId(data.id));
        dispatch(getFilterProject(props.token, `/${data.id}/${0}/${0}/0`));
        dispatch(setBreadCrum([data]));
      }
    } else {
      dispatch(setTribeId(data.id));
      dispatch(getFilterProject(props.token, `/${data.id}/${0}/${0}/0`));
      dispatch(setBreadCrum([data]));
    }
  };
  const clickMenu2 = (data, slug, parentSlug) => {
    dispatch(setActiveProject(null));
    props.searchToggle(false);
    dispatch(setFile([]));
    dispatch(setFolder([]));
    dispatch(setClientId(data.id));
    dispatch(setFilesAction("files", ""));

    let filter = files.bread_crum.filter((filter) => {
      return filter.slug === slug;
    });
    if (filter.length > 0) {
      dispatch(getFilterProject(props.token, `/${tribe_id}/${0}/${0}/0`));
      dispatch(setBreadCrum([files.bread_crum[0]]));
    } else {
      dispatch(getFilterProject(props.token, `/${tribe_id}/${data.id}/${0}/0`));
      dispatch(setBreadCrum([files.bread_crum[0], data]));
    }
  };
  const openSubChildren2 = (data) => {
    dispatch(setActiveProject(null));
    dispatch(setFilesAction("files", ""));

    dispatch(setFile([]));
    dispatch(setFolder([]));
    dispatch(setYear(data.text));
    dispatch(
      getFilterProject(props.token, `/${tribe_id}/${client_id}/${data.text}/0`)
    );

    findThree(data.id_sidebar).open_children = !findThree(data.id_sidebar)
      .open_children;
    bread_crum = [files.bread_crum[0], files.bread_crum[1], data];
    dispatch(setSideBar(side_bar));
    dispatch(setActiveFolder(data.id_sidebar));

    dispatch(setBreadCrum(bread_crum));
  };
  const modalToggle = () => {
    setModal(!modal_add);
  };
  const addFile = (slug) => {
    if (window.location.pathname !== "/") {
      window.location.assign("/");
    } else {
      dispatch(setProjectId(null));
      dispatch(resetAddProject());
      dispatch(setQuick([]));
      dispatch({
        type: actionType.SET_WORK_PROJ,
        payload: [],
      });
      dispatch(setFilesAction(slug));
      modalToggle();
    }
  };
  const openOneGML = async () => {
    props.searchToggle(false);
    if (window.location.pathname !== "/") {
      window.location.replace("/#onegml-folder");
    } else {
      dispatch(
        setBreadCrum([
          { id: 1, text: "ONE GML", children: [], slug: "onegml-folder" },
        ])
      );
      dispatch(setFilesAction("onegml", ""));
    }
  };

  const getUrl = (slug) => {
    if (window.location.pathname !== "/") {
      return `/#${slug}`;
    } else {
      return `#${slug}`;
    }
  };
  return (
    <div className="sidebar-wrapper">
      <div className="sidebar-item">
        <div
          onClick={() => setModal(false)}
          style={{
            width: 500,
            height: "100vh",
            position: "absolute",
            zIndex: 2,
            display: modal_add ? "block" : "none",
          }}
        ></div>
        <div
          style={{ position: "fixed", backgroundColor: "white", zIndex: 299 }}
        >
          <div style={{ margin: "0px 30px 15px 25px", position: "relative" }}>
            <Button
              onClick={() => modalToggle()}
              size="small"
              variant="contained"
              className="btn-remove-capital btn-rounded remove-boxshadow"
              style={{
                color: "white",
                backgroundColor: "#ffc466",
                width: 160,
                height: 33,
              }}
            >
              <img src={Plus} />
              &nbsp;&nbsp; Add new file
            </Button>
            <div className={`modal-add-file ${!modal_add && "modal-hide"}`}>
              <div className="modal-add-title">
                <h3>Add new file for</h3>
              </div>
              <div className="modal-add-content">
                <div className="modal-add-item">
                  <p onClick={() => addFile("add_project")}>Project</p>
                </div>
                <div className="modal-add-item">
                  <p onClick={() => addFile("add_workshop")}>Workshop</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div style={{ paddingTop: 40 }}>
          {general.isLoadingTable
            ? renderLoading()
            : side_bar.map((data, i) => {
                return (
                  <div key={i}>
                    <a
                      href={getUrl(data.slug)}
                      style={{ textDecoration: "none" }}
                    >
                      <div
                        onClick={() => clickMenu(data, data.slug, "")}
                        className={
                          renderActive(data)
                            ? "active-sidebar div-flex div-space-between "
                            : "list-sidebar div-flex div-space-between div-align-center"
                        }
                      >
                        <div className="div-flex div-align-center">
                          <img src={Storage} className="icon-size" />
                          &nbsp;&nbsp;
                          <a href={getUrl(data.slug)}>{data.text}</a>
                        </div>
                        &nbsp;
                        <img
                          src={ChevronDown}
                          style={{ width: 18 }}
                          className={`chevron-down ${
                            renderOpen(data) && "open"
                          }`}
                        />
                      </div>
                    </a>
                    <Collapse in={renderOpen(data)}>
                      {data.children.map((child, i2) => {
                        return (
                          <div className={`subfolder `} key={i2}>
                            <div
                              onClick={() =>
                                clickMenu2(child, child.slug, data.slug)
                              }
                              className={`div-flex div-space-between div-align-center subfolder-item ${
                                renderActive(child) && "active-subfolder"
                              }`}
                            >
                              <div className="div-flex div-align-center">
                                <img src={Folder} className="icon-size" />
                                &nbsp;&nbsp;
                                <a href={`#${child.slug}`}>{child.text}</a>
                              </div>
                              &nbsp;
                              <img
                                src={ChevronDown}
                                style={{ width: 18 }}
                                className={`chevron-down ${
                                  renderOpen(child) && "open"
                                }`}
                              />
                            </div>
                            <Collapse in={renderOpen(child)}>
                              {child.children.map((subchild, i3) => {
                                // console.log('sub', subchild)

                                // console.log('subchild', subchild)
                                return (
                                  <div className="subfolder" key={i3}>
                                    <div
                                      onClick={() =>
                                        openSubChildren2(subchild, i2, i3)
                                      }
                                      className={`div-flex div-space-between div-align-center subfolder-item ${
                                        renderActive(subchild) &&
                                        "active-subfolder"
                                      }`}
                                    >
                                      <div className="div-flex div-align-center">
                                        <img
                                          src={Folder}
                                          className="icon-size"
                                        />
                                        &nbsp;&nbsp;
                                        <a href={`#${subchild.slug}`}>
                                          {subchild.text}
                                        </a>
                                      </div>
                                      &nbsp;
                                      <img
                                        src={ChevronDown}
                                        style={{ width: 18 }}
                                        className={`chevron-down`}
                                      />
                                    </div>
                                  </div>
                                );
                              })}
                            </Collapse>
                          </div>
                        );
                      })}
                    </Collapse>
                  </div>
                );
              })}
        </div>
        <div
          style={{ width: "100%", height: 1.2, backgroundColor: "#cccc" }}
        ></div>
        <a href="#onegml-folder" style={{ textDecoration: "none" }}>
          <div
            onClick={() => openOneGML(side_bar[side_bar.length - 1])}
            className={
              files.files_action === "onegml"
                ? "active-sidebar"
                : "list-sidebar"
            }
          >
            <img src={Institution} className="icon-size" />
            &nbsp;&nbsp;
            <a href="#onegml-folder">ONE GML</a>
          </div>
        </a>
        <div
          onClick={() => setPublicationCollapse(!publication_collapse)}
          className={"list-sidebar div-space-between"}
        >
          <div className="div-flex div-align-center ">
            <img src={Publication} className="icon-size" />
            &nbsp;&nbsp;
            <a href="#">Publication</a>
          </div>
          &nbsp;
          <img
            src={ChevronDown}
            style={{ width: 18 }}
            className={`chevron-down ${publication_collapse && "open"}`}
          />
        </div>
        <Collapse in={publication_collapse}>
          <div
            onClick={() => window.location.assign("/publication/insight")}
            className={`sub-sidebar div-space-between ${
              window.location.pathname === "/publication/insight"
                ? "active-sidebar"
                : "sub-list-sidebar"
            }`}
          >
            <div className="div-flex div-align-center">
              <img src={Insight} className="icon-size" />
              &nbsp;&nbsp;
              <a href="#">Our Insight</a>
            </div>
          </div>
        </Collapse>
        <div
          onClick={() => window.location.assign("/clients")}
          className={
            window.location.pathname === "/clients"
              ? "active-sidebar"
              : "list-sidebar"
          }
        >
          <img src={handshake} className="icon-size" />
          &nbsp;&nbsp;
          <a href="/clients">Client</a>
        </div>
      </div>
    </div>
  );
}

export default withRouter(Index);
