import * as actionTypes from '../constants/insight'
import moment from 'moment'
const initialState={
    insight_payload:{
        id:0,
        title:"",
        content:'',
        author:[],
        category:null,
        thumbnail_file:null,
        thumbnail_name:'',
        thumbnail_url:'',
        meta_title:'',
        slug:'',
        meta_desc:'',
        keyword:[],
        last_update:'',
        publish:0,
        website: ''
    },
    list_insight:[],
    pagination:null,
    insight_action:'list_insight'
    
}

export default (state=initialState,action)=>{
    switch (action.type) {
        case actionTypes.SET_INSIGHT:
            return{
                ...state,
                insight_payload:{
                    ...state.insight_payload,
                    [Object.keys(action.payload)]:Object.values(action.payload)[0]
                }
            }
        case actionTypes.GET_INSIGHT:
            return{
                ...state,
                list_insight:action.payload.items,
                pagination:action.payload.info,
                total_data:action.payload
            }
        case actionTypes.INSIGHT_ACTION:
            return{
                ...state,
                insight_action:action.payload
            }
        case actionTypes.RESET_PAYLOAD:
            return{
                ...state,
                insight_payload:{
                    id:0,
                    title:"",
                    content:'',
                    author:[],
                    category:null,
                    website:null,
                    thumbnail_file:null,
                    thumbnail_name:'',
                    thumbnail_url:'',
                    meta_title:'',
                    slug:'',
                    meta_desc:'',
                    keyword:[],
                    last_update:moment(),
                    publish:0,
                    website: ''
                }
            }
        default:
            return state;
    }
}