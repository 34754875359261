import React,{useState} from 'react'
import './style.css'
import {Badge,Avatar,Typography,Divider,Button,TextField,Collapse,CircularProgress} from '@material-ui/core'
import {CameraAlt} from '@material-ui/icons'
import AvaDefault from 'assets/icon/avadefault.svg'
import Key from 'assets/icon/Key.svg'
import { MuiThemeProvider, createMuiTheme,withStyles, } from '@material-ui/core/styles'
import {logout,changePassword} from 'redux/actions/auth'
import {getClient,getContact,getEmployee} from 'redux/actions/master'
import {useDispatch,useSelector} from 'react-redux'
const themeButton = createMuiTheme({ 
    palette: { 
        primary: {
            main:'#ffc466',
            contrastText: '#FFFFFF',

        },
        secondary: {
            main:'#FFFFFF',
        }
    } 
})
export default function Profile(props) {
    const {profile}=props
    const dispatch = useDispatch()
    const general = useSelector(state => state.general)
    const master = useSelector(state => state.master)
    const [state,setState]=useState({
        password_toggle:false,
        edited:false,
        oldPassword:'',
        newPassword:'',
        name:''
    })
    const togglePassword=()=>{
        setState({
            ...state,
            password_toggle:!state.password_toggle,
            edited:!state.edited,

        })
    }
    const onChange=(e)=>{
        e.preventDefault()
        setState({
            ...state,
            [e.target.name]:e.target.value
        })
    }
    const onSave=()=>{
        let {oldPassword,newPassword}=state
        let data={oldPassword,newPassword}
        dispatch(changePassword(props.token,data))
    }
    let branch=master.branches.filter((data)=>{
        return data.id===profile.branchId
    })
    let segment=master.segments.filter((data)=>{
        return data.id===profile.segmentId
    })
    return (
        <div>
            <div className='profile-container'>
                <Badge
                    overlap="circle"
                    anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'right',
                    }}
                    badgeContent={<div style={{backgroundColor:'white',width:30,height:30,display:'flex',justifyContent:'center',alignItems:'center',borderRadius:'50%',border:'2px solid white'}}><CameraAlt fontSize="inherit" color="primary" style={{fontSize:19}} /></div>}

                >
                    <Avatar alt="Travis Howard" src={AvaDefault} style={{width:80,height:80}} />
                </Badge>
                <br/>
                <h2>{profile.name}</h2>
                <p>{profile.email}</p>
                <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}>
                    <p>Branch <b>{master.branches.length>0&&profile.branchId!==0?branch[0].text:'-'}</b></p>
                    &nbsp;<div style={{width:1,height:20,marginTop:-10,backgroundColor:'#777777'}}></div>&nbsp;
                    <p>Segment <b>{master.segments.length>0&&profile.segmentId!==0?segment[0].text:'-'}</b></p>
                </div>
            </div>
            <Divider />
            <div style={{padding:10}}>
                <Button className='btn-remove-capital' color='primary' variant='text'
                    startIcon={<img src={Key} style={{width:20}} />}
                    onClick={togglePassword}
                >
                    Change password
                </Button>
                <Collapse in={state.password_toggle}>
                    <TextField type="password" name="oldPassword" onChange={onChange} id="outlined-basic1" label="Old password" variant="outlined"  size="small" style={{width:'100%',marginTop:10}}/>
                    <TextField type="password" name="newPassword" onChange={onChange} id="outlined-basic1" label="New password" variant="outlined"  size="small" style={{width:'100%',marginTop:10,marginBottom:10}} />
                </Collapse>
                {general.error_msg!==null&&<p style={{color:'red'}}>{general.error_msg}</p>}
            </div>
            <Divider />
            <div style={{display:'flex',justifyContent:'flex-end',marginTop:10,marginBottom:10}}>
                <MuiThemeProvider theme={themeButton}>
                {state.edited?
                <Button onClick={onSave} className='btn-rounded btn-remove-capital' variant='contained' color='primary'>
                    Update
                </Button>
                :
                <Button onClick={()=>dispatch(logout())} className='btn-rounded btn-remove-capital' variant='contained' color='secondary'>
                    Log out
                </Button>
                }
                </MuiThemeProvider>
            </div>
        </div>
    )
}
