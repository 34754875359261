import {
    BrowserRouter as Router,
    Switch,
    Route,
    withRouter,
    Redirect
  } from "react-router-dom";
  import React,{useEffect} from 'react'
import Client from 'views/Clients'
import Login from 'views/login'
import OneGml from 'views/OneGMl'
import Three from 'views/Three'
import Insight from 'views/Insight'

import Cookie from 'universal-cookie'
import { useSelector, useDispatch } from "react-redux";
import {logout} from 'redux/actions/auth'
const cookie=new Cookie
const Logout=()=>{
    const dispatch=useDispatch()
    useEffect(()=>{
        dispatch(logout())

    },[])
    return(
        <Redirect to='/login'/>
    )
}
const indexRouter=(props)=>{
    const token=cookie.get('login_cookie')
    const profile=cookie.get('profile_cookie')
    const isHaveToken=()=>{
        if(token!==undefined &&profile!==undefined){
            return(
            <>
                <Route path='/clients' exact render={()=>(<Client token={token} profile={profile}/>)} />
                <Route path='/publication/insight' exact render={()=>(<Insight token={token} profile={profile}/>)} />
                <Route path='/onegml' exact render={()=>(<OneGml token={token} profile={profile}/>)} />
                <Route path='/' exact render={()=>(<Three token={token} profile={profile}/>)} />
               
            </>
            )
        }else{
            return <Logout/>
        }
    }
    return(
        <Router>
        <Switch>
            <Route path='/login' exact component={Login}/>
            {/* <Route path='/previewdetail' exact component={DetailPreview}/> */}
            {isHaveToken()}
        </Switch>
        </Router>
    )
}
  export default indexRouter
  