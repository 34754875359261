import React ,{useEffect}from 'react'
import './style.css'
import { MuiThemeProvider, createMuiTheme,withStyles,makeStyles
} from '@material-ui/core/styles'
import {Button,Table,TableHead,TableRow,TableCell,TableBody} from '@material-ui/core'
import {modalToggle} from 'redux/actions/general'
import { useDispatch, useSelector } from "react-redux";
import Close from 'assets/icon/close.svg'
import {getParticipant,getProject,setUpload} from 'redux/actions/files'
const themeButton2 = createMuiTheme({ 
    palette: { 
        primary:{
            main:'#ffc466',
            contrastText: '#FFFFFF',
        },
        secondary:{
            main:'#65b7ff',
            contrastText: '#FFFFFF',
        },

    } 
})
export default function Participants(props) {
    const dispatch=useDispatch()
    const files = useSelector(state => state.files)
    const {project_id,participant}=files
    useEffect(()=>{
        dispatch(getParticipant(props.token,project_id))
    },[])
    const clickNext=()=>{
        if(participant.length>0){
            goToUpload()
        }else{
            addParticipant()
        }
        
        
    }
    const addParticipant=()=>{
        dispatch(modalToggle({ 
            modal_open: true,
            modal_title: "Add participant",
            modal_component: "participant",
            modal_size:900,
            modal_action:'add_participant',
            modal_data:null
        }))
    }
    const goToUpload=async ()=>{
        let res=await dispatch(getProject(props.token,`/${files.project_id}/${0}`))
            if(res){
                let new_upload=[]
                res.content.folders.map((data,i)=>{
                    new_upload.push({
                        files:{name:data.name},
                        type:'folder',
                        id:data.id,
                        parent:0,
                        exist:true
                    })
                })
                res.content.files.map((data,i)=>{
                    new_upload.push({
                        files:{name:data.name},
                        type:'file',
                        parent:0,
                        exist:true,
                        id:data.id
                    })
                })
                dispatch(setUpload(new_upload))
                props.tabToggle('upload')

            }
    }
    const editParticipant=(data)=>{
        dispatch(modalToggle({ 
            modal_open: true,
            modal_title: "Edit participant",
            modal_component: "participant",
            modal_data:participant,
            modal_size:900,
            modal_action:'edit_participant'
        }))
    }
    const deleteParticipant=(data)=>{
        dispatch(modalToggle({ 
            modal_open: true,
            modal_title: `participant`,
            modal_component: "confirm_delete",
            modal_size:400,
            modal_type:'confirm',
            modal_data:{
                title:'Participant',
                projectId:project_id,
                participantId:data.id,
                msg:`<p>Are you sure , delete this participant ?</p>`
            },
            modal_action:'delete_participant'
        }))
    }
    console.log('participant', participant)
    return (
        <div>
            <div className='div-flex div-space-between'>
            <p className='semi-bold'>Participant information</p>
            <MuiThemeProvider theme={themeButton2}>
                <div className='div-flex'>
                {participant.length>0&&<Button onClick={()=>addParticipant()} size='small' variant='text' color='secondary' className='btn-remove-capital' style={{margin:0}}>Add more participant</Button>}
                {/* {participant.length<1&&<Button onClick={()=>goToUpload()} size='small' variant='text' color='secondary' className='btn-remove-capital' style={{margin:0}}>Upload file list participant</Button>} */}

                </div>
            </MuiThemeProvider>
            </div>
            {participant.map((data,i)=>(
                <div style={{marginTop:10}} key={i}>
                    <div className=' div-flex div-space-between div-align-center'>
                        <p className='semi-bold'>Company name : {data.client.text}</p>
                        <MuiThemeProvider theme={themeButton2}>
                            <Button onClick={()=>editParticipant(data)} size='small' variant='text' color='secondary' className='btn-remove-capital'>Edit</Button>
                        </MuiThemeProvider>
                    </div>
                    <div className='dashed-border'></div>
                    {data.participants.length>0&&
                    <Table  size="small" aria-label="a dense table" style={{marginTop:10}}>
                    <TableHead>
                    <TableRow>
                        <TableCell className='card-content-item-jurnal-text' align="left">Participant name</TableCell>
                        <TableCell className='card-content-item-jurnal-text text-widthbreak' align="left">Email</TableCell>
                        <TableCell className='card-content-item-jurnal-text' align="left">Phone No.</TableCell>
                        <TableCell className='card-content-item-jurnal-text' align="left">Department</TableCell>
                        <TableCell className='card-content-item-jurnal-text' align="center">Position</TableCell>
                        <TableCell className='card-content-item-jurnal-text' align="center"></TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {data.participants.map((part,index)=>(
                        <TableRow key={index}>
                            <TableCell className='card-content-item-jurnal-text-without-weight' align="left" >{part.name}</TableCell>
                            <TableCell className='card-content-item-jurnal-text-without-weight' align="left" >{part.email}</TableCell>
                            <TableCell className='card-content-item-jurnal-text-without-weight' align="left">{part.phone}</TableCell>
                            <TableCell className='card-content-item-jurnal-text-without-weight' align="left">{part.department}</TableCell>
                            <TableCell className='card-content-item-jurnal-text-without-weight' align="center">{part.position}</TableCell>
                            <TableCell className='card-content-item-jurnal-text-without-weight' align="center"><img src={Close} onClick={()=>deleteParticipant(part)} style={{width:15,cursor:'pointer'}}/></TableCell>
                        </TableRow>
                    ))}
                    
                    </TableBody>
                </Table>
                }
                </div>
            ))}
            
            <br/>
            <MuiThemeProvider theme={themeButton2}>
            <div className='div-flex div-space-between'>
                <Button onClick={()=>props.tabToggle('info_workshop')} size='small' variant='outlined' color='primary' className='btn-remove-capital  btn-rounded'>Back</Button>
                    <Button onClick={()=>clickNext()} size='small' variant='contained' color='primary' className='btn-remove-capital  btn-rounded'>{participant.length>0?'Go to upload file':'Add participant'}</Button>
                {/* <Button onClick={()=> props.tabToggle('upload')} size='small' variant='contained' color='primary' className='btn-remove-capital  btn-rounded'>Upload</Button> */}
            </div>
            </MuiThemeProvider>
            {/* <p></p> */}
        </div>
    )
}
