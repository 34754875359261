import React,{useState,useEffect} from 'react'
import {Button,TextField,FormControl,InputLabel,OutlinedInput,MenuItem,Select,
    FormHelperText,Modal,FormControlLabel,Checkbox,Table,TableHead,TableRow,TableCell,TableBody} from '@material-ui/core'
import { MuiThemeProvider, createMuiTheme,withStyles,makeStyles } from '@material-ui/core/styles'
import AutoCompleteSelect from 'components/Select'
import {postFolder,postFolder2,putFolder,setUpload} from 'redux/actions/files'
import {useDispatch,useSelector} from 'react-redux'
import {modalToggleReset} from 'redux/actions/general'
import ChipInput from 'material-ui-chip-input'

const themeField = createMuiTheme({  
    palette: {  
        primary: {
            main:'#a8e090',
            contrastText: '#FFFFFF',

        },
        secondary:{
            main:'#65b7ff',
            contrastText: '#FFFFFF',

        }
    } 
})
const themeButton = createMuiTheme({ 
    palette: { 
        primary: {
            main:'#a8e090',
        },
        secondary:{
            main:'#ffc466',
            contrastText: '#FFFFFF',

        }
    } 
})
const useStyles = makeStyles(theme => ({
    textField: {
      [`& fieldset`]: {
        borderRadius: 10,
      },
      width:'100%',
      marginBottom:15
  },
    textField2: {
      [`& fieldset`]: {
        borderRadius: 10,
      },
      width:'100%',
    //   marginBottom:15
  }
  
}));
export default function Add_folder2(props) {
    const classes=useStyles()
    const dispatch=useDispatch()
    const master=useSelector(state=>state.master)
    const files=useSelector(state=>state.files)
    const [state,setState]=useState({
        name:'',
        owner:null,
        deskripsi:''
    })
    console.log('props.modal_data.projectId', props.modal_data.projectId)
    const addFolder=async ()=>{
        let data={
            id:0,
            name:state.name,
            parentId:props.modal_data.parentId,
            projectId:props.modal_data.projectId,
            onegml:props.modal_data.onegml,
            userId:props.profile.id,
            description:state.deskripsi
        }
        
        let res=await dispatch(postFolder2(props.token,data,null))
        if(res){
            let fil={
                files:{name:state.name},
                type:'folder',
                id:res.id,
                parent:props.modal_data.active_parent,
                exist:false
            }
            dispatch(setUpload([...files.upload,fil]))
        }
    }
    
    
    
    const handleDisable=()=>{
        if(state.deskripsi!==''&&state.name!==''){
            return false
        }else{
            return true
        }
    }
    return (
        <div>
             <MuiThemeProvider theme={themeField}>
                    <TextField
                        label='Folder name'
                        type='text'
                        value={state.name}
                        onChange={(e)=>setState({...state,name:e.target.value})}
                        variant='outlined'
                        size='small'
                        className={classes.textField}

                    />
                    <TextField
                        label='Deskripsi'
                        type='text'
                        value={state.deskripsi}
                        onChange={(e)=>setState({...state,deskripsi:e.target.value})}
                        variant='outlined'
                        size='small'
                        className={classes.textField}
                        multiline
                    />
                    {/* <ChipInput
                        label="Keyword"
                        value={state.keyword}
                        onAdd={(chip) =>setState({...state,keyword:[...state.keyword,chip]})}
                        // onAdd={(chip) =>console.log('chip', chip)}
                        onDelete={(chip, index) => deleteKeyword(chip,index)}
                        variant="outlined"
                        className={classes.textField}
                    /> */}
             </MuiThemeProvider>
             <MuiThemeProvider theme={themeButton}>
                <div className='modal-footer'>
                    <Button disabled={handleDisable()}  onClick={addFolder}  size='small' color='secondary' variant='contained' className='btn-remove-capital btn-rounded' style={{width:120,height:30}}>{props.modal_action==='edit_folder'?'Update':'Save'}</Button>
                </div>
            </MuiThemeProvider>
        </div>
    )
}
