import * as actionType from 'redux/constants/search'
import * as actionGeneral from './general'
import {apiCall} from 'service/apiCall'
import _ from "lodash";
import moment from 'moment'

import Cookie from 'universal-cookie'
const cookie=new Cookie()
const token=cookie.get('login_cookie')
const profile=cookie.get('profile_cookie')

export const setSearch=(obj)=>async dispatch=>{
    dispatch({
        type:actionType.SET_SEARCH,
        payload:obj
    })
    return obj
}

export const getSearch=(slug)=>async(dispatch)=>{
    await dispatch(actionGeneral.setLoadingSearch(true))
    let dataReq={
        url:`/search${slug}`,
        method:'GET',
        data:{
            headers:{ 'Authorization': `Bearer ${token}`},

        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(_.get(res,'status')===200){

        dispatch(actionGeneral.setLoadingSearch(false))
        dispatch(setSearch({result:res.data.entries}))
        dispatch(setSearch({pagination:res.data.info}))
        // dispatch(setTalenta({list_proyek:res.data.items}))
        // dispatch(setTalenta({pagination:res.data.info}))
        return res
    }else{
        dispatch(actionGeneral.setLoadingSearch(false))
        return res

    }
}