import React from 'react'
import {Button,TextField,FormControl,InputLabel,OutlinedInput,MenuItem,Select,
    FormHelperText,Modal,FormControlLabel,Checkbox,Table,TableHead,TableRow,TableCell,TableBody} from '@material-ui/core'
import { MuiThemeProvider, createMuiTheme,withStyles,makeStyles } from '@material-ui/core/styles'
import AutoCompleteSelect from 'components/Select'

const themeField = createMuiTheme({  
    palette: {  
        primary: {
            main:'#ffc466',
            contrastText: '#FFFFFF',

        },
        secondary:{
            main:'#65b7ff',
            contrastText: '#FFFFFF',

        }
    } 
})
const themeButton = createMuiTheme({ 
    palette: { 
        primary: {
            main:'#a8e090',
        },
        secondary:{
            main:'#ffc466',
            contrastText: '#FFFFFF',

        }
    } 
})
const useStyles = makeStyles(theme => ({
    textField: {
      [`& fieldset`]: {
        borderRadius: 10,
      },
      width:'100%',
      marginBottom:15
  },
    textField2: {
      [`& fieldset`]: {
        borderRadius: 10,
      },
      width:'100%',
    //   marginBottom:15
  }
  
}));
export default function Add_file() {
    const classes=useStyles()

    return (
        <div>
             <MuiThemeProvider theme={themeField}>
                    <TextField
                        label='Folder name'
                        type='text'
                        value={null}
                        onChange={(e)=>null}
                        variant='outlined'
                        size='small'
                        className={classes.textField}

                    />
                     <AutoCompleteSelect
                        // multiple
                        onChange={(event,value)=>null}
                        options={null}
                        // getOptionLabel={(option) => option.text}
                        label={<>Owner</>}
                        value={null}

                    />
             </MuiThemeProvider>
             <MuiThemeProvider theme={themeButton}>
                <div className='modal-footer'>
                    <Button  onClick={null}  size='small' color='secondary' variant='contained' className='btn-remove-capital btn-rounded' style={{width:120,height:30}}>Save</Button>
                </div>
            </MuiThemeProvider>
        </div>
    )
}
