import * as actionTypes from "../constants/insight";
import {
  setLoading,
  setLoadingTable3,
  setLoadingTable,
  modalToggle,
} from "./general";
// import {alertToggle} from './alert'
import { apiCall } from "../../service/apiCall";
import { get } from "lodash";
import Cookie from "universal-cookie";
import { REVALIDATE_URL } from "service/base_url";
const cookie = new Cookie();
const token = cookie.get("login_cookie");
export const setInsight = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.SET_INSIGHT,
    payload: data,
  });
};
export const resetPayload = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.RESET_PAYLOAD,
    payload: data,
  });
};
export const setInsightAction = (data) => (dispatch) => {
  dispatch({
    type: actionTypes.INSIGHT_ACTION,
    payload: data,
  });
};

export const getInsight = (slug) => async (dispatch) => {
  dispatch(setLoadingTable3(true));
  let dataReq = {
    url: `/km/insight${slug}`,
    method: "GET",
    data: {
      headers: { Authorization: `Bearer ${token}` },
    },
  };
  let res = await dispatch(apiCall(dataReq));
  if (get(res, "status") == 200) {
    dispatch(setLoadingTable3(false));

    dispatch({
      type: actionTypes.GET_INSIGHT,
      payload: res.data,
    });
  } else {
    dispatch(setLoadingTable3(false));
  }
};
export const getDetailInsight = (slug) => async (dispatch) => {
  dispatch(setLoading(true));
  let dataReq = {
    url: `/km/insight${slug}`,
    method: "GET",
    data: {
      headers: { Authorization: `Bearer ${token}` },
    },
  };
  let res = await dispatch(apiCall(dataReq));
  if (get(res, "status") == 200) {
    dispatch(setLoading(false));

    dispatch(setInsight({ id: res.data.id }));
    dispatch(setInsight({ title: res.data.title }));
    dispatch(setInsight({ content: res.data.content }));
    dispatch(setInsight({ author: res.data.authors }));
    dispatch(setInsight({ category: res.data.categories[0] }));
    dispatch(setInsight({ website: res.data.website }));
    dispatch(setInsight({ thumbnail_name: res.data.filename }));
    dispatch(setInsight({ thumbnail_url: res.data.thumbnail }));
    dispatch(setInsight({ meta_title: res.data.metaTitle }));
    dispatch(setInsight({ slug: res.data.slug }));
    dispatch(setInsight({ meta_desc: res.data.metaDescription }));
    dispatch(setInsight({ keyword: res.data.keyWords }));
    dispatch(setInsight({ last_update: res.data.lastUpdated }));
    return res;
  } else {
    dispatch(setLoading(false));
  }
};
export const revalidateInsight = () => async (dispatch) => {
  let res = await fetch(REVALIDATE_URL);
  //   let res = await dispatch(apiCall(dataReq));
  //   return res;
  if (res) {
    return;
  }
};
export const postInsight = (data) => async (dispatch) => {
  dispatch(setLoading(true));
  let dataReq = {
    url: `/km/insight`,
    method: "POST",
    data: {
      data: data,
      headers: { Authorization: `Bearer ${token}` },
    },
    name_req: "postInsight",
  };
  let res = await dispatch(apiCall(dataReq));
  if (get(res, "status") == 200) {
    dispatch(setLoading(false));
    dispatch(
      modalToggle({
        modal_open: true,
        modal_title: "Add Insight success",
        modal_component: "add_insight",
        modal_size: 400,
        modal_type: "alert",
        modal_data: {
          msg: `<p>insight ${res.data.title} success added</p> `,
          ...res.data,
        },
        modal_action: "success",
        // success_msg:success_msg
      })
    );
    return res;
  } else {
    dispatch(setLoading(false));
  }
};
export const langsungPublish = (data) => async (dispatch) => {
  dispatch(setLoading(true));
  let dataReq = {
    url: `/km/insight`,
    method: "POST",
    data: {
      data: data,
      headers: { Authorization: `Bearer ${token}` },
    },
    name_req: "postInsight",
  };
  let res = await dispatch(apiCall(dataReq));
  if (get(res, "status") == 200) {
    await dispatch(revalidateInsight());
    dispatch(setLoading(false));
    dispatch(
      modalToggle({
        modal_open: true,
        modal_title: "Publish Insight success",
        modal_component: "add_insight",
        modal_size: 400,
        modal_type: "alert",
        modal_data: {
          msg: `<p>Insight ${res.data.title} success publish</p> `,
          ...res.data,
        },
        modal_action: "success",
        // success_msg:success_msg
      })
    );
    return res;
  } else {
    dispatch(setLoading(false));
  }
};
export const putInsight = (data) => async (dispatch) => {
  dispatch(setLoading(true));
  let dataReq = {
    url: `/km/insight`,
    method: "PUT",
    data: {
      data: data,
      headers: { Authorization: `Bearer ${token}` },
    },
    name_req: "postInsight",
  };
  let res = await dispatch(apiCall(dataReq));
  if (get(res, "status") == 200) {
    dispatch(setLoading(false));
    dispatch(
      modalToggle({
        modal_open: true,
        modal_title: "Update Insight success",
        modal_component: "add_insight",
        modal_size: 400,
        modal_type: "alert",
        modal_data: {
          msg: `<p>insight ${res.data.title} success updated</p> `,
          ...res.data,
        },
        modal_action: "success",
        // success_msg:success_msg
      })
    );
  } else {
    dispatch(setLoading(false));
  }
};
export const deleteInsight = (slug) => async (dispatch) => {
  dispatch(setLoading(true));
  let dataReq = {
    url: `/km/insight/delete${slug}`,
    method: "DELETE",
    data: {
      headers: { Authorization: `Bearer ${token}` },
    },
  };
  let res = await dispatch(apiCall(dataReq));
  if (get(res, "status") == 204) {
    dispatch(setLoading(false));
    dispatch(
      modalToggle({
        modal_open: true,
        modal_title: "Delete Insight success",
        modal_component: "add_insight",
        modal_size: 400,
        modal_type: "alert",
        modal_data: {
          msg: `<p></p> `,
          ...res.data,
        },
        modal_action: "success",
        // success_msg:success_msg
      })
    );
  } else {
    dispatch(setLoading(false));
  }
};
export const publishInsight = (slug) => async (dispatch) => {
  dispatch(setLoading(true));
  let dataReq = {
    url: `/km/insight/publish${slug}`,
    method: "GET",
    data: {
      headers: { Authorization: `Bearer ${token}` },
    },
  };
  let res = await dispatch(apiCall(dataReq));
  if (get(res, "status") == 200) {
    await dispatch(revalidateInsight());
    dispatch(setLoading(false));
    dispatch(
      modalToggle({
        modal_open: true,
        modal_title: "Publish Insight success",
        modal_component: "add_insight",
        modal_size: 400,
        modal_type: "alert",
        modal_data: {
          msg: `<p>Insight ${res.data.title} success publish</p> `,
          ...res.data,
        },
        modal_action: "success",
        // success_msg:success_msg
      })
    );
    return res;
  } else {
    dispatch(setLoading(false));
  }
};
export const unPublishInsight = (slug) => async (dispatch) => {
  dispatch(setLoading(true));
  let dataReq = {
    url: `/km/insight/publish${slug}`,
    method: "GET",
    data: {
      headers: { Authorization: `Bearer ${token}` },
    },
  };
  let res = await dispatch(apiCall(dataReq));
  if (get(res, "status") == 200) {
    dispatch(setLoading(false));
    await dispatch(revalidateInsight());
    dispatch(
      modalToggle({
        modal_open: true,
        modal_title: "Unpublish Insight success",
        modal_component: "add_insight",
        modal_size: 400,
        modal_type: "alert",
        modal_data: {
          msg: `<p>Insight ${res.data.title} success unpublish</p> `,
          ...res.data,
        },
        modal_action: "success",
        // success_msg:success_msg
      })
    );
    return res;
  } else {
    dispatch(setLoading(false));
  }
};
