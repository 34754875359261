import React, { Component } from 'react'
import '../style.css'
import { MuiThemeProvider, createMuiTheme,withStyles, } from '@material-ui/core/styles'
import {Button } from '@material-ui/core'
import {connect} from 'react-redux'

import {modalToggleReset} from 'redux/actions/general'
import {deleteClient} from 'redux/actions/client'
import {deleteFolderFile,deleteParticipant,setUpload} from 'redux/actions/files'
import {deleteInsight,getInsight} from 'redux/actions/insight'
const themeButton = createMuiTheme({ 
    palette: { 
        primary: {
            main:'#ffc466',
        },
        secondary: {
            main:'#ff6e79',
            contrastText: '#FFFFFF',
        }
    } 
})
class confirm_delete extends Component {
    deleteRm=async()=>{
        let {client,modal_data}=this.props
        await client.selected_rm.splice(modal_data.index,1)
        // console.log('object', client)
    }
    deleteClient=async()=>{
        let {modal_data,token}=this.props
        let data={id:modal_data.id,userId:modal_data.userId}
        await this.props.modalToggleReset()
        this.props.deleteClient(token,data)
    }
    deleteFolderFile=async()=>{

        let {modal_data,token,files}=this.props
        let last_bread=files.bread_crum[files.bread_crum.length-1]
        this.props.deleteFolderFile(token,modal_data.id,`/${0}/${files.bread_crum.length===1?0:last_bread.id}`)
        
    }
    deleteFolderFile2=async()=>{
        let {modal_data,token,files}=this.props
        let res=await this.props.deleteFolderFile(token,modal_data.id,modal_data.slugget)
        if(res){
            files.upload.splice(modal_data.index,1)
            let data=files.upload
            this.props.setUpload(data)
            this.props.modalToggleReset()
        }
    }
    deleteFolderFile3=async()=>{
        let {modal_data,token,files}=this.props
        await this.props.modalToggleReset()

        this.props.deleteFolderFile(token,modal_data.id,modal_data.slugget)
       
    }
    deleteParticipant=async ()=>{
        let {modal_data,token,files}=this.props
        await this.props.modalToggleReset()
        this.props.deleteParticipant(token,modal_data.projectId,modal_data.participantId)
    }
    deleteInsight=async ()=>{
        let {modal_data,token,files}=this.props
        await this.props.modalToggleReset()
        await this.props.deleteInsight(`/${modal_data.id}/${modal_data.userId}`)

        if(modal_data.get_slug!==undefined){
            this.props.getInsight(modal_data.get_slug)
        }
    }
    renderAction=()=>{
        let {modal_data,modal_action,profile,token}=this.props
        switch (modal_action) {
            case 'delete_rm':
               return this.deleteRm()
            case 'delete_client':
                return this.deleteClient()
            case 'delete_folderfile':
                return this.deleteFolderFile()
            case 'delete_folderfile2':
                return this.deleteFolderFile2()
            case 'delete_folderfile3':
                return this.deleteFolderFile3()
            case 'delete_participant':
                return this.deleteParticipant()
            case 'delete_insight':
                return this.deleteInsight()
            default:
                break;
        }
        // case
    }
    render() {
        // console.log('this.props.modal_data', this.props.modal_data)
        let {modal_data,modal_title}=this.props
        return (
            <div className='confirm-container'>
                <h4>Delete {modal_data.title}</h4>
                <div dangerouslySetInnerHTML={{ __html: modal_data.msg }}></div>
                {/* <p>Are you sure delete <b>{modal_data.title}</b></p> */}
                <div className='card-footer'>
                    <MuiThemeProvider theme={themeButton}>
                        <Button onClick={()=>this.props.modalToggleReset()}   size='small' color='primary' className='btn-remove-capital'>No, back to {modal_title}</Button>
                        <Button onClick={()=>this.renderAction()}   size='small' color='secondary' variant='contained' className='head-add-section__btn '>Yes</Button>
                    </MuiThemeProvider>
                </div>
            </div>
        )
    }
}
const mapStateToProps=(state)=>({
    general:state.general,
    client:state.client,
    files:state.files,
})
const mapDispatchToProps={
    modalToggleReset,
    deleteClient,
    deleteFolderFile,
    deleteParticipant,
    setUpload,
    deleteInsight,
    getInsight
}
export default connect(mapStateToProps,mapDispatchToProps)(confirm_delete)