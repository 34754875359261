import React,{useState} from 'react'
import { RadioGroup,Button,FormControlLabel,Radio,FormControl,InputLabel,Select,MenuItem,TextField,FormLabel } from '@material-ui/core'
import { MuiThemeProvider, createMuiTheme,withStyles,makeStyles
} from '@material-ui/core/styles'
import {useSelector,useDispatch} from 'react-redux'
import { setSearch,getSearch } from 'redux/actions/search'
import { modalToggleReset } from 'redux/actions/general'

const useStyles = makeStyles(theme => ({
    textField: {
      [`& fieldset`]: {
        borderRadius: 10,
      },
      width:'100%',
      marginBottom:15
  },
  
}));
const themeButton = createMuiTheme({ 
    palette: { 
        primary: {
            main:'#65b7ff',
            contrastText: '#FFFFFF',
        },
        secondary: {
            main:'#ffc466',
            contrastText: '#FFFFFF',
        }
    } 
})
export default function Advanced_search() {
    const classes=useStyles()
    const dispatch = useDispatch()
    const search = useSelector(state => state.search)
    const onSearch=()=>{
        
        dispatch(getSearch(`/0/${search.doctype}/${search.filetype}/${search.client.length===0?'*':search.client}/${search.keyword}/1/10`))
        dispatch(modalToggleReset())
    }
    return (
        <div>
            <MuiThemeProvider theme={themeButton}>
            <FormControl component="fieldset">
            <FormLabel component="legend">Context</FormLabel>
                <RadioGroup
                    color="primary"
                    row
                    label="Context"
                    aria-label="gender"
                    name="controlled-radio-buttons-group"
                    value={search.doctype}
                    onChange={(e)=>dispatch(setSearch({doctype:parseInt(e.target.value)}))}
                    style={{justifyContent:'space-between'}}
                >
                    <FormControlLabel value={0} control={<Radio size="small" color="primary" />} label="All" />
                    <FormControlLabel value={1} control={<Radio size="small" color="primary"/>} label="Deliverables Only" />
                    <FormControlLabel value={2} control={<Radio size="small" color="primary"/>} label="Pre-sales Only" />
                </RadioGroup>
                </FormControl>
                <br/><br/>
                <FormControl style={{width:'100%',marginBottom:15}}  variant="outlined" size="small" color='primary' className='contact-field' >
                    <InputLabel  htmlFor="category">File Type</InputLabel>
                    <Select  name="salutation"  value={search.filetype} onChange={(e)=>dispatch(setSearch({filetype:e.target.value}))} labelId="label" id="select"  labelWidth={75} className='field-radius'>
                        <MenuItem value="ppt">Powerpoint</MenuItem>
                        <MenuItem value="xls">Excel</MenuItem>
                        <MenuItem value="doc">Word</MenuItem>
                        <MenuItem value="pdf">PDF</MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    label='Client'
                    type='text'
                    value={search.client}
                    onChange={(e)=>dispatch(setSearch({client:e.target.value}))}
                    variant='outlined'
                    size='small'
                    className={classes.textField}
                />
                <TextField
                    label='Keyword'
                    type='text'
                    value={search.keyword}
                    onChange={(e)=>dispatch(setSearch({keyword:e.target.value}))}
                    variant='outlined'
                    size='small'
                    className={classes.textField}
                />
                {/* <div style={{textAlign:'right'}}> */}
                <Button style={{width:'100%'}} onClick={onSearch}  size='medium' color='secondary' variant='contained' className='btn-remove-capital btn-rounded'>Search</Button>
                {/* </div> */}
                <br/><br/>
            </MuiThemeProvider>
        </div>
    )
}
