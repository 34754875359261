export const SET_FILE='SET_FILE'
export const SET_FOLDER='SET_FOLDER'
export const SET_QUICK='SET_QUICK'
export const SET_BREAD_CRUM='SET_BREAD_CRUM'
export const SET_SIDE_BAR='SET_SIDE_BAR'
export const SET_DATA_PROJECT='SET_DATA_PROJECT'
export const SET_ACTIVE_FOLDER ='SET_ACTIVE_FOLDER '
export const SET_FILES_ACTION ='SET_FILES_ACTION '
export const SET_LAST_ACTIVITY ='SET_LAST_ACTIVITY '

export const SET_TRIBE_ID='SET_TRIBE_ID'
export const SET_CLIENT_ID='SET_CLIENT_ID'
export const SET_YEAR='SET_YEAR'

export const SET_WORK_PROJ='SET_WORK_PROJ'
export const GET_PROJECT_INFO='GET_PROJECT_INFO'
export const SET_ACTIVE_PROJECT='SET_ACTIVE_PROJECT'

export const LIST_PROJECT_FOR_MODAL='LIST_PROJECT_FOR_MODAL'
export const LIST_WORKSHOP_FOR_MODAL='LIST_WORKSHOP_FOR_MODAL'

export const SET_ADD_PROJECT_PAYLOAD='SET_ADD_PROJECT_PAYLOAD'
export const GET_PRODUCT='GET_PRODUCT'

export const DETAIL_AFTER_POST_PROJ_WORK='DETAIL_AFTER_POST_PROJ_WORK'

export const SET_UPLOAD='SET_UPLOAD'

export const SET_PROJECT_ID='SET_PROJECT_ID'

export const GET_PARTICIPANT='GET_PARTICIPANT'

export const RESET_ADD_PROJECT='RESET_ADD_PROJECT'
