import React,{useState} from 'react'
import './style.css'
import List from './list'
import Add from './add'
import Layout from 'components/Layouts'
import Preview from './preview'
import CardPrev from './preview/card_prev'
import DetailPrev from './preview/detail_prev'
export default function Index(props) {
    const [tab,setTab]=useState('list_insight')
    const tabToggle=(key)=>{
        setTab(key)
    }
    return (
        <div>
            <Layout>
            {tab==='list_insight'&&<List tabToggle={tabToggle} token={props.token} profile={props.profile}/>}
            {tab==='add_insight'&&<Add tabToggle={tabToggle} token={props.token} profile={props.profile}/>}
            {tab==='card_preview'&&<CardPrev tabToggle={tabToggle} token={props.token} profile={props.profile}/>}
            {tab==='detail_preview'&&<DetailPrev tabToggle={tabToggle} token={props.token} profile={props.profile}/>}
            </Layout>
        </div>
    )
}
