import React,{useEffect} from 'react'
import Layout from 'components/Layouts'
import {Button} from '@material-ui/core'
import { MuiThemeProvider, createMuiTheme,withStyles,
} from '@material-ui/core/styles'
import Plus from 'assets/icon/Plus.svg'
import ChevronDown from 'assets/icon/ChevronDown.svg'
import ChevronRight from 'assets/icon/Chevron-right-line.svg'
import './style.css'
import { useDispatch, useSelector } from "react-redux";
import {setBreadCrum} from 'redux/actions/master'
import {setFile,setFolder,setQuick,getQuick,getProject,updateFile,uploadFile} from 'redux/actions/files'
import Folder from './folder'
import {modalToggle} from 'redux/actions/general'
import {getTribe,getPlatform,getClient,getContact} from 'redux/actions/master'
import {handleFile,getBase64,handleFileMulti} from 'components/handleFile'

const themeButton = createMuiTheme({ 
    palette: { 
        primary: {
            main:'#ffc466',
            contrastText: '#FFFFFF',
        },
        secondary: {
            main:'#ff6e79',
            contrastText: '#FFFFFF',
        }
    } 
})
export default function Index(props) {
    const dispatch=useDispatch()
    const master=useSelector(state=>state.master)
    const files=useSelector(state=>state.files)
    const {bread_crum}=files
    useEffect(()=>{
        dispatch(getQuick(props.token,`/${props.profile.id}/1/0/0/0/8`))
        dispatch(getProject(props.token,`/${0}/${0}`))
        // dispatch(setBreadCrum([{title:'ONE GML',key:'onegml'},{title:'Struktur organisasi',key:'onegml'},{title:'Strategy and Execution',key:'onegml'}]))
    },[])
    const addFolder=async ()=>{
        let res=await dispatch(getPlatform(props.token))
        if(res){
            dispatch(modalToggle({
                modal_open: true,
                modal_title: "Add new folder",
                modal_component: "add_folder",
                modal_data:null ,
                modal_size:300,
                modal_action:'add_folder'
            }))
        }
        
    }
    const toFolder=(data,i)=>{
        
        let newb=[]
        for (let index = 0; index < bread_crum.length; index++) {
            newb.push(bread_crum[index])
            if(Object.is(data,bread_crum[index])){
                break
            }
        }
        dispatch(getProject(props.token,`/${0}/${i===0?0:data.id}`))
        if(i===0){
            dispatch(getQuick(props.token,`/${props.profile.id}/1/0/0/0/8`))

        }
        // let b=bread_crum.splice(i+1)
        dispatch(setBreadCrum(newb))
    }
    const renderBreadCrum=()=>{
        if(bread_crum.length<4){
            let a= bread_crum.map((data,i)=>(
                <div key={i} className='div-flex div-space-between div-align-center' style={{paddingRight:20}}>
                    <h3  onClick={()=>toFolder(data,i)} style={{marginRight:20,cursor:'pointer'}}>{data.text}</h3>
                    <img style={{width:15}} src={bread_crum.length-1===i?ChevronDown:ChevronRight} />
                </div>
            ))
            return a
        }else{
            let new_bread=bread_crum.slice(-3)
           return  new_bread.map((data,i)=>(
                <div key={i} className='div-flex div-space-between div-align-center' style={{paddingRight:20}}>
                    <h3   onClick={()=>toFolder(data,i)} style={{marginRight:20,cursor:'pointer'}}>{i===0?`...${data.text.substring(2,data.text.length-2)}`:data.text}</h3>
                    <img style={{width:15}} src={new_bread.length-1===i?ChevronDown:ChevronRight} />
                </div>
            ))
        }
    }
    const onChangeFile=(evt)=>{
        let new_file=[]
        let new_single
        var file = evt.target.files;
        // console.log('file', file)
        if(file.length>1){
            let multi=handleFileMulti(file)
            multi.map(file=>{
                new_file.push(file.file)
            })
        }else{
            new_single= handleFile(evt).file
            // new_file.push(single.file)
        }
        let last_bread=files.bread_crum[files.bread_crum.length-1]
        let fd=new FormData()
        fd.append('onegml',1)
        fd.append('projectId',0)
        fd.append('parentId',files.bread_crum.length===1?0:last_bread.id)
        fd.append('userId',props.profile.id)
        fd.append('files',new_single)
        dispatch(uploadFile(props.token,fd,`/${0}/${files.bread_crum.length===1?0:last_bread.id}`))
        // let handle=handleFile(evt)

        // if(handle.file_error===null){
        //    let fd=new FormData()
        //    fd.append('onegml',1)
        //    fd.append('projectId',0)
        //    fd.append('parentId',files.bread_crum.length===1?0:last_bread.id)
        //    fd.append('userId',props.profile.id)
        //    fd.append('files',handle.file)
        //    dispatch(uploadFile(props.token,fd,`/${0}/${files.bread_crum.length===1?0:last_bread.id}`))
        // }else{
        //     // setError(handle.file_error)
        // }
    }
    const onChangeUpdate=(evt)=>{
        let handle=handleFile(evt)
        let last_bread=files.bread_crum[files.bread_crum.length-1]

        if(handle.file_error===null){
            getBase64(handle.file,(result)=>{
                let data={
                    onegml:1,
                    projectId:0,
                    parentId:files.bread_crum.length===1?0:last_bread.id,
                    userId:props.profile.id,
                    filename:handle.file_name,
                    fileBase64:result.split("base64,")[1]
                }
                dispatch(updateFile(props.token,data,`/${0}/${files.bread_crum.length===1?0:last_bread.id}`))
            })
           
        }else{
            // setError(handle.file_error)
        }
    }
    return (
        <div>
            <Layout>
                <br/>
                <div className='div-flex div-space-between div-align-center gml-header'>
                    <div className='div-flex' style={{width:'100%'}}>
                        
                        {renderBreadCrum()}
                        
                       
                    </div>
                    <div style={{display:'flex'}}>
                        <MuiThemeProvider theme={themeButton}>
                            <Button component='label' onClick={()=>null} size='small'  variant='contained' className='btn-remove-capital btn-rounded remove-boxshadow' style={{color:'white',backgroundColor:'#ffc466',width:160,height:35}}><img src={Plus}/>
                            &nbsp;&nbsp; Upload new file
                            <input
                                type="file"
                                style={{ display: "none" }}
                                onChange={onChangeFile}
                                // multiple
                            />
                            </Button>
                            &nbsp;&nbsp;&nbsp;&nbsp;
                            <Button onClick={()=>addFolder()} size='small'  variant='contained' className='btn-remove-capital btn-rounded remove-boxshadow' style={{color:'white',backgroundColor:'#ffc466',width:180,height:35}}><img src={Plus}/>&nbsp;&nbsp;Add new folder</Button>
                        </MuiThemeProvider>
                    </div>
                </div>
                <div style={{width:'100%',height:1.2,backgroundColor:'rgba(119, 119, 119, 0.5)',margin:'10px 0px 10px 0px'}}></div>
                <Folder token={props.token} profile={props.profile}/>
            </Layout>
        </div>
    )
}
