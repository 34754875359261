import React, { Component } from 'react'
import '../style.css'
import { MuiThemeProvider, createMuiTheme,withStyles, } from '@material-ui/core/styles'
import {Button } from '@material-ui/core'
import {connect} from 'react-redux'

import {modalToggleReset} from 'redux/actions/general'
import {deleteClient} from 'redux/actions/client'
import {publishInsight,setInsight,unPublishInsight,putInsight,postInsight,langsungPublish} from 'redux/actions/insight'
const themeButton = createMuiTheme({ 
    palette: { 
        primary: {
            main:'#ffc466',
        },
        secondary: {
            main:'#ff6e79',
            contrastText: '#FFFFFF',
        }
    } 
})
const themeButton2 = createMuiTheme({ 
    palette: { 
        primary: {
            main:'#ffc466',
            contrastText: '#FFFFFF',

        },
        secondary: {
            
            main:'#65b7ff',
            contrastText: '#FFFFFF',
        }
    } 
})
const themeButton3 = createMuiTheme({ 
    palette: { 
        primary: {
            main:'#65b7ff',
            contrastText: '#FFFFFF',

        },
        secondary: {
            
            main:'#ff6e79',
            contrastText: '#FFFFFF',
        }
    } 
})
class confirm_delete extends Component {
    
    
    publishInsight=async ()=>{
        let {modal_data}=this.props
        this.props.modalToggleReset()
        if(modal_data.id==0){
            let res=await this.props.langsungPublish(modal_data.data_update)
            if(res){
                this.props.setInsight({publish:1})
                this.props.setInsight({id:res.data.id})
            }
        }else{
            await this.props.putInsight(modal_data.data_update)
            let res=await this.props.publishInsight(`/${modal_data.id}/1/${modal_data.userId}`)
            if(res){
                this.props.setInsight({publish:1})
            }
        }
        
    }
    unPublishInsight=async ()=>{
        let {modal_data}=this.props
        this.props.modalToggleReset()

        let res=await this.props.unPublishInsight(`/${modal_data.id}/0/${modal_data.userId}`)
        if(res){
            this.props.setInsight({publish:0})
        }
    }
    renderAction=()=>{
        let {modal_data,modal_action,profile,token,pipeline}=this.props
        switch (modal_action) {
            case 'publish_insight':
               return this.publishInsight()
            case 'unpublish_insight':
               return this.unPublishInsight()
            
           
            default:
                break;
        }
    }
    
    render() {
        let {modal_data,modal_title,modal_action}=this.props
        console.log('modal_action', modal_action)

        return (
            <div className='confirm-container'>
                <h3>{modal_title}</h3>
                <div dangerouslySetInnerHTML={{ __html: modal_data.msg }}></div>
                <div className='card-footer'>
                    <MuiThemeProvider theme={modal_action==='won_deal'||modal_action==='reopen_deal'||modal_action==='to_invoice'?themeButton2:modal_action==='lost_deal'?themeButton3:themeButton}>
                        <Button onClick={()=>this.props.modalToggleReset()}   size='small' color='primary' className='btn-remove-capital'>{modal_data.title_cancel}</Button>
                        <Button onClick={()=>this.renderAction()}   size='small' color='secondary' variant='contained' className='head-add-section__btn '>{modal_data.title_yes}</Button>
                    </MuiThemeProvider>
                </div>
            </div>
        )
    }
}
const mapStateToProps=(state)=>({
    general:state.general,
    client:state.client,
    pipeline:state.pipeline,
    insight:state.insight,
})
const mapDispatchToProps={
    modalToggleReset,
    deleteClient,
    publishInsight,
    setInsight,
    unPublishInsight,
    putInsight,
    postInsight,
    langsungPublish
}
export default connect(mapStateToProps,mapDispatchToProps)(confirm_delete)