import React, { useState,useEffect } from 'react'
import {Button,Collapse,FormControl,InputLabel,MenuItem,Divider,
OutlinedInput,InputAdornment,Table,TableHead,TableRow,TableCell,TableBody} from '@material-ui/core'
import {FilterList} from '@material-ui/icons'
import SearchImg from 'assets/icon/Search.png'
import AutoCompleteSelect from 'components/Select'
import { useDispatch, useSelector } from "react-redux";
import { MuiThemeProvider, createMuiTheme,withStyles,makeStyles
} from '@material-ui/core/styles'
import {
    DatePicker,
    TimePicker,
    DateTimePicker,
    MuiPickersUtilsProvider,
    } from '@material-ui/pickers';
    import MomentUtils from '@date-io/moment';
import '../style.css'
import Skeleton from 'react-loading-skeleton'
import {getProjWorkModal,setAddProjectPayload,setProjectId} from 'redux/actions/files'
import {getClient} from 'redux/actions/master'
import CevronRight from 'assets/icon/chevron-right.svg'
import CevronLeft from 'assets/icon/chevron-left.svg'
import { TablePagination } from '@trendmicro/react-paginations';
import '@trendmicro/react-paginations/dist/react-paginations.css';
import {debounce,isEmpty} from 'lodash'
import moment from 'moment'
import {modalToggleReset} from 'redux/actions/general'
const themeButton = createMuiTheme({ 
    palette: { 
        primary: {
            main:'#ffc466',
            contrastText: '#FFFFFF',
        },
        secondary: {
            main:'#AFE597',
            contrastText: '#FFFFFF',
        },
    } 
})
const useStyles = makeStyles(theme => ({
    textField: {
      [`& fieldset`]: {
        borderRadius: 10,

      },
      width:'100%',
      marginBottom:15,
  },
 
  
}));
export default function Choose_workshop(props) {
    const dispatch=useDispatch()
    const classes=useStyles()
    const general=useSelector((state)=>state.general)
    const master=useSelector((state)=>state.master)
    const [page,setPage]=useState(1)
    const [pageLength,setPageLength]=useState(10)
    const [search,setSearch]=useState('')
    const [search2,setSearch2]=useState('')
    const [loading,setLoading]=useState(false)
    const [textSelect,setTextSelect]=useState('Type company name')
    const [filter,setFilter]=useState({
        tribeId:{id:0,text:'All tribe'},
        clientId:{value:0,label:'All client'},
        year:0
    })
    useEffect(()=>{
        dispatch(getProjWorkModal(props.token,`${filter.tribeId.id}/${filter.clientId.value}/${filter.year}/1/10/*`))
    },[])
    const files=useSelector((state)=>state.files)
    const {list_project_for_modal,work_proj_pagination}=files
    const [open_filter,setOpenFilter]=useState(false)
    
    const togglePagination=(page,pageLength)=>{
        setPage(page)
        setPageLength(pageLength)
        dispatch(getProjWorkModal(props.token,`/0/0/0/${page}/${pageLength}/${search.length>0?search:'*'}`))
    }
    // const searchByType =debounce(async(value)=>{
    //     // this.props.getClient(this.props.token,`/0/0/0/1/10/${client.search===''?'*':client.search}`)
    //     dispatch(getProjWorkModal(props.token,`/0/0/0/${1}/${10}/${search.length===''?'*':search}`))
    // },1000)
    // const searchToggle=async(e)=>{
    //     let {value}=e.target
        
    //     await setSearch(value)
    //     if(search.length>0){
    //         searchByType(value)
    //     }
        
    // }
    const searchToggle=debounce(async (value)=>{
        setSearch(value)
        // setTextSelect('No options')
        console.log('eyooo',value)
        if(value!==search&&value.length>0){
            // console.log('value eh', value,value.length)
            await dispatch(getProjWorkModal(props.token,`/0/0/0/${1}/${10}/${value.length===''?'*':value}`))
            
        }
        
    },1000)
    const searchToggle2=debounce(async (value)=>{
        setSearch2(value)
        // setTextSelect('No options')

        if(value!==search2&&value.length>0){
            console.log('value eh', value,value.length)
            setLoading(true)
            let res=await dispatch(getClient(props.token,value))
            if(isEmpty(res)){
                setTextSelect('No options')
                // console.log('res', res)
            }
            setLoading(false)
        }
        // console.log('value.value,search', value)
        
    },1000)
    
    const onChangeTribe=(data)=>{
        console.log('data', data)
        dispatch(getProjWorkModal(props.token,`/${data.id}/${filter.clientId.value}/${filter.year}/1/10/*`))
        setFilter({
            ...filter,
            tribeId:data
        })
    }
    const onChangeClient=(data)=>{
        dispatch(getProjWorkModal(props.token,`/${filter.tribeId.id}/${data.value}/${filter.year}/1/10/*`))
        setFilter({
            ...filter,
            clientId:data
        })

    }
    const onChangeYear=(date)=>{
        dispatch(getProjWorkModal(props.token,`/${filter.tribeId.id}/${filter.clientId.value}/${moment(date).format('YYYY')}/1/10/*`))
        setFilter({
            ...filter,
            year:date
        })
    }
    const clickChoose=(data)=>{
        let new_train=[]
        data.trainers.map((dat)=>{
            new_train.push({label:dat.text,value:dat.id})
        })
        dispatch(setAddProjectPayload('name',data.name))
        dispatch(setAddProjectPayload('status',data.status))
        dispatch(setAddProjectPayload('startDate',moment(data.startDate)))
        dispatch(setAddProjectPayload('endDate',moment(data.endDate)))
        dispatch(setAddProjectPayload('keyWords',data.keyWord))
        dispatch(setAddProjectPayload('products',data.products))
        dispatch(setAddProjectPayload('venue',data.venue))
        dispatch(setAddProjectPayload('trainerId',new_train))
        dispatch(setAddProjectPayload('clientId',{label:data.client.text,value:data.client.id}))
        dispatch(setAddProjectPayload('tribeId',data.tribe))
        dispatch(setAddProjectPayload('workshopTypeId',data.workshopTypeId))
        dispatch(setAddProjectPayload('projectAdvisorId',data.projectAdvisor))
        dispatch(setAddProjectPayload('projectLeaderId',data.projectLeader))
        dispatch(setAddProjectPayload('projectMemberIds',data.projectMembers))
        dispatch(setAddProjectPayload('clientProjectOwnerId',data.clientProjectOwner))
        dispatch(setAddProjectPayload('clientProjectLeaderId',data.clientProjectLeader))
        dispatch(setAddProjectPayload('clientProjectMemberIds',data.clientProjectMembers))
        dispatch(setProjectId(data.id))
        dispatch(modalToggleReset())
    }
    return (
        <div style={{maxHeight:400,overflowX:'hidden',overflowY:'auto'}}>
            <div className='modal-filter div-flex div-space-between div-align-center'>
                <div className='div-flex'>
                    <p className='p-filter'><b>Filter by</b></p>&nbsp;&nbsp;&nbsp;
                    <p className='p-filter2'>All tribe in 2020</p>
                </div>
                <div className='rm-filter-btn'>
                    <MuiThemeProvider theme={themeButton}>
                        <Button
                            className='btn-remove-capital btn-rounded btn-flat-height'
                            variant="contained"
                            startIcon={<FilterList />}
                            color='primary'
                            size='small'
                            onClick={()=>setOpenFilter(!open_filter)}
                            // disabled={this.handleDisable()}
                        >
                            Filter
                        </Button>
                        </MuiThemeProvider>
                    </div>
            </div>
            <Collapse in={open_filter}>
            
            <div className='div-flex'>
                <div style={{width:200}}>
                    <AutoCompleteSelect
                        onChange={(event,value)=>onChangeTribe(value)}
                        options={master.tribes}
                        // value={state.tribeId}
                        getOptionLabel={(option) => option.text}
                        label={<>Tribe</>}

                    />
                </div>
                &nbsp;&nbsp;
                <div style={{width:220}}>
                <AutoCompleteSelect
                    color='primary'
                    noOptionsText={textSelect}
                    loading={loading}
                    disableClearable={true}
                    onChange={(event,value)=>onChangeClient(value)}
                    options={master.client}
                    getOptionLabel={(option) => option.label}
                    onInputChange={(event,e)=>searchToggle2(e)}
                    label={<>Client company name</>}
                />
                </div>
                &nbsp;&nbsp;
                <MuiPickersUtilsProvider utils={MomentUtils}>
                <div style={{width:150}}>
                <DatePicker
                    // disableFuture
                    openTo="year"
                    format="dd/MM/yyyy"
                    label="Date of birth"
                    views={["year"]}
                    className={classes.textField}  label='Start date' clearable={true} size='small' inputVariant='outlined'
                    // value={filter_chart}
                    onChange={(date)=>onChangeYear(date)}
                    
                />
                </div>
                </MuiPickersUtilsProvider>
            </div>
            </Collapse>
            <div className='card-table__head'>
                <FormControl variant="outlined">
                    <InputLabel htmlFor="input-with-icon-textfield">Search</InputLabel>
                    <OutlinedInput
                        size='small'
                        style={{height:30,width:200,}}
                        // value={client.search}
                        id="input-with-icon-textfield"
                        name='password'
                        onChange={(e)=>searchToggle(e.target.value)}
                        // onKeyPress={e =>
                        //     handleKeyPress(e)
                        // }
                        required
                        startAdornment={
                        <InputAdornment position="start">
                            <img alt="search" src={SearchImg} style={{width:15}}/>
                        </InputAdornment>
                        }
                        labelWidth={50}
                    />
                </FormControl>
            </div>
            <div className='card-table__content'>
            <Table  size="small" aria-label="a dense table">
                <TableHead>
                <TableRow>
                    <TableCell align="left">Workshop name</TableCell>
                    <TableCell align="left">Tribe owner</TableCell>
                    <TableCell align="left">Client company</TableCell>
                    <TableCell align="left">client Workshop owner</TableCell>
                    <TableCell align="center">Status</TableCell>
                    <TableCell align="center">Action</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                    {general.isLoadingTable2?
                    <TableRow>
                        <TableCell><Skeleton count={6} height={20}/></TableCell>
                        <TableCell><Skeleton count={6} height={20}/></TableCell>
                        <TableCell><Skeleton count={6} height={20}/></TableCell>
                        <TableCell><Skeleton count={6} height={20}/></TableCell>
                        <TableCell><Skeleton count={6} height={20}/></TableCell>
                        <TableCell><Skeleton count={6} height={20}/></TableCell>
                    </TableRow>
                    
                    :list_project_for_modal.map((data,i)=>(
                        <TableRow key={i}>
                            <TableCell align="left">{data.name}</TableCell>
                            <TableCell align="left">{data.tribe!==null&&data.tribe.text}</TableCell>
                            <TableCell align="left">{data.client!==null&&data.client.text}</TableCell>
                            <TableCell align="left">{data.clientProjectOwner!==null&&data.clientProjectOwner.text}</TableCell>
                            <TableCell align="center">{data.status}</TableCell>
                            <TableCell align="center">
                            <MuiThemeProvider theme={themeButton}>
                            <Button
                                className='btn-remove-capital btn-rounded btn-flat-height'
                                variant="contained"
                                color='primary'
                                size='small'
                                onClick={()=>clickChoose(data)}
                                // disabled={this.handleDisable()}
                            >
                                Choose
                            </Button>
                            </MuiThemeProvider>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
            <div className='card-table__pagination'>
                {work_proj_pagination!==null&&
                <TablePagination
                        className="card-pagination"
                        type="reduced"
                        page={work_proj_pagination.page}
                        pageLength={work_proj_pagination.perPage}
                        totalRecords={work_proj_pagination.total}
                        onPageChange={({ page, pageLength }) => {
                            togglePagination(page,pageLength)
                        }}
                        prevPageRenderer={() => <img src={CevronLeft} style={{width:10}}/>}
                        nextPageRenderer={() => <img src={CevronRight}/>}
                    />
                }
            </div>
            </div>
            <br/>
        </div>
    )
}
