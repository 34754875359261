import * as actionType from '../constants/files'
import {setLoading,setLoadingTable,setLoadingTable3,modalToggle,setLoadingTable2} from './general'
// import {alertToggle} from './alert'
import {apiCall} from '../../service/apiCall'
import { get } from "lodash";
import { act } from 'react-dom/test-utils';

export const setTribeId=(data)=>dispatch=>{
    dispatch({
        type:actionType.SET_TRIBE_ID,
        payload:data
    })
}
export const resetAddProject=(data)=>dispatch=>{
    dispatch({
        type:actionType.RESET_ADD_PROJECT,
        payload:data
    })
}
export const setUpload=(data)=>dispatch=>{
    dispatch({
        type:actionType.SET_UPLOAD,
        payload:data
    })
}
export const setProjectId=(data)=>dispatch=>{
    dispatch({
        type:actionType.SET_PROJECT_ID,
        payload:data
    })
}
export const setAddProjectPayload=(key,value)=>dispatch=>{
    dispatch({
        type:actionType.SET_ADD_PROJECT_PAYLOAD,
        payload:{
          [key]:value
        }
    })
}
export const setClientId=(data)=>dispatch=>{
    dispatch({
        type:actionType.SET_CLIENT_ID,
        payload:data
    })
}
export const setYear=(data)=>dispatch=>{
    dispatch({
        type:actionType.SET_YEAR,
        payload:data
    })
}
export const setFile=(data)=>dispatch=>{
    dispatch({
        type:actionType.SET_FILE,
        payload:data
    })
}
export const setFolder=(data)=>dispatch=>{
    dispatch({
        type:actionType.SET_FOLDER,
        payload:data
    })
}
export const setQuick=(data)=>dispatch=>{
    dispatch({
        type:actionType.SET_QUICK,
        payload:data
    })
}
export const setBreadCrum=(data)=>dispatch=>{
    dispatch({
        type:actionType.SET_BREAD_CRUM,
        payload:data
    })
}
export const setSideBar=(data)=>dispatch=>{
    dispatch({
        type:actionType.SET_SIDE_BAR,
        payload:data
    })
}
export const setActiveFolder=(data)=>dispatch=>{
    dispatch({
        type:actionType.SET_ACTIVE_FOLDER,
        payload:data
    })
}
export const setActiveProject=(data)=>dispatch=>{
    dispatch({
        type:actionType.SET_ACTIVE_PROJECT,
        payload:data
    })
}
export const setFilesAction=(tab_active,tab_back)=>dispatch=>{
    dispatch({
        type:actionType.SET_FILES_ACTION,
        payload:{
          tab_active:tab_active,tab_back:tab_back
        }
    })
}
export const getTree=(token)=>async dispatch=>{
    
      
    
    dispatch(setLoadingTable(true))
    let dataReq={
        url:`/km/treeclient`,
        method:'GET',
        data:{
            headers:{ 'Authorization': `Bearer ${token}`},
        }
    }
    let res=await dispatch(apiCall(dataReq))
    if(get(res,'status')==200){
        dispatch(setLoadingTable(false))
        let new_side=[]
        res.data.map((data,i)=>{
          let children1=[]
          if(data.children.length>0){
            data.children.map((data2)=>{
              let children2=[]
              if(data2.children.length>0){
                data2.children.map((data3)=>{
                  let children3=[]
                  if(data3.children.length>0){
                    data3.children.map((data4)=>{
                      children3.push({id:data4.id,text:data4.text,children:data4.children,slug:`${data4.id}-${data4.text}`})
                    })
                  }
                  children2.push({id:data3.id,text:data3.text,children:children3,slug:`${data3.id}-${data3.text}`})
                })
              }
              children1.push({id:data2.id,text:data2.text,children:children2,slug:`${data2.id}-${data2.text}`})
            })
          }
         
          new_side.push({id:data.id,text:data.text,children:children1,slug:`${data.id}-${data.text}`})
        })
        // console.log('new_side', new_side)
        dispatch({
            type:actionType.SET_SIDE_BAR,
            payload:new_side
        })
        return new_side
    }else{
        dispatch(setLoadingTable(false))

    }
}

export const getProject=(token,slug)=>async dispatch=>{
      dispatch(setLoading(true))
      let dataReq={
          url:`/km/projects${slug}`,
          method:'GET',
          data:{
              headers:{ 'Authorization': `Bearer ${token}`},
          }
      }
      let res=await dispatch(apiCall(dataReq))
      if(get(res,'status')==200){

        let folder=[]
        let file=[]
        res.data.content.folders.map((data,i)=>{
          folder.push(data)
        })
        res.data.content.files.map((data,i)=>{
          file.push(data)
        })
        dispatch({
            type:actionType.SET_FOLDER,
            payload:folder
        })
        dispatch({
            type:actionType.SET_FILE,
            payload:file
        })
          dispatch(setLoading(false))
        return res.data

      }
        
}
export const getProjectInfo=(token,slug)=>async dispatch=>{
      dispatch(setLoadingTable3(true))
      let dataReq={
          url:`/km/projects${slug}`,
          method:'GET',
          data:{
              headers:{ 'Authorization': `Bearer ${token}`},
          }
      }
      let res=await dispatch(apiCall(dataReq))
      if(get(res,'status')==200){
        dispatch({
          type:actionType.GET_PROJECT_INFO,
          payload:res.data
        })
        
        dispatch(setLoadingTable3(false))
        return res

      }
        
}

export const getQuick=(token,slug)=>async dispatch=>{
  dispatch(setLoadingTable2(true))
  let dataReq={
      url:`/km/quick${slug}`,
      method:'GET',
      data:{
          headers:{ 'Authorization': `Bearer ${token}`},
      }
  }
  let res=await dispatch(apiCall(dataReq))
  if(get(res,'status')==200){
      dispatch({
        type:actionType.SET_QUICK,
        payload:res.data
      })
      dispatch(setLoadingTable2(false))

      // console.log('new_side', new_side)
      // dispatch({
      //     type:actionType.SET_SIDE_BAR,
      //     payload:new_side
      // })
  }
}
export const getLast=(token,slug)=>async dispatch=>{
  dispatch(setLoadingTable2(true))
  let dataReq={
      url:`/km/last${slug}`,
      method:'GET',
      data:{
          headers:{ 'Authorization': `Bearer ${token}`},
      }
  }
  let res=await dispatch(apiCall(dataReq))
  if(get(res,'status')==200){
      dispatch(setLoadingTable2(false))
      dispatch({
        type:actionType.SET_LAST_ACTIVITY,
        payload:res.data
      })
      // console.log('new_side', new_side)
      // dispatch({
      //     type:actionType.SET_SIDE_BAR,
      //     payload:new_side
      // })
  }
}

export const viewFile=(token,slug)=>async(dispatch)=>{
  dispatch(setLoading(true))

  let dataReq={
      url:`/File/view${slug}`,
      method:'GET',
      data:{
          headers:{ 'Authorization': `Bearer ${token}`},
      }
  }
  let res=await dispatch(apiCall(dataReq))
  if(get(res,'status')==200){
      window.open(res.data, '_blank');
      dispatch(setLoading(false))
      
  }else{
      dispatch(setLoading(false))

  }
}
export const viewVideo=(token,slug)=>async(dispatch)=>{
  dispatch(setLoading(true))

  let dataReq={
      url:`/File/view${slug}`,
      method:'GET',
      data:{
          headers:{ 'Authorization': `Bearer ${token}`},
      }
  }
  let res=await dispatch(apiCall(dataReq))
  if(get(res,'status')==200){
    dispatch(modalToggle({
        modal_open: true,
        modal_title: "",
        modal_component: "view_video",
        modal_data:{url:res.data},
        modal_size:600,
        modal_action:'view_video'
    }))
    //   window.open(res.data, '_blank');
      dispatch(setLoading(false))
      
  }else{
      dispatch(setLoading(false))

  }
}


export const getFilterProject=(token,slug)=>async(dispatch)=>{
  dispatch(setLoadingTable2(true))
  let dataReq={
    url:`/km/filtersort${slug}`,
    method:'GET',
    data:{
        headers:{ 'Authorization': `Bearer ${token}`},
    }
  }
  let res=await dispatch(apiCall(dataReq))
  if(get(res,'status')==200){
      dispatch({
        type:actionType.SET_WORK_PROJ,
        payload:res.data
      })
      dispatch(setLoadingTable2(false))

  }else{
      dispatch(setLoadingTable2(false))

  }
}
export const getBreadCrump=(token,slug)=>async(dispatch)=>{
  dispatch(setLoading(true))
  let dataReq={
    url:`/km/parents${slug}`,
    method:'GET',
    data:{
        headers:{ 'Authorization': `Bearer ${token}`},
    }
  }
  let res=await dispatch(apiCall(dataReq))
  if(get(res,'status')==200){
      
      dispatch(setLoading(false))

  }else{
      dispatch(setLoading(false))

  }
}
export const getProjWorkModal=(token,slug)=>async(dispatch)=>{
  dispatch(setLoadingTable2(true))
  let dataReq={
    url:`/km/filter/${slug}`,
    method:'GET',
    data:{
        headers:{ 'Authorization': `Bearer ${token}`},
    }
  }
  let res=await dispatch(apiCall(dataReq))
  if(get(res,'status')==200){
      
      dispatch(setLoadingTable2(false))
      dispatch({
        type:actionType.LIST_PROJECT_FOR_MODAL,
        payload:{
          project:res.data.projects,
          pagination:res.data.info
        }
      })

  }else{
      dispatch(setLoadingTable2(false))

  }
}


export const getProduct=(token)=>async(dispatch)=>{
  dispatch(setLoading(true))
  let dataReq={
    url:`/km/products`,
    method:'GET',
    data:{
        headers:{ 'Authorization': `Bearer ${token}`},
    }
  }
  let res=await dispatch(apiCall(dataReq))
  if(get(res,'status')==200){
      
      dispatch(setLoading(false))
      dispatch({
        type:actionType.GET_PRODUCT,
        payload:res.data
      })

  }else{
      dispatch(setLoading(false))

  }
}

export const postProject=(token,data)=>async(dispatch)=>{
  dispatch(setLoading(true))
  let dataReq={
    url:`/km/projects`,
    method:'POST',
    data:{
        data,
        headers:{ 'Authorization': `Bearer ${token}`},
    }
  }
  let res=await dispatch(apiCall(dataReq))
  if(get(res,'status')==200){
      
      dispatch(setLoading(false))
      dispatch({
          type:actionType.DETAIL_AFTER_POST_PROJ_WORK,
          payload:res.data
      })
      return res.data
      // dispatch({
      //   type:actionType.GET_PRODUCT,
      //   payload:res.data
      // })

  }else{
      dispatch(setLoading(false))

  }
}
export const postWorkshop=(token,data)=>async(dispatch)=>{
  dispatch(setLoading(true))
  let dataReq={
    url:`/km/projects`,
    method:'POST',
    data:{
        data,
        headers:{ 'Authorization': `Bearer ${token}`},
    }
  }
  let res=await dispatch(apiCall(dataReq))
  if(get(res,'status')==200){
      
      dispatch(setLoading(false))
      return res.data
      // dispatch({
      //   type:actionType.GET_PRODUCT,
      //   payload:res.data
      // })

  }else{
      dispatch(setLoading(false))

  }
}
export const postFolder=(token,data,slugget)=>async(dispatch)=>{
    dispatch(setLoading(true))
    let dataReq={
      url:`/km/folder`,
      method:'POST',
      data:{
          data,
          headers:{ 'Authorization': `Bearer ${token}`},
      }
    }
    let res=await dispatch(apiCall(dataReq))
    if(get(res,'status')==200){
        dispatch(setLoading(false))
        if(slugget!==null){
            dispatch(getProject(token,slugget))
        }
        dispatch(modalToggle({ 
            modal_open: true,
            modal_title: "Add folder success",
            modal_component: "add_folder",
            modal_size:400,
            modal_type:'alert',
            modal_data:{
                msg:`<p>Add folder success</p> `,
                ...res.data
            },
            modal_action:'success',
            // success_msg:success_msg
        }))
        return res.data

    }else{
        dispatch(setLoading(false))

    }
}
export const postFolder2=(token,data,slugget)=>async(dispatch)=>{
    dispatch(setLoading(true))
    let dataReq={
      url:`/km/folder`,
      method:'POST',
      data:{
          data,
          headers:{ 'Authorization': `Bearer ${token}`},
      }
    }
    let res=await dispatch(apiCall(dataReq))
    if(get(res,'status')==200){
        dispatch(setLoading(false))
        if(slugget!==null){
            dispatch(getProject(token,slugget))
        }
        dispatch(modalToggle({ 
            modal_open: true,
            modal_title: "Add folder success",
            modal_component: "add_folder",
            modal_size:400,
            modal_type:'alert',
            modal_data:{
                msg:`<p>Add folder success</p> `,
                ...res.data
            },
            modal_action:'success',
            // success_msg:success_msg
        }))
        return res.data

    }else{
        dispatch(setLoading(false))

    }
}
export const postParticipant=(token,data,slugget)=>async(dispatch)=>{
    dispatch(setLoading(true))
    let dataReq={
      url:`/km/participants`,
      method:'POST',
      data:{
          data,
          headers:{ 'Authorization': `Bearer ${token}`},
      }
    }
    let res=await dispatch(apiCall(dataReq))
    if(get(res,'status')==200){
        dispatch(setLoading(false))
        dispatch(getParticipant(token,data.projectId))
        dispatch(modalToggle({ 
            modal_open: true,
            modal_title: "Add participant success",
            modal_component: "add_folder",
            modal_size:400,
            modal_type:'alert',
            modal_data:{
                msg:`<p>success add participant</p> `,
                ...res.data
            },
            modal_action:'success',
            // success_msg:success_msg
        }))
        return res.data

    }else{
        dispatch(setLoading(false))

    }
}
export const updateParticipant=(token,data,slugget)=>async(dispatch)=>{
    dispatch(setLoading(true))
    let dataReq={
      url:`/km/participants`,
      method:'PUT',
      data:{
          data,
          headers:{ 'Authorization': `Bearer ${token}`},
      }
    }
    let res=await dispatch(apiCall(dataReq))
    if(get(res,'status')==204){
        dispatch(setLoading(false))
        dispatch(getParticipant(token,data.projectId))
        dispatch(modalToggle({ 
            modal_open: true,
            modal_title: "Update participant success",
            modal_component: "add_folder",
            modal_size:400,
            modal_type:'alert',
            modal_data:{
                msg:`<p>Success update participant</p> `,
                ...res.data
            },
            modal_action:'success',
            // success_msg:success_msg
        }))
        return res.data

    }else{
        dispatch(setLoading(false))

    }
}
export const deleteParticipant=(token,projectId,participantId)=>async(dispatch)=>{
    dispatch(setLoading(true))
    let dataReq={
      url:`/km/participants/${projectId}/${participantId}`,
      method:'DELETE',
      data:{
          headers:{ 'Authorization': `Bearer ${token}`},
      }
    }
    let res=await dispatch(apiCall(dataReq))
    if(get(res,'status')==204){
        dispatch(setLoading(false))
        dispatch(getParticipant(token,projectId))
        dispatch(modalToggle({ 
            modal_open: true,
            modal_title: "Delete participant success",
            modal_component: "add_folder",
            modal_size:400,
            modal_type:'alert',
            modal_data:{
                msg:`<p>success deleting participant</p> `,
                ...res.data
            },
            modal_action:'success',
            // success_msg:success_msg
        }))
        return res.data

    }else{
        dispatch(setLoading(false))

    }
}
export const getParticipant=(token,projectId)=>async(dispatch)=>{
    dispatch(setLoading(true))
    let dataReq={
      url:`/km/participants/${projectId}`,
      method:'GET',
      data:{
          headers:{ 'Authorization': `Bearer ${token}`},
      }
    }
    let res=await dispatch(apiCall(dataReq))
    if(get(res,'status')==200){
        dispatch(setLoading(false))
        dispatch({
            type:actionType.GET_PARTICIPANT,
            payload:res.data
        })
        return res.data

    }else{
        dispatch(setLoading(false))

    }
}
export const putFolder=(token,data,slugget)=>async(dispatch)=>{
    dispatch(setLoading(true))
    let dataReq={
      url:`/km/folder/${data.id}`,
      method:'PUT',
      data:{
          data,
          headers:{ 'Authorization': `Bearer ${token}`},
      }
    }
    let res=await dispatch(apiCall(dataReq))
    if(get(res,'status')==200){
        dispatch(setLoading(false))
      
        dispatch(getProject(token,slugget))
        dispatch(modalToggle({ 
            modal_open: true,
            modal_title: "Update folder success",
            modal_component: "add_folder",
            modal_size:400,
            modal_type:'alert',
            modal_data:{
                msg:`<p>${res.data.description} success updated</p> `,
                ...res.data
            },
            modal_action:'success',
            // success_msg:success_msg
        }))
        return res.data

    }else{
        dispatch(setLoading(false))

    }
}
export const putProject=(token,data)=>async(dispatch)=>{
    dispatch(setLoading(true))
    let dataReq={
      url:`/km/projects/${data.id}`,
      method:'PUT',
      data:{
          data,
          headers:{ 'Authorization': `Bearer ${token}`},
      }
    }
    let res=await dispatch(apiCall(dataReq))
    if(get(res,'status')==200){
        // dispatch(setLoading(false))
      
        // dispatch(getProject(token,slugget))
        dispatch(modalToggle({ 
            modal_open: true,
            modal_title: "Update success",
            modal_component: "add_folder",
            modal_size:400,
            modal_type:'alert',
            modal_data:{
                msg:`<p>${res.data.project.name} success updated</p> `,
                ...res.data
            },
            modal_action:'success',
            // success_msg:success_msg
        }))
        return res.data

    }else{
        dispatch(setLoading(false))

    }
}
export const deleteFolderFile=(token,id,slugget)=>async(dispatch)=>{
    dispatch(setLoading(true))
    let dataReq={
      url:`/km/deletefolderfile/${id}`,
      method:'DELETE',
      data:{
          headers:{ 'Authorization': `Bearer ${token}`},
      }
    }
    let res=await dispatch(apiCall(dataReq))
    if(get(res,'status')==200){
        dispatch(setLoading(false))
      
        dispatch(getProject(token,slugget))
        dispatch(modalToggle({ 
            modal_open: true,
            modal_title: "Delete success",
            modal_component: "add_folder",
            modal_size:400,
            modal_type:'alert',
            modal_data:{
                msg:`<p>${res.data.name} success deleted</p> `,
                ...res.data
            },
            modal_action:'success',
            // success_msg:success_msg
        }))
        return res.data

    }else{
        dispatch(setLoading(false))

    }
}
export const uploadFile=(token,data,slugget)=>async(dispatch)=>{
    dispatch(setLoading(true))
    let dataReq={
      url:`/upload/file`,
      method:'POST',
      data:{
          data,
          headers:{ 'Authorization': `Bearer ${token}`,'Content-Type': 'multipart/form-data'},
      }
    }
    let res=await dispatch(apiCall(dataReq))
    if(get(res,'status')==200){
        dispatch(setLoading(false))
        if(slugget!==null){
            dispatch(getProject(token,slugget))
        }
        
        dispatch(modalToggle({ 
            modal_open: true,
            modal_title: "Upload file",
            modal_component: "add_folder",
            modal_size:400,
            modal_type:'alert',
            modal_data:{
                msg:`<p>${res.data.description}</p> `,
                ...res.data
            },
            modal_action:'success_upload',
            // success_msg:success_msg
        }))
        return res.data

    }else{
        dispatch(setLoading(false))

    }
}
export const uploadFileBase64=(token,data,slugget,breadcrump=null)=>async(dispatch)=>{
    dispatch(setLoading(true))
    let dataReq={
      url:`/upload/filebase64`,
      method:'POST',
      data:{
          data,
          headers:{ 'Authorization': `Bearer ${token}`},
      }
    }
    let res=await dispatch(apiCall(dataReq))
    if(get(res,'status')==200){
        dispatch(setLoading(false))
        if(slugget!==null){
            dispatch(getProject(token,slugget))
        }
        
        dispatch(modalToggle({ 
            modal_open: true,
            modal_title: "Upload file",
            modal_component: "upload_file",
            modal_size:400,
            modal_type:'alert',
            modal_data:{
                msg:`<p>${res.data.description}</p> `,
                ...res.data,
                projectId:data.projectId,
                parentId:data.parentId,
                breadcrump
            },
            modal_action:'success_upload',
            // success_msg:success_msg
        }))
        dispatch(setUpload([]))

        return res.data

    }else{
        dispatch(setLoading(false))

    }
}
export const updateFile=(token,data,slugget)=>async(dispatch)=>{
    dispatch(setLoading(true))
    let dataReq={
      url:`/upload/updatefile`,
      method:'POST',
      data:{
          data,
          headers:{ 'Authorization': `Bearer ${token}`},
      }
    }
    let res=await dispatch(apiCall(dataReq))
    if(get(res,'status')==200){
        dispatch(setLoading(false))
        if(slugget!==null){
            dispatch(getProject(token,slugget))
        }
        
        dispatch(modalToggle({ 
            modal_open: true,
            modal_title: "Update file",
            modal_component: "add_folder",
            modal_size:400,
            modal_type:'alert',
            modal_data:{
                msg:`<p>${res.data.description}</p> `,
                ...res.data
            },
            modal_action:'success_upload',
            // success_msg:success_msg
        }))
        return res.data

    }else{
        dispatch(setLoading(false))

    }
}

