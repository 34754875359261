import React,{useState,useEffect} from 'react'
import './style.css'
import Logo from 'assets/image/logo_topbar.svg'
import {Input,Button} from '@material-ui/core'
import Search from 'assets/icon/Search.svg'
import avadefault from 'assets/icon/avadefault.svg'
import notif from 'assets/icon/notif.svg'
import {modalToggle} from 'redux/actions/general'
import {useDispatch,useSelector} from 'react-redux'
import Profile from './profile'
import Notification from './notification'
import { debounce } from 'lodash'
import {getSearch,setSearch} from 'redux/actions/search'
import { getMasterData } from 'redux/actions/master'
export default function Index(props) {
    const [open_profile,setOpenProfile]=useState(false)
    const [open_notif,setOpenNotif]=useState(false)
    const search = useSelector(state => state.search)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getMasterData())
    }, [])
    const profileClick=()=>{
        setOpenProfile(!open_profile)
        // dispatch(modalToggle({ 
        //     modal_open: true,
        //     modal_title: ``,
        //     modal_component: "",
        //     modal_size:300,
        //     modal_type:'profile',
        //     modal_data:null,
        //     modal_action:'profile'
        // }))
    }
    const notifClick=()=>{
        setOpenNotif(!open_notif)
    }
    const advancedSearch=()=>{
        props.searchToggle(true)
        dispatch(modalToggle({ 
            modal_open: true,
            modal_title: `Advanced Search`,
            modal_component: "advanced_search",
            modal_size:450,
            modal_type:'',
            modal_data:null,
            modal_action:'advanced_search'
        }))
    }
    const searchToggle=debounce(async (value)=>{
        // setSearch(value)
        dispatch(setSearch({keyword:value}))
        dispatch(getSearch(`/0/0/*/*/${value!==""?value:'*'}/1/10`))
        props.searchToggle(true)

    },1000)
    return (
        <nav>
            {open_profile&&<div onClick={profileClick} className='backdrop-nav'></div>}
            {/* {open_notif&&<div onClick={notifClick} className='backdrop-nav'></div>}
            <div className='hamburger' onClick={props.sidebarToggle}>
                <div className='hamburger__list'/>
                <div className='hamburger__list'/>
                <div className='hamburger__list'/>
            </div> */}
            <div className='logo' onClick={()=>window.location.assign('/')}>
                <img src={Logo} />
                <div className='vertical-line'></div>
                <h4>Knowledge Center</h4>
            </div>
            <div className='search hide-mobile'>
                <img src={Search}/>
                <Input 
                    disableUnderline 
                    className='search-field'
                    placeholder="Ketik kata kunci pencarian"
                    // value={search.keyword}
                    // onFocus={()=>props.searchToggle(true)}
                    onChange={(e)=>searchToggle(e.target.value)}
                    // onBlur={props.searchToggle}
                />
                <p onClick={advancedSearch} style={{margin:0,color:'#65b7ff',cursor:'pointer'}}>Advanced Search</p>
            </div>
            {/* <div style={{flex:1}}></div> */}
            <div className='div-flex' style={{marginRight:90}}>
            {/* <div className='notif hide-mobile'>
                {open_notif&&<div className='notif-wrapper'>
                    <Notification profile={props.profile} token={props.token}/>
                </div>}
                <div className='circle-notif' onClick={notifClick}>
                    <img src={notif} style={{width:23}} />
                    <div className='sub-circle-notif'>2</div>
                </div>
            </div> */}
            &nbsp;&nbsp;&nbsp;
            <div className='profile hide-mobile'>
                {open_profile&&<div className='profile-wrapper'>
                    <Profile profile={props.profile} token={props.token}/>
                </div>}
                <img className='avadefault' src={avadefault} style={{width:40}} onClick={profileClick}/>
            </div>
            </div>
        </nav>
    )
}
