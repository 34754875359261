import React ,{useEffect,useState}from 'react'
import * as actionType from 'redux/constants/files'
import { MuiThemeProvider, createMuiTheme,withStyles,
} from '@material-ui/core/styles'
import './style.css'
import {Button,Table,TableBody,TableCell,TableHead,TableRow} from '@material-ui/core'
import { useDispatch, useSelector } from "react-redux";
import MoreVertIcon from '@material-ui/icons/MoreVert';
import {modalToggle} from 'redux/actions/general'
import {getTree,getQuick,setFile,setFolder,setQuick,setBreadCrum,getProject,viewFile,getLast,getFilterProject,getProjectInfo,getBreadCrump,setActiveProject, setProjectId,updateFile,viewVideo} from 'redux/actions/files'
import moment from 'moment'
import Skeleton  from 'react-loading-skeleton';
import Properties from './properties'
import PDFicon from 'assets/icon/PDFicon.svg'
import Allfileicon from 'assets/icon/Allfileicon.svg'
import Musicicon from 'assets/icon/Musicicon.svg'
import Movieicon from 'assets/icon/Movieicon.svg'
import Imageicon from 'assets/icon/Imageicon.svg'
import Wordicon from 'assets/icon/Wordicon.svg'
import PPTicon from 'assets/icon/PPTicon.svg'
import ExcelIcon from 'assets/icon/ExcelIcon.svg'
import CSVicon from 'assets/icon/CSVicon.svg'
import {handleFile,getBase64} from 'components/handleFile'
const themeButton = createMuiTheme({ 
    palette: { 
        primary: {
            main:'#FF6E79',
            contrastText: '#FFFFFF',
        },
        secondary: {
            main:'#FFC466',
            contrastText: '#FFFFFF',
        }
    } 
})
export default function Folder(props) {
    const files=useSelector(state=>state.files)
    const general=useSelector(state=>state.general)
    const [option_modal,setModal]=useState(false)
    const [showCard,showToggle]=useState(true)
    const [showCard2,showToggle2]=useState(true)
    const [option_modal2,setModal2]=useState('')
    const [backdrop,setBackdrop]=useState(false)


    const [clickTimeout,setClickTimeout]=useState(null)
    const {data_project,last_activity,active_project,folder,bread_crum,active_folder,quick_access,file_access,folder_access,tribe_id,client_id,year,work_proj,project_info}=files
    const dispatch=useDispatch()
    let quick=[]
    let file=[]
    let folder_dum=[]
    useEffect(()=>{
        if(bread_crum.length<4){
            if(bread_crum.length===0){
                dispatch(getQuick(props.token,`/${props.profile.id}/0/${0}/${0}/0/${bread_crum.length>0?4:8}`))

            }
            if(bread_crum.length===1){
                dispatch(getQuick(props.token,`/${props.profile.id}/0/${tribe_id}/${0}/0/${bread_crum.length>0?4:8}`))

            }
            if(bread_crum.length===2){
                dispatch(getQuick(props.token,`/${props.profile.id}/0/${tribe_id}/${client_id}/0/${bread_crum.length>0?4:8}`))

            }
            if(bread_crum.length===3){
                dispatch(getQuick(props.token,`/${props.profile.id}/0/${tribe_id}/${client_id}/${year}/${bread_crum.length>0?4:8}`))

            }

        }
        if(last_activity.length<1){
            dispatch(getLast(props.token,`/0/7`))
        }
        setModal(false)
    },[bread_crum]) 
    const toSubFolder=async (data)=>{
        setModal(false)
        dispatch(setQuick([]))
        dispatch(setProjectId(data.id))

        let res=await dispatch(getProject(props.token,`/${data.id}/${0}`))
        if(res){
            let slug=[]
            res.breadcrump.map((data)=>{
                slug.push({...data,slug:`${data.id}-${data.text}`})
            })
            // let new_bread_crum=[
            //     ...slug,
            //     {
            //         text:data.name,
            //         slug:`${data.id}-${data.text}`,
            //         ...data
            //     }
            // ]
            dispatch(setBreadCrum(slug))
            dispatch({
                type:actionType.SET_WORK_PROJ,
                payload:[]
            })
        }
        

    }
    const toSubFolder2=(data)=>{
        // dispatch(setActiveProject(null))
        // dispatch(getBreadCrump(props.token,`/${data.id}`))
        dispatch(getProject(props.token,`/${files.project_id}/${data.id}`))

        let new_bread_crum=[
            ...bread_crum,
            {
                text:data.name,
                ...data
            }
        ]
        dispatch(setBreadCrum(new_bread_crum))
        dispatch({
            type:actionType.SET_WORK_PROJ,
            payload:[]
        })

    }
    let timer = 0;
  let delay = 200;
  let prevent = false;
  const handleClick=(data) =>{
    timer = setTimeout(function() {
      if (!prevent) {
        showInfoWorkProj(data);
       
      }
      prevent = false;
    }, delay);
  }
  const handleDoubleClick=(data)=>{
    clearTimeout(timer);
    prevent = true;
    toSubFolder(data);
  }
    
    const showInfoWorkProj=async (data)=>{
        if(data.id===files.project_id){
            dispatch(setProjectId(null))
            dispatch(setActiveProject(null))
            setModal(false)
        }else{
            dispatch(setProjectId(data.id))
            dispatch(setActiveProject(data.id))
            setModal(true)
        }
        

        
    }
    const viewFiles=(id,file)=>{
        if(file.fileType==='mp4'){
            dispatch(viewVideo(props.token,`/4/${id}/${props.profile.id}`))
        }else{
            dispatch(viewFile(props.token,`/4/${id}/${props.profile.id}`))

        }
    }
    
    const editFolder=async (data)=>{
        dispatch(modalToggle({
            modal_open: true,
            modal_title: "Edit Folder",
            modal_component: "add_folder3",
            modal_data:{
                parentId:bread_crum.length<5?0:bread_crum[bread_crum.length-1].id,
                projectId:files.project_id,
                onegml:0,
                name:data.name,
                description:data.description,
                id:data.id
            } ,
            modal_size:300,
            modal_action:'edit_folder3'
        }))
        
    }
    const deleteFileOrFolder=(data)=>{
        dispatch(modalToggle({ 
            modal_open: true,
            modal_title: `File / Folder`,
            modal_component: "confirm_delete",
            modal_size:400,
            modal_type:'confirm',
            modal_data:{
                title:'File / Folder',
                id:data.id,
                userId:props.profile.id,
                msg:`<p>Delete <b>${data.name}</b> will impact to project or workshop connected to <b>${data.name}</b>. make sure you have downloaded file project or workshop.</p>`,
                slugget:`/${files.project_id}/${bread_crum.length<5?0:bread_crum[bread_crum.length-1].id}`,
            },
            modal_action:'delete_folderfile3'
        }))
    }
    const onChangeUpdate=(evt,fileId)=>{
        let handle=handleFile(evt)
        let last_bread=files.bread_crum[files.bread_crum.length-1]
        
        if(handle.file_error===null){
            getBase64(handle.file,(result)=>{
                let data={
                    fileId:fileId,
                    userId:props.profile.id,
                    filename:handle.file_name,
                    fileBase64:result.split("base64,")[1]
                }
                dispatch(updateFile(props.token,data,`/${files.project_id}/${bread_crum.length<5?0:bread_crum[bread_crum.length-1].id}`))
            })
           
        }else{
            // setError(handle.file_error)
        }
    }
    const openModal=(id)=>{
        backdropToggle()

        setModal2({
            [id]:!option_modal[id]
        })
    }
    const backdropToggle=()=>{
        setBackdrop(!backdrop)
        setModal2('')
    }
    
    const renderIcon=(type)=>{
        switch (type) {
            case 'pdf':
                return PDFicon
                break;
            case 'doc':
                return Wordicon
                break;
            case 'docx':
                return Wordicon
                break;
            case 'ppt':
                return PPTicon
                break;
            case 'pptx':
                return PPTicon
                break;
            case 'xls':
                return ExcelIcon
                break;
            case 'xlsx':
                return ExcelIcon
                break;
            case 'png':
                return Imageicon
                break;
            case 'jpg':
                return Imageicon
                break;
        
            default:
                return Allfileicon
                break;
        }
    }
    const CardAccess=(props)=>{
        return(
            <div   className={`card-access ${props.data.id===active_project&&'card-active'}`}>
                <div className='card-access-body'>
                    <div onClick={()=>toSubFolder2(props.data)}>
                    <div className='div-flex div-space-between' >
                        <p  className='card-access-title' style={{margin:0}}>{props.data.name}</p>
                        <div style={{width:30,height:20,borderRadius:5,backgroundColor:'rgb(173, 191, 247)'}}></div>
                    </div>
                    <div>
                    <p className='card-access-subtitle'>{props.data.tribe}</p>
                    </div>
                    </div>
                    <div className='div-flex div-space-between div-align-center' style={{position:'relative'}}>
                    {props.data.type==='file'?<p className='card-access-subtitle'>Upload at : {moment(props.data.last_update).format('MMMM YYYY')}</p>:<p className='card-access-subtitle'>Project year {moment(props.data.date).format('YYYY')}</p>}
                    <MoreVertIcon onClick={()=>openModal(props.data.id)} color="disabled" style={{cursor:'pointer'}}/>
                    <div className={`option-modal ${option_modal2[props.data.id]&&'option-modal-active'}`}>
                        <p className='blue-text' onClick={()=>editFolder(props.data)}>Edit Folder</p>
                        <p className='red-text' onClick={()=>deleteFileOrFolder(props.data)}>Delete Folder</p>
                    </div>
                    </div>
                </div>
               
            </div>
        )
    }
    const FileAccess=(props)=>{
        return(
            <div  className={`card-access ${props.data.id===active_project&&'card-active'}`}>
                <div className='card-access-body'  >
                    <div className='div-flex div-space-between' style={{position:'relative'}}>
                        <p  className='card-access-title' style={{margin:0,width:'85%'}} title={props.data.name}>{props.data.name.length>25?`${props.data.name.substring(0,25)}...`:props.data.name}</p>
                        <img style={{width:20,position:'absolute',right:0}} src={renderIcon(props.data.fileType)}/>
                        {/* <div style={{width:30,height:20,borderRadius:5,backgroundColor:'#ff9aa2'}}></div> */}
                    </div>
                    <div>
                    <p className='card-access-subtitle'>{props.data.tribe}</p>
                    <div className='div-flex div-space-between div-align-center'>
                    <p className='card-access-subtitle'>Upload at : {moment(props.data.date).format('MMMM YYYY')}</p>
                    {/* {props.data.type==='folder'&&<MoreVertIcon onClick={editFolder} color="disabled" style={{cursor:'pointer'}}/>} */}
                    </div>
                    </div>
                </div>
                <>
                <div style={{width:'100%',height:1,backgroundColor:'#ccc'}}></div>
                <div className='card-access-footer'>
                    {/* <Button onClick={null} size='small' style={{height:30,fontSize:12,margin:0}} color='primary' variant='text' className='remove-capital'>Download</Button> */}
                    <Button onClick={()=>viewFiles(props.data.id,props.data)} size='small' style={{height:30,fontSize:12,margin:0,width:'20px!important'}} color='primary' variant='text' className='remove-capital'>View</Button>
                    <MoreVertIcon color="disabled" onClick={()=>openModal(props.data.id)}/>
                    &nbsp;
                    <div className={`option-modal ${option_modal2[props.data.id]&&'option-modal-active'}`}>
                       <MuiThemeProvider theme={themeButton}>
                            <Button component='label' onClick={null} size='small' variant='text' color='secondary' className='btn-remove-capital'>Change File
                            <input
                                    type="file"
                                    style={{ display: "none" }}
                                    onChange={(evt)=>onChangeUpdate(evt,props.data.id)}
                                />
                            </Button>
                            <Button onClick={()=>deleteFileOrFolder(props.data)} size='small' variant='text' color='primary' className='btn-remove-capital'>Delete File</Button>
                        
                        </MuiThemeProvider>
                    </div>
                </div>
                </>
            </div>
        )
    }

    const CardQuick=(props)=>{
        return(
            <div   onDoubleClick={()=>toSubFolder(props.data)} className={`card-access ${props.data.id===active_project&&'card-active'}`}>
                <div className='card-access-body'>
                    <div className='div-flex div-space-between' style={{position:'relative'}}>
                        <p  className='card-access-title' style={{margin:0,width:'85%'}} title={props.data.filename}>{props.data.filename.length>25?`${props.data.filename.substring(0,25)}...`:props.data.filename}</p>
                        <img style={{width:20,position:'absolute',right:0}} src={renderIcon(props.data.fileType)}/>
                        {/* <div style={{width:30,height:20,borderRadius:5,backgroundColor:'#ff9aa2'}}></div> */}
                    </div>
                    <div>
                    <p className='card-access-subtitle'>{props.data.tribe}</p>
                    <div className='div-flex div-space-between div-align-center'>
                    <p className='card-access-subtitle'>Upload at : {moment(props.data.last_update).format('MMMM YYYY')}</p>
                    {/* {props.data.type==='folder'&&<MoreVertIcon onClick={editFolder} color="disabled" style={{cursor:'pointer'}}/>} */}
                    </div>
                    </div>
                </div>
                <>
                <div style={{width:'100%',height:1,backgroundColor:'#ccc'}}></div>
                <div className='card-access-footer'>
                    {/* <Button onClick={null} size='small' style={{height:30,fontSize:12,margin:0}} color='primary' variant='text' className='remove-capital'>Download</Button> */}
                    <Button onClick={()=>viewFiles(props.data.fileId)} size='small' style={{height:30,fontSize:12,margin:0,width:'20px!important'}} color='primary' variant='text' className='remove-capital'>View</Button>
                    {/* <MoreVertIcon color="disabled"/> */}
                    &nbsp;
                </div>
                </>
            </div>
        )
    }
    const CardWorkProj=(props)=>{
        return(
            <div onClick={()=>handleClick(props.data)} onDoubleClick={()=>handleDoubleClick(props.data)} className={`card-access ${props.data.id===active_project&&'card-active'}`}>
                <div className='card-access-body2'>
                    <div className='div-flex div-space-between' style={{margin:'3px 0px 3px 0px'}}>
                        <p  className='card-access-title' style={{margin:0}} title={props.data.name}>{props.data.name.length>30?`${props.data.name.substring(0,30)}...`:props.data.name}</p>
                        <div style={{width:30,height:20,borderRadius:5,backgroundColor:props.data.workshopTypeId===0?'#ADBFF7':'#F2CD87'}}></div>
                    </div>
                    <div>
                    <div style={{margin:'3px 0px 3px 0px'}}><p className='card-access-subtitle' title={props.data.client}>{props.data.client.length>20?`${props.data.client.substring(0,20)}...`:props.data.client}</p></div>
                    <div className='div-flex div-space-between div-align-center'>
                    <p className='card-access-subtitle'>Project year : {props.data.year}</p>
                    {/* {props.data.type==='folder'&&<MoreVertIcon onClick={editFolder} color="disabled" style={{cursor:'pointer'}}/>} */}
                    </div>
                    </div>
                </div>
               
            </div>
        )
    }
    const renderLoading=()=>{
        return(
            <div>
                <div style={{marginTop:10}}>
                    <div style={{width:200,marginBottom:10}}><Skeleton height={30}/></div>
                    <div className='grid-access'>
                        <Skeleton height={100}/>
                        <Skeleton height={100}/>
                        <Skeleton height={100}/>
                        <Skeleton height={100}/>
                        <Skeleton height={100}/>
                        <Skeleton height={100}/>
                        <Skeleton height={100}/>
                        <Skeleton height={100}/>
                        
                        
                    </div>
                </div>
                <div style={{marginTop:10}}>
                    <div style={{width:200,marginBottom:10}}><Skeleton height={30}/></div>
                    <div className='grid-access'>
                        <Skeleton height={100}/>
                        <Skeleton height={100}/>
                        <Skeleton height={100}/>
                        <Skeleton height={100}/>
                        <Skeleton height={100}/>
                        <Skeleton height={100}/>
                        <Skeleton height={100}/>
                        <Skeleton height={100}/>
                        
                        
                    </div>
                </div>
            </div>
           
        )
    }
    console.log('folder_access', folder_access)
    return (
        <div  style={{position:'relative'}}>
            <div onClick={backdropToggle} className='backdrop' style={{display:backdrop?'block':'none'}}></div>
            {general.isLoadingTable2?renderLoading():

            <>
            {quick_access.length>0&&
            <div>
                <div className='div-flex div-space-between div-align-center access-header2'>
                    <p>Quick access</p>
                    <Button onClick={()=>showToggle(!showCard)} size='small' style={{height:30}} color='primary' variant='text' className='remove-capital'>{showCard?'Hide':'Show'}</Button>

                </div>
                {showCard&&
                <>
                <div className='grid-access'>
                    {quick_access.map((data,i)=>(
                        <CardQuick key={i} data={data}/>
                    ))}
                </div>
                <br/>
                <div style={{width:'100%',height:1.2,backgroundColor:'rgba(119, 119, 119, 0.5)',margin:'10px 0px 10px 0px'}}></div>
                <br/>
                </>
                }
            </div>
            }
            {folder_access.length>0&&
            <div>
                <div className='div-flex div-space-between div-align-center access-header2'>
                    <p>Folder</p>
                    <div className='div-flex'>
                        {/* <Button onClick={null} size='small' style={{height:30}} color='primary' variant='text' className='remove-capital'>Filter</Button>
                        &nbsp;&nbsp;
                        <Button onClick={null} size='small' style={{height:30}} color='primary' variant='text' className='remove-capital'>Short</Button> */}
                        
                    </div>

                </div>
                <div className='grid-access'>
                    {folder_access.map((data,i)=>(
                        <CardAccess key={i} data={data}/>
                    ))}
                </div>
                <br/>
                <div style={{width:'100%',height:1.2,backgroundColor:'rgba(119, 119, 119, 0.5)',margin:'10px 0px 10px 0px'}}></div>
                <br/>
            </div>
            }
            {work_proj.length>0&&
            <div>
                <div className='div-flex div-space-between div-align-center access-header2'>
                    <p>Projects/workshops</p>
                    <div className='div-flex'>
                        {/* <Button onClick={null} size='small' style={{height:30}} color='primary' variant='text' className='remove-capital'>Filter</Button>
                        &nbsp;&nbsp;
                        <Button onClick={null} size='small' style={{height:30}} color='primary' variant='text' className='remove-capital'>Short</Button> */}
                        
                    </div>

                </div>
                <div className='grid-access'>
                    {work_proj.map((data,i)=>(
                        <CardWorkProj key={i} data={data}/>
                    ))}
                </div>
                <br/>
                <div style={{width:'100%',height:1.2,backgroundColor:'rgba(119, 119, 119, 0.5)',margin:'10px 0px 10px 0px'}}></div>
                <br/>
            </div>
            }
            {file_access.length>0&&
            <div>
                <div className='div-flex div-space-between div-align-center access-header2'>
                    <p>Files</p>
                    <div className='div-flex'>
                        {/* <Button onClick={null} size='small' style={{height:30}} color='primary' variant='text' className='remove-capital'>Filter</Button>
                        &nbsp;&nbsp;
                        <Button onClick={null} size='small' style={{height:30}} color='primary' variant='text' className='remove-capital'>Short</Button> */}
                    </div>

                </div>
                <div className='grid-access'>
                    {file_access.map((data,i)=>(
                        <FileAccess key={i} data={data}/>
                    ))}
                </div>
                <br/>
            </div>
            }
            {bread_crum.length<1&&last_activity.length>0&&
            <>
             <div className='div-flex div-space-between div-align-center access-header2'>
                <p>Last activity</p>
                <Button onClick={()=>showToggle2(!showCard2)} size='small' style={{height:30}} color='primary' variant='text' className='remove-capital'>{showCard?'Hide':'Show'}</Button>

            </div>
            {showCard2&&
            <Table  aria-label="simple table">
                <TableHead>
                <TableRow>
                    <TableCell>Employe name</TableCell>
                    <TableCell align="left" style={{width:100}}>Activity</TableCell>
                    <TableCell align="left" style={{width:130}}>File name</TableCell>
                    <TableCell align="left">File type</TableCell>
                    <TableCell align="left">File owner</TableCell>
                    <TableCell align="left">Access date</TableCell>
                </TableRow>
                </TableHead>
                <TableBody>
                    {last_activity.map((data,i)=>(
                        <TableRow key={i}>
                            <TableCell align='left'>{data.name}</TableCell>
                            <TableCell align="left">{data.action}</TableCell>
                            <TableCell align="left" title={data.filename}>{data.filename.length>40?`${data.filename.substring(0,40)}...`:data.filename}</TableCell>
                            <TableCell align="left">{data.fileType}</TableCell>
                            <TableCell align="left">{data.client}</TableCell>
                            <TableCell align="left">{moment(data.lastAccess).format('D MMM YY, HH:mm')}</TableCell>
                            
                        </TableRow> 
                    ))}
                 
                </TableBody>
            </Table>
            }
            </>}
            <div style={{width:'100%',height:150}}></div>
            
            {option_modal&&<div  className={`properties`}>
                <Properties option_modal={option_modal} project_info={project_info} token={props.token}/>
            </div>}
            </>
            }
        </div>
    )
}
