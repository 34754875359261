import React from 'react'
import './style.css'
import {Button} from '@material-ui/core'
import { MuiThemeProvider, createMuiTheme,withStyles, } from '@material-ui/core/styles'
const themeButton = createMuiTheme({ 
    palette: { 
        primary: {
            main:'#65b7ff',
            contrastText: '#FFFFFF',

        },
        secondary: {
            main:'#ff7165',
            contrastText: '#FFFFFF',
        }
    } 
})
export default function Notification() {
    
    const ItemNotif=()=>{
        return(
            <div className='item-notif-wrapper'>
                <div className='div-flex div-space-between div-align-center'>
                    <p className='bold'>Pepy</p>
                    <p className='date-notif'>7 Jan 2021</p>
                </div>
                <p className='notif-message'>
                    has a request to download
                    
                </p>
                
                <span style={{width:20,overflowWrap:'break-word',margin:'20px 0px 10px 0px',cursor:'pointer'}}><b style={{color:'#65b7ff'}}>Filename.txt</b></span>
                <MuiThemeProvider theme={themeButton}>
                <div style={{display:'flex',justifyContent:'flex-end'}}>
                    <Button className='btn-remove-capital' variant='text' color='secondary'>Decline</Button>
                    <Button className='btn-remove-capital' variant='text' color='primary'>Approve</Button>
                </div>
                </MuiThemeProvider>
            </div>
        )
    }
    return (
        <div>
            <div className='notif-head'>
                <p>Notification</p>
            </div>
            <div className='dashed-border'></div>
            <div className='notif-body'>
                <ItemNotif/>
            </div>
        </div>
    )
}
