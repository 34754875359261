import React, { useState, useEffect } from "react";
import {
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  TextField,
  Select,
  MenuItem,
  FormControlLabel,
  RadioGroup,
  Radio,
  Box,
  Card,
  Typography,
  CardContent,
} from "@material-ui/core";
import SearchImg from "assets/icon/Search.png";
import Eye from "assets/icon/eye.svg";
import filter from "assets/icon/filter.svg";

import Edit from "assets/icon/edit.svg";
import Close from "assets/icon/close.svg";
import CevronRight from "assets/icon/chevron-right.svg";
import CevronLeft from "assets/icon/chevron-left.svg";
import Skeleton from "components/Skeleton";
import { TablePagination } from "@trendmicro/react-paginations";
import "@trendmicro/react-paginations/dist/react-paginations.css";
import { debounce } from "lodash";
import Close2 from "assets/icon/close2.svg";
import {
  getInsight,
  setInsightAction,
  getDetailInsight,
  setInsight,
  resetPayload,
} from "redux/actions/insight";
import { getAuthor, getTopic } from "redux/actions/master";
import { modalToggle } from "redux/actions/general";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import AutoCompleteSelect from "components/Select";
import {
  MuiThemeProvider,
  createMuiTheme,
  withStyles,
  makeStyles,
} from "@material-ui/core/styles";
const useStyles = makeStyles((theme) => ({
  textField: {
    [`& fieldset`]: {
      borderRadius: 10,
    },
    width: "100%",
    marginBottom: 15,
  },
  textField2: {
    [`& fieldset`]: {
      borderRadius: 10,
    },
    width: "100%",
    //   marginBottom:15
  },
}));
const themeButton = createMuiTheme({
  palette: {
    primary: {
      main: "#a8e090",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#ff6e79",
      contrastText: "#FFFFFF",
    },
  },
});
const themeButton2 = createMuiTheme({
  palette: {
    primary: {
      main: "#FFC466",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#ff6e79",
      contrastText: "#FFFFFF",
    },
  },
});
export default function Index(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const general = useSelector((state) => state.general);
  const insight = useSelector((state) => state.insight);

  const master = useSelector((state) => state.master);
  const [tab_list, setTabList] = useState("drafted");
  const [filter, setFilter] = useState({
    author: [{ id: 0, text: "All Author" }],
    category: 0,
  });
  const [sort, setSort] = useState(0);
  const [search, setSearch] = useState("");
  const [publish, setPublish] = useState(0);
  const [filter_modal, setFilterModal] = useState(false);
  const [sort_modal, setSortModal] = useState(false);
  const [backdrop, setBackdrop] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    perPage: 10,
  });

  let new_author = [];
  filter.author.map((dat) => {
    new_author.push(dat.id);
  });
  let slug = `/${filter.author.length > 0 ? new_author.join(",") : 0}/${
    filter.category
  }/${insight.insight_payload.publish}/${sort}/${pagination.page}/${
    pagination.perPage
  }/${search === "" ? "*" : search}`;
  useEffect(() => {
    dispatch(getInsight(slug));
    if (master.employee.length < 1) {
      dispatch(getAuthor());
    }
    if (master.topic.length < 1) {
      dispatch(getTopic());
    }
  }, [insight.insight_payload.publish]);
  const tabListToggle = (key) => {
    dispatch(setInsight({ publish: key === "drafted" ? 0 : 1 }));
    // setPublish(key==='drafted'?0:1)
    setFilter({
      author: [{ id: 0, text: "All Author" }],
      category: 0,
    });
    setSort(0);
    setSearch("");
    setTabList(key);
  };
  const addInsight = () => {
    dispatch(resetPayload());
    dispatch(setInsightAction("add_insight"));
    props.tabToggle("add_insight");
  };
  const togglePagination = (page, pageLength) => {
    let slug = `/${new_author.join(",")}/${filter.category}/${
      insight.insight_payload.publish
    }/${sort}/${page}/${pageLength}/${search === "" ? "*" : search}`;
    dispatch(getInsight(slug));
  };
  const searchToggle = debounce(async (value) => {
    setSearch(value);
    let slug = `/${new_author.join(",")}/${filter.category}/${
      insight.insight_payload.publish
    }/${sort}/${pagination.page}/${pagination.perPage}/${
      value === "" ? "*" : value
    }`;
    dispatch(getInsight(slug));
  }, 1000);
  const onClickBackdrop = () => {
    setFilterModal(false);
    setSortModal(false);
  };
  const applyFilter = () => {
    setFilterModal(false);
    dispatch(getInsight(slug));
  };
  const applySort = () => {
    setSortModal(false);
    dispatch(getInsight(slug));
  };
  const detailInsight = async (id) => {
    let res = await dispatch(getDetailInsight(`/${id}`));
    if (res) {
      dispatch(setInsightAction("detail_insight"));
      props.tabToggle("add_insight");
    }
  };
  const seeInsight = async (id) => {
    let res = await dispatch(getDetailInsight(`/${id}`));
    if (res) {
      dispatch(setInsightAction("see_insight"));
      props.tabToggle("add_insight");
    }
  };
  const deleteInsight = (data) => {
    dispatch(
      modalToggle({
        modal_open: true,
        modal_title: `list`,
        modal_component: "confirm_delete",
        modal_size: 400,
        modal_type: "confirm",
        modal_data: {
          title: "Insight",
          id: data.id,
          get_slug: slug,
          userId: props.profile.id,
          msg: `<p>Are you sure delete <b>${data.title}</b></p>`,
        },
        modal_action: "delete_insight",
      })
    );
  };
  const unPublish = (data) => {
    dispatch(
      modalToggle({
        modal_open: true,
        modal_title: `Unpublish Insight`,
        modal_component: "confirm2",
        modal_size: 400,
        modal_type: "confirm",
        modal_data: {
          title: "Insight",
          id: data.id,
          userId: props.profile.id,
          title_cancel: "No,cancel",
          title_yes: "Yes",
          msg: `<p>Are you sure unpublish <b>${data.title}</b></p>`,
        },
        modal_action: "unpublish_insight",
      })
    );
  };
  console.log(insight);
  const commonStyles = {
    bgcolor: "background.paper",
    m: 1,
    borderColor: "text.primary",
    width: "5rem",
    height: "5rem",
  };

  return (
    <div>
      <div className="head-section">
        <div>
          <h4>Insight list</h4>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <MuiThemeProvider theme={themeButton}>
            <Button
              onClick={() => addInsight()}
              size="small"
              color="primary"
              variant="contained"
              className="head-add-section__btn remove-boxshadow"
            >
              Add new insight
            </Button>
          </MuiThemeProvider>
        </div>
      </div>
      <div className="card-content" style={{ position: "relative" }}>
        {filter_modal && (
          <div onClick={onClickBackdrop} className="backdrop"></div>
        )}
        {sort_modal && (
          <div onClick={onClickBackdrop} className="backdrop"></div>
        )}
        <div style={{ padding: 20, display: "flex", gap: 20 }}>
          <Card
            style={{
              width: "20%",
              backgroundColor: "rgb(255, 196, 102)",
              color: "white",
              paddingBottom: 0,
            }}
          >
            <CardContent style={{ paddingBottom: 8}}>
              <Typography>Total insight GML</Typography>
              <Typography style={{ fontSize: 24, fontWeight: "bold" }}>
                {insight.total_data?.totalAllInsightsPublishedGML}
              </Typography>
            </CardContent>
          </Card>
          <Card
            style={{
              width: "20%",
              backgroundColor: "#a8e090",
              color: "white",
              padding: 0,
            }}
          >
            <CardContent style={{ paddingBottom: 8}}>
            <Typography>Total insight CDHX</Typography>
              <Typography style={{ fontSize: 24, fontWeight: "bold" }}>
                {insight.total_data?.totalAllInsightsPublishedCDHX}
              </Typography>
            </CardContent>
          </Card>
          <Card
            style={{
              width: "20%",
              backgroundColor: "#65b7ff",
              color: "white",
              padding: 0,
            }}
          >
            <CardContent style={{ paddingBottom: 8}}>
            <Typography>Total all insight</Typography>
              <Typography style={{ fontSize: 24, fontWeight: "bold" }}>
                {insight.total_data?.totalAllInsightsPublished}
              </Typography>
            </CardContent>
          </Card>
        </div>
        <div className="head-section" style={{ padding: 20, paddingTop: 0 }}>
          <div className="head-tab">
            <li
              onClick={() => tabListToggle("drafted")}
              className={
                insight.insight_payload.publish == 0 ? "head-tab-active" : ""
              }
            >
              Drafted
            </li>
            <li
              onClick={() => tabListToggle("published")}
              className={
                insight.insight_payload.publish == 1 ? "head-tab-active" : ""
              }
            >
              Published
            </li>
          </div>
        </div>
        <div className="card-table" style={{ padding: "0px 20px 20px 20px" }}>
          <div className="card-table__head">
            <FormControl variant="outlined">
              <InputLabel htmlFor="input-with-icon-textfield">
                Search
              </InputLabel>
              <OutlinedInput
                size="small"
                style={{ height: 30, width: 200 }}
                // value={search}
                id="input-with-icon-textfield"
                name="password"
                onChange={(e) => searchToggle(e.target.value)}
                // onKeyPress={e =>
                //     this.handleKeyPress(e)
                // }
                required
                startAdornment={
                  <InputAdornment position="start">
                    <img alt="search" src={SearchImg} style={{ width: 15 }} />
                  </InputAdornment>
                }
                labelWidth={50}
              />
            </FormControl>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                position: "relative",
              }}
            >
              <button
                onClick={() => setFilterModal(!filter_modal)}
                className="card-table__head_btn"
              >
                Filter
              </button>
              <button
                onClick={() => setSortModal(!sort_modal)}
                className="card-table__head_btn"
              >
                Short
              </button>
              {filter_modal && (
                <div className="filter-modal">
                  <p className="semi-bold">Filter</p>
                  <AutoCompleteSelect
                    onChange={(event, value) =>
                      setFilter({ ...filter, author: value })
                    }
                    options={[
                      { id: 0, text: "All Author" },
                      ...master.employee,
                    ]}
                    value={filter.author}
                    getOptionLabel={(option) => option.text}
                    label={<>Author</>}
                    multiple
                  />
                  <FormControl
                    className={classes.textField}
                    variant="outlined"
                    size="small"
                    style={{ width: "100%", marginBottom: 10 }}
                  >
                    <InputLabel htmlFor="category">Category</InputLabel>
                    <Select
                      value={filter.category}
                      onChange={(e) =>
                        setFilter({ ...filter, category: e.target.value })
                      }
                      color="primary"
                      name="segmentId"
                      labelId="label"
                      id="select"
                      labelWidth={70}
                      style={{ borderRadius: 5 }}
                    >
                      <MenuItem value={0}>All Category</MenuItem>

                      {master.topic.map((data, i) => (
                        <MenuItem key={i} value={data.id}>
                          {data.text}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                  <div
                    className="div-flex"
                    style={{ justifyContent: "flex-end" }}
                  >
                    <MuiThemeProvider theme={themeButton2}>
                      <Button
                        onClick={() => applyFilter()}
                        size="small"
                        color="primary"
                        variant="contained"
                        className="head-add-section__btn remove-boxshadow"
                      >
                        Apply
                      </Button>
                    </MuiThemeProvider>
                  </div>
                </div>
              )}
              {sort_modal && (
                <div className="sort-modal">
                  <p className="semi-bold">Sort</p>
                  <RadioGroup
                    value={sort.toString()}
                    onChange={(e) => setSort(parseInt(e.target.value))}
                    column
                    aria-label="position"
                    name="position"
                    defaultValue="1"
                  >
                    <FormControlLabel
                      value="0"
                      control={<Radio color="primary" />}
                      label={<p>Newest to Oldest</p>}
                      labelPlacement="right"
                      style={{ height: 30 }}
                    />
                    <FormControlLabel
                      value="1"
                      control={<Radio color="primary" />}
                      label={<p>Oldest to Newest</p>}
                      labelPlacement="right"
                      style={{ height: 30 }}
                    />
                  </RadioGroup>
                  <br />
                  <div
                    className="div-flex"
                    style={{ justifyContent: "flex-end" }}
                  >
                    <MuiThemeProvider theme={themeButton2}>
                      <Button
                        onClick={() => applySort()}
                        size="small"
                        color="primary"
                        variant="contained"
                        className="head-add-section__btn remove-boxshadow"
                      >
                        Apply
                      </Button>
                    </MuiThemeProvider>
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="card-table__content">
            <Table size="small" aria-label="a dense table">
              <TableHead>
                <TableRow>
                  <TableCell align="left">Title</TableCell>
                  <TableCell align="left">Website</TableCell>
                  <TableCell align="left">Category</TableCell>
                  <TableCell align="left">Author</TableCell>
                  <TableCell align="left">
                    {publish == 0 ? "Last update" : "Publish Date"}
                  </TableCell>
                  <TableCell align="center">Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {general.isLoadingTable3 ? (
                  <Skeleton count={5} />
                ) : (
                  insight.list_insight.map((data, i) => {
                    let new_author = [];
                    let new_category = [];
                    data.authors.map((author) => {
                      new_author.push(author.text);
                    });
                    data.categories.map((category) => {
                      new_category.push(category.text);
                    });
                    return (
                      <TableRow key={i}>
                        <TableCell style={{ maxWidth: 200 }}>
                          {data.title}
                        </TableCell>
                        <TableCell>{data.website}</TableCell>
                        <TableCell>{new_category.join(",")}</TableCell>
                        <TableCell style={{ maxWidth: 100 }}>
                          {new_author.join(",")}
                        </TableCell>
                        <TableCell>
                          {moment(data.lastUpdate).format("YYYY-MM-DD HH:mm")}
                        </TableCell>
                        <TableCell style={{ width: 100 }} align="right">
                          <div className="div-flex">
                            {insight.insight_payload.publish == 1 && (
                              <p
                                onClick={() => unPublish(data)}
                                style={{
                                  cursor: "pointer",
                                  fontSize: 14,
                                  margin: "5px 2px 5px 15px",
                                  fontWeight: "bold",
                                  color: "#ffc466",
                                }}
                              >
                                Unpublish
                              </p>
                            )}
                            <img
                              src={Eye}
                              onClick={() => seeInsight(data.id)}
                              className="icon-action"
                            />
                            <img
                              src={Edit}
                              onClick={() => detailInsight(data.id)}
                              className="icon-action"
                            />
                            {insight.insight_payload.publish == 0 && (
                              <img
                                src={Close}
                                onClick={() => deleteInsight(data)}
                                className="icon-action"
                              />
                            )}
                          </div>
                        </TableCell>
                      </TableRow>
                    );
                  })
                )}
              </TableBody>
            </Table>
            <div className="card-table__pagination">
              {insight.pagination !== null && (
                <TablePagination
                  className="card-pagination"
                  type="reduced"
                  page={insight.pagination.page}
                  pageLength={insight.pagination.perPage}
                  totalRecords={insight.pagination.total}
                  onPageChange={({ page, pageLength }) => {
                    togglePagination(page, pageLength);
                  }}
                  prevPageRenderer={() => (
                    <img src={CevronLeft} style={{ width: 10 }} />
                  )}
                  nextPageRenderer={() => <img src={CevronRight} />}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
