import React,{useEffect} from 'react'
import './style.css'
import Skeleton from 'react-loading-skeleton'
import {useDispatch,useSelector} from 'react-redux'
import {getProjectInfo} from 'redux/actions/files'
export default function Properties(props) {
    const files=useSelector(state=>state.files)
    let {project_info,project_id}=files
    const dispatch=useDispatch()
    useEffect(()=>{
        if(project_id!==null){
            dispatch(getProjectInfo(props.token,`/${project_id}/${0}`))
        }
        
    },[project_id])
    const general=useSelector(state=>state.general)
    const renderLoading=()=>(
        <div className='grid-properties'>
            <div>
                <p className='subtitle-properties'>Project information</p>
                <table cellspacing="0" cellpadding="0">
                    <tr>
                        <td>
                        <p className='content-properties'>Project name</p>
                        </td>
                        <td>
                            <Skeleton height={20} width={100}/>
                            {/* <p className='content-properties'> : {project_info.project.name}</p> */}
                        </td>
                    </tr>
                    <tr>
                        <td>
                        <p className='content-properties'>Tribe</p>
                        </td>
                        <td>
                        <Skeleton height={20} width={100}/>
                        </td>
                    </tr>
                    <tr>
                        <td>
                        <p className='content-properties'>Status</p>
                        </td>
                        <td>
                        <Skeleton height={20} width={100}/>
                        </td>
                    </tr>
                </table>
            </div>
            <div>
                <p className='subtitle-properties'>Project team member</p>
                <table cellspacing="0" cellpadding="0">
                    <tr>
                        <td>
                        <p className='content-properties'>Project advisor</p>
                        </td>
                        <td>
                        <Skeleton height={20} width={100}/>
                        </td>
                    </tr>
                    <tr>
                        <td>
                        <p className='content-properties'>Project leader</p>
                        </td>
                        <td>
                        <Skeleton height={20} width={100}/>
                        </td>
                    </tr>
                    <tr>
                        <td>
                        <p className='content-properties'>Project member</p>
                        </td>
                        <td>
                        <Skeleton height={20} width={100}/>
                        </td>
                    </tr>
                </table>
            </div>
            <div>
                <p className='subtitle-properties'>Project team member</p>
                <table cellspacing="0" cellpadding="0">
                    <tr>
                        <td>
                        <p className='content-properties'>Client Company name</p>
                        </td>
                        <td>
                        <Skeleton height={20} width={100}/>
                        </td>
                    </tr>
                    <tr>
                        <td>
                        <p className='content-properties'>Client Project Owner</p>
                        </td>
                        <td>
                        <Skeleton height={20} width={100}/>
                        </td>
                    </tr>
                    <tr>
                        <td>
                        <p className='content-properties'>Client Project Leader</p>
                        </td>
                        <td>
                        <Skeleton height={20} width={100}/>
                        </td>
                    </tr>
                    <tr>
                        <td>
                        <p className='content-properties'>Client Project Member</p>
                        </td>
                        <td>
                        <Skeleton height={20} width={100}/>
                        </td>
                    </tr>
                </table>
            </div>
            
        </div>
    )
    return (
        <div>
            <div className='title-properties'>
                    <p>Properties</p>
                </div>
                {general.isLoadingTable3||project_info===null?renderLoading():
                <div className='grid-properties'>
                    <div>
                        <p className='subtitle-properties'>Project information</p>
                        <table cellspacing="0" cellpadding="0">
                            <tr>
                                <td>
                                    <p className='content-properties'>Project name</p>
                                </td>
                                <td>
                                    <p className='content-properties'>: {project_info.project.name}</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p className='content-properties'>Tribe</p>
                                </td>
                                <td>
                                    <p className='content-properties'>: {project_info.tribe.text}</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p className='content-properties'>Status</p>
                                </td>
                                <td>
                                    <p className='content-properties'>: {project_info.project.status}</p>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div>
                        <p className='subtitle-properties'>Project team member</p>
                        <table cellspacing="0" cellpadding="0">
                            <tr>
                                <td>
                                    <p className='content-properties'>Project advisor</p>
                                </td>
                                <td>
                                    <p className='content-properties'>: {project_info.projectAdvisor!==null&&project_info.projectAdvisor.text}</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p className='content-properties'>Project leader</p>
                                </td>
                                <td>
                                    <p className='content-properties'>: {project_info.projectLeader!==null&&project_info.projectLeader.text}</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p className='content-properties'>Project member</p>
                                </td>
                                <td>
                                    <p className='content-properties'>: {project_info.projectMembers.map((data)=>(`${data.text},`))}</p>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div>
                        <p className='subtitle-properties'>Project team member</p>
                        <table cellspacing="0" cellpadding="0">
                            <tr>
                                <td>
                                    <p className='content-properties'>Client Company name</p>
                                </td>
                                <td>
                                    <p className='content-properties'>: {project_info.client.text}</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p className='content-properties'>Client Project Owner</p>
                                </td>
                                <td>
                                    <p className='content-properties'>: {project_info.clientProjectOwner!==null&&project_info.clientProjectOwner.text}</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p className='content-properties'>Client Project Leader</p>
                                </td>
                                <td>
                                    <p className='content-properties'>: {project_info.clientProjectLeader!==null&&project_info.clientProjectLeader.text}</p>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <p className='content-properties'>Client Project Member</p>
                                </td>
                                <td>
                                    <p className='content-properties'>: {project_info.clientProjectMembers.map((data)=>(`${data.text},`))}</p>
                                </td>
                            </tr>
                        </table>
                    </div>
                    
                </div> }
        </div>
    )
}
