import React, { Component } from 'react'
import Checklist from 'assets/icon/checklist.png'
import SuccessUpload from 'assets/image/success_upload.png'
import close from 'assets/icon/close.svg'
import {connect} from 'react-redux'
import {modalToggleReset,modalToggle} from 'redux/actions/general'
// import {setAction} from 'redux/actions/event'
import { MuiThemeProvider, createMuiTheme,withStyles, } from '@material-ui/core/styles'
import {Button } from '@material-ui/core'
import {getProject,setBreadCrum,setFilesAction,getTree,setTribeId} from 'redux/actions/files'
const themeButton = createMuiTheme({ 
    palette: { 
        primary: {
            main:'#00ACC1',
        },
        secondary: {
            main:'#ffc466',
            contrastText: '#FFFFFF',
        }
    } 
})
class alert extends Component {
    
    renderFooter=()=>{
        const {modal_component,modal_data,success_msg}=this.props.general
        const getProjects=async()=>{
            await this.props.getTree(this.props.token)
            let res=await this.props.getProject(this.props.token,`/${modal_data.projectId}/${modal_data.parentId}`)
            if(res){
                let new_bread=[]
                res.breadcrump.map((data)=>{
                    new_bread.push({...data,slug:`${data.id}-${data.text}`})
                })
                this.props.setTribeId(res.tribe.id)

                // let filter=res.breadcrump.filter((data)=>{
                //     return data.id===modal_data.breadcrump
                // })
                // if(filter.length>0){
                //     new_bread.splice(new_bread.length-1,0,{...modal_data.breadcrump,slug:`${modal_data.breadcrump.id}-${modal_data.breadcrump.text}`})
                // }else{
                //     new_bread.push({id:res.project.id,text:res.project.name,slug:`${res.project.id}-${res.project.name}`})

                // }
                // new_bread.push({id:res.project.id,text:res.project.name,slug:`${res.project.id}-${res.project.name}`})
                
                this.props.setBreadCrum(new_bread)
                this.props.modalToggleReset()
                this.props.setFilesAction('files','')

            }
        }
        switch (modal_component) {
            case 'add_client':
                return(
                    <Button onClick={()=>this.props.modalToggleReset()}   size='small' color='secondary' variant='contained' className='head-add-section__btn '>See detail client</Button>
                ) 
            case 'delete_client':
                return(
                    <Button onClick={()=>this.props.modalToggleReset()}   size='small' color='secondary' variant='contained' className='head-add-section__btn '>See detail client</Button>
                )      
            case 'upload_client':
                return(
                    <Button onClick={()=>this.props.modalToggleReset()}   size='small' color='secondary' variant='contained' className='head-add-section__btn '>See client list</Button>

                )
            case 'change_password':
                return(
                    <Button onClick={()=>this.props.modalToggleReset()}   size='small' color='secondary' variant='contained' className='head-add-section__btn '>Close</Button>

                )
            case 'add_folder':
                return(
                    <Button onClick={()=>this.props.modalToggleReset()}   size='small' color='secondary' variant='contained' className='head-add-section__btn '>Close</Button>

                )
            case 'add_insight':
                return(
                    <Button onClick={()=>this.props.modalToggleReset()}   size='small' color='secondary' variant='contained' className='head-add-section__btn '>Close</Button>

                )
            case 'upload_file':
                return(
                    <Button onClick={()=>getProjects()}   size='small' color='secondary' variant='contained' className='head-add-section__btn '>Go to project</Button>

                )
            
            default:
                break;
        }
    }
    render() {
        const {modal_data,modal_title,modal_action}=this.props.general
        return (
            <div className='alert-container'>
                <img src={modal_action==='error'?close:modal_action==='success_upload'?SuccessUpload:Checklist} style={{width:'40%'}}/>
                <br/>
                <h4>{modal_title}</h4>
                {/* <p>{modal_data.msg}</p> */}
                <div dangerouslySetInnerHTML={{ __html: modal_data.msg }}></div>
                <MuiThemeProvider theme={themeButton}>
                    {this.renderFooter()}
                </MuiThemeProvider>
            </div>
        )
    }
}
const mapStateToProps=(state)=>({
    general:state.general
})
const mapDispatchToProps={
    modalToggleReset,
    modalToggle,
    getProject,
    setBreadCrum,
    setFilesAction,
    getTree,
    setTribeId
    // setAction
}
export default connect(mapStateToProps,mapDispatchToProps)(alert)