import React from "react";
import {
  Button,
  FormControl,
  InputLabel,
  OutlinedInput,
  InputAdornment,
  TextField,
  Select,
  MenuItem,
  FormControlLabel,
} from "@material-ui/core";
import { debounce } from "lodash";
import {
  MuiThemeProvider,
  createMuiTheme,
  withStyles,
  makeStyles,
} from "@material-ui/core/styles";
import { URL as newUrl } from "service/base_url";
import Cookie from "universal-cookie";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "ckeditor5-build-classic-with-upload-image";
import image_line from "assets/icon/image_line.svg";
import ChipInput from "material-ui-chip-input";
import { useSelector, useDispatch } from "react-redux";
import {
  setInsight,
  postInsight,
  setInsightAction,
  putInsight,
} from "redux/actions/insight";
import { handleFile, getBase64 } from "components/handleFile";
import AutoCompleteSelect from "components/Select";
import { modalToggle } from "redux/actions/general";
const cookie = new Cookie();
const token = cookie.get("login_cookie");
const profile = cookie.get("profile_cookie");
const themeButton = createMuiTheme({
  palette: {
    primary: {
      main: "#a8e090",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#ffc466",
      contrastText: "#FFFFFF",
    },
  },
});
const themeButton2 = createMuiTheme({
  palette: {
    primary: {
      main: "#65b7ff",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#ff7165",
      contrastText: "#FFFFFF",
    },
  },
});
const useStyles = makeStyles((theme) => ({
  textField: {
    [`& fieldset`]: {
      borderRadius: 10,
    },
    width: "100%",
    marginBottom: 15,
  },
  textField2: {
    [`& fieldset`]: {
      borderRadius: 10,
    },
    width: "100%",
  },
}));
// ClassicEditor.defaultConfig = {
//   toolbar: {
//     items: [
//       "heading",
//       "|",
//       "bold",
//       "italic",
//       "alignment",
//       "link",
//       "bulletedList",
//       "numberedList",
//       "|",
//       "indent",
//       "outdent",
//       "|",
//       "imageUpload",
//       "blockQuote",
//       "insertTable",
//       "undo",
//       "redo",
//     ],
//   },
//   alignment: {
//     options: ["left", "right", "center"],
//   },
//   image: {
//     toolbar: [
//       "imageStyle:full",
//       "imageStyle:side",
//       "imageStyle:alignLeft",
//       "imageStyle:alignCenter",
//       "imageStyle:alignRight",
//       "|",
//       "imageTextAlternative",
//     ],
//     styles: ["full", "side", "alignLeft", "alignCenter", "alignRight"],
//   },
//   table: {
//     contentToolbar: ["tableColumn", "tableRow", "mergeTableCells"],
//   },
//   language: "en",
//   simpleUpload: {
//     uploadUrl: `${newUrl}/upload/ckeditor`,

//     headers: {
//       "X-CSRF-TOKEN": "CSFR-Token",
//       Authorization: `Bearer ${token}`,
//     },
//   },
// };

export default function Index(props) {
  const classes = useStyles();
  const insight = useSelector((state) => state.insight);
  const master = useSelector((state) => state.master);
  const dispatch = useDispatch();
  const { insight_payload, insight_action } = insight;
  const deleteKeyword = (chip, index) => {
    let filter = insight_payload.keyword.filter((data) => {
      return data !== chip;
    });
    dispatch(setInsight({ keyword: [...filter] }));
  };
  const handleThumbnail = (evt) => {
    let handle = handleFile(evt);
    if (handle.file_error === null) {
      let file_url = URL.createObjectURL(handle.file);
      getBase64(handle.file, (result) => {
        dispatch(setInsight({ thumbnail_file: result }));
        dispatch(setInsight({ thumbnail_url: file_url }));
        dispatch(setInsight({ thumbnail_name: handle.file_name }));
      });
    } else {
      console.log("err", handle);
    }
  };

  const onClickSave = async () => {
    let new_author = [];
    insight_payload.author.map((data) => {
      new_author.push(data.id);
    });
    let data = {
      id: insight_payload.id,
      userId: profile.id,
      title: insight_payload.title,
      content: insight_payload.content,
      authorIds: new_author,
      categoryIds: [insight_payload.category.id],
      filename: insight_payload.thumbnail_name,
      fileBase64: insight_payload.thumbnail_file,
      metaTitle: insight_payload.meta_title,
      metaDescription: insight_payload.meta_desc,
      slug: insight_payload.slug,
      keyWords: insight_payload.keyword,
      publish: 0,
      website: insight_payload.website.value,
    };
    console.log("data_save", data);

    if (insight_action === "see_insight") {
      dispatch(setInsightAction("detail_insight"));
    }
    if (insight_action === "add_insight") {
      let res = await dispatch(postInsight(data));
      if (res) {
        dispatch(setInsight({ id: res.data.id }));

        dispatch(setInsightAction("see_insight"));
      }
    }
    console.log("insight_action", insight_action);
    if (insight_action === "detail_insight") {
      dispatch(putInsight(data));
    }
  };
  const deleteFile = () => {
    dispatch(setInsight({ thumbnail_name: "" }));
  };
  const onChangeSlug = (slug) => {
    let result = slug.replace(/\s/g, "-").toLowerCase();
    dispatch(setInsight({ slug: result }));
  };
  const publishInsight = (pub) => {
    let new_author = [];
    insight_payload.author.map((data) => {
      new_author.push(data.id);
    });
    let data = {
      id: insight_payload.id,
      userId: profile.id,
      title: insight_payload.title,
      content: insight_payload.content,
      authorIds: new_author,
      categoryIds: [insight_payload.category.id],
      filename: insight_payload.thumbnail_name,
      fileBase64: insight_payload.thumbnail_file,
      metaTitle: insight_payload.meta_title,
      metaDescription: insight_payload.meta_desc,
      slug: insight_payload.slug,
      keyWords: insight_payload.keyword,
      publish: 1,
      website: insight_payload.website.value,
    };
    console.log("data_publish", data);
    if (insight_payload.publish == 0) {
      dispatch(
        modalToggle({
          modal_open: true,
          modal_title: `Publish Insight`,
          modal_component: "confirm2",
          modal_size: 400,
          modal_type: "confirm",
          modal_data: {
            title: "Insight",
            data_update: data,
            id: pub.id,
            userId: props.profile.id,
            title_cancel: "No,cancel",
            title_yes: "Yes",
            msg: `<p>Are you sure publish <b>${pub.title}</b></p>`,
          },
          modal_action: "publish_insight",
        })
      );
    } else {
      dispatch(
        modalToggle({
          modal_open: true,
          modal_title: `Unpublish Insight`,
          modal_component: "confirm2",
          modal_size: 400,
          modal_type: "confirm",
          modal_data: {
            title: "Insight",
            id: pub.id,
            userId: props.profile.id,
            title_cancel: "No,cancel",
            title_yes: "Yes",
            msg: `<p>Are you sure unpublish <b>${pub.title}</b></p>`,
          },
          modal_action: "unpublish_insight",
        })
      );
    }
  };
  const handleFieldDisable = () => {
    if (
      insight.insight_action === "see_insight" ||
      insight.insight_payload.publish == 1
    ) {
      return true;
    } else {
      return false;
    }
  };
  const handleBtnDisableSave = () => {
    if (
      insight_payload.title === "" ||
      insight_payload.content === "" ||
      insight_payload.author.length < 1 ||
      insight_payload.category === null ||
      insight_payload.website === null ||
      insight_payload.thumbnail_name === "" ||
      insight_payload.meta_title === "" ||
      insight_payload.slug === "" ||
      insight_payload.meta_desc === "" ||
      insight_payload.keyword === ""
    ) {
      return true;
    }
    if (insight_payload.publish == 1) {
      return true;
    }
  };
  const handleBtnDisablePublish = () => {
    if (
      insight_payload.title === "" ||
      insight_payload.content === "" ||
      insight_payload.author.length < 1 ||
      insight_payload.category === null ||
      insight_payload.website === null ||
      insight_payload.thumbnail_name === "" ||
      insight_payload.meta_title === "" ||
      insight_payload.slug === "" ||
      insight_payload.meta_desc === "" ||
      insight_payload.keyword === ""
    ) {
      return true;
    }
  };
  const renderTitle = () => {
    switch (insight_action) {
      case "see_insight":
        return "Detail Insight";
        break;
      case "detail_insight":
        return "Edit Insight";
      case "add_insight":
        return "Add new insight";
      default:
        break;
    }
  };
  const deleteInsight = (data) => {
    dispatch(
      modalToggle({
        modal_open: true,
        modal_title: `list`,
        modal_component: "confirm_delete",
        modal_size: 400,
        modal_type: "confirm",
        modal_data: {
          title: "Insight",
          id: insight_payload.id,
          userId: props.profile.id,
          msg: `<p>Are you sure delete <b>${insight_payload.title}</b></p>`,
        },
        modal_action: "delete_insight",
      })
    );
  };
  return (
    <div>
      <div className="head-section">
        <div>
          <h4>{renderTitle()}</h4>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <MuiThemeProvider theme={themeButton}>
            <Button
              onClick={() => props.tabToggle("list_insight")}
              size="small"
              color="secondary"
              variant="outlined"
              className="head-add-section__btn remove-boxshadow"
            >
              Back
            </Button>
            <Button
              onClick={() => props.tabToggle("card_preview")}
              size="small"
              color="secondary"
              variant="outlined"
              className="head-add-section__btn remove-boxshadow"
            >
              Preview
            </Button>
            <Button
              disabled={handleBtnDisableSave()}
              onClick={() => onClickSave()}
              size="small"
              color="secondary"
              variant="contained"
              className="head-add-section__btn remove-boxshadow"
            >
              {insight_action === "see_insight" ? "Edit" : "Save"}
            </Button>
            <Button
              disabled={handleBtnDisablePublish()}
              onClick={() => publishInsight(insight_payload)}
              size="small"
              color="secondary"
              variant="contained"
              className="head-add-section__btn remove-boxshadow"
            >
              {insight_payload.publish == 0 ? "Publish" : "Unpublish"}
            </Button>
          </MuiThemeProvider>
        </div>
      </div>
      <MuiThemeProvider theme={themeButton}>
        <div style={{ width: "78%" }}>
          <TextField
            label="Title"
            type="text"
            value={insight_payload.title}
            onChange={(e) => dispatch(setInsight({ title: e.target.value }))}
            variant="outlined"
            size="small"
            multiline
            className={classes.textField}
            disabled={handleFieldDisable()}
          />
        </div>
        <div className="div-flex">
          <div style={{ width: "78%" }}>
            <div className="card-content" style={{ padding: 20 }}>
              <p className="semi-bold">Content</p>
              <CKEditor
                data={insight_payload.content}
                editor={ClassicEditor}
                onChange={(event, editor) => {
                  const data = editor.getData();
                  dispatch(setInsight({ content: data }));
                }}
                disabled={handleFieldDisable()}
              />
            </div>
          </div>
          <div style={{ width: "20%", paddingLeft: 15 }}>
            <AutoCompleteSelect
              onChange={(event, value) =>
                dispatch(setInsight({ author: value }))
              }
              options={master.employee}
              value={insight_payload.author}
              getOptionLabel={(option) => option.text}
              label={<>Author</>}
              multiple
              disabled={handleFieldDisable()}
            />
            <AutoCompleteSelect
              onChange={(event, value) =>
                dispatch(setInsight({ category: value }))
              }
              options={master.topic}
              value={insight_payload.category}
              getOptionLabel={(option) => option.text}
              label={<>Category</>}
              // multiple
              disabled={handleFieldDisable()}
            />
            <AutoCompleteSelect
              onChange={(event, value) => {
                dispatch(setInsight({ website: value }));
              }}
              options={[
                { label: "GML", value: "gml" },
                { label: "CDHX", value: "cdhx" },
                { label: "Both", value: "both" },
              ]}
              value={insight_payload.website}
              getOptionLabel={(option) => option.label}
              label={<>Website</>}
              disabled={handleFieldDisable()}
            />

            <br />
            <br />
            <p className="semi-bold">Thumbnail image</p>
            <div className="div-flex div-align-center">
              <img src={image_line} />
              &nbsp;
              <p
                style={{
                  fontSize: 14,
                  color:
                    insight_payload.thumbnail_name !== ""
                      ? "#65b7ff"
                      : "#777777",
                  margin: 0,
                  fontWeight: "bold",
                }}
              >
                {insight_payload.thumbnail_name !== ""
                  ? insight_payload.thumbnail_name
                  : "Empty image"}
              </p>
            </div>
            {insight_payload.thumbnail_name !== "" ? (
              <div
                className="div-flex div-justify-between"
                style={{ marginTop: 10 }}
              >
                <Button
                  disabled={handleFieldDisable()}
                  onClick={() => null}
                  component="label"
                  size="small"
                  color="primary"
                  variant="text"
                  className="btn-remove-capital"
                  style={{ padding: 0 }}
                >
                  Upload
                  <input
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleThumbnail}
                  />
                </Button>
                <MuiThemeProvider theme={themeButton2}>
                  <Button
                    disabled={handleFieldDisable()}
                    onClick={() => deleteFile()}
                    size="small"
                    color="secondary"
                    variant="text"
                    className="btn-remove-capital"
                    style={{ padding: 0 }}
                  >
                    Delete
                  </Button>
                </MuiThemeProvider>
              </div>
            ) : (
              <MuiThemeProvider theme={themeButton2}>
                <Button
                  disabled={handleFieldDisable()}
                  onClick={() => null}
                  component="label"
                  size="small"
                  color="primary"
                  variant="text"
                  className="btn-remove-capital"
                  style={{ padding: 0 }}
                >
                  Upload
                  <input
                    type="file"
                    style={{ display: "none" }}
                    onChange={handleThumbnail}
                  />
                </Button>
              </MuiThemeProvider>
            )}
            <p className="semi-bold" style={{ marginTop: 20 }}>
              SEO Setting
            </p>
            <TextField
              label="Meta title"
              multiline
              variant="outlined"
              className={classes.textField}
              size="small"
              value={insight_payload.meta_title}
              onChange={(e) =>
                dispatch(setInsight({ meta_title: e.target.value }))
              }
              inputProps={{ maxLength: 100 }}
              disabled={handleFieldDisable()}
              helperText={
                <div
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >{`${insight_payload.meta_title.length}/100`}</div>
              }
            />
            <TextField
              multiline
              label="Slug"
              type="text"
              value={insight_payload.slug}
              onChange={(e) => onChangeSlug(e.target.value)}
              variant="outlined"
              size="small"
              className={classes.textField}
              disabled={handleFieldDisable()}
            />
            <TextField
              label="Meta description"
              multiline
              variant="outlined"
              className={classes.textField}
              size="small"
              disabled={handleFieldDisable()}
              value={insight_payload.meta_desc}
              onChange={(e) =>
                dispatch(setInsight({ meta_desc: e.target.value }))
              }
              inputProps={{ maxLength: 160 }}
              helperText={
                <div
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >{`${insight_payload.meta_desc.length}/160`}</div>
              }
            />
            <ChipInput
              label="Keyword"
              value={insight_payload.keyword}
              onAdd={(chip) =>
                dispatch(
                  setInsight({ keyword: [...insight_payload.keyword, chip] })
                )
              }
              onDelete={(chip, index) => deleteKeyword(chip, index)}
              variant="outlined"
              className={classes.textField2}
              disabled={handleFieldDisable()}
            />
            <p style={{ color: "#777777" }}>----------------------------</p>
            {insight_action !== "add_insight" &&
              insight_payload.publish === 0 && (
                <MuiThemeProvider theme={themeButton2}>
                  <Button
                    disabled={handleFieldDisable()}
                    onClick={() => deleteInsight()}
                    size="small"
                    color="secondary"
                    variant="text"
                    className="btn-remove-capital"
                    style={{ padding: 0 }}
                  >
                    Delete insight
                  </Button>
                </MuiThemeProvider>
              )}
          </div>
        </div>
      </MuiThemeProvider>
    </div>
  );
}
