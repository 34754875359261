import React, { Component } from 'react'
import {connect} from 'react-redux'
import {Modal} from '@material-ui/core'
import './style.css'
import Close from 'assets/icon/close.svg'
import {modalToggleReset} from 'redux/actions/general'
import Alert from './src/alert'
import Profile from './src/profile'
import AddRm from './src/add_rm'
import Addcontact from './src/add_contact'
import FilterClient from './src/filter_client'
import AddFolder from './src/add_folder'
import AddFolder2 from './src/add_folder2'
import AddFolder3 from './src/add_folder3'
import AddFile from './src/add_file'
import ConfirmDelete from 'components/Modal/src/confirm_delete'
import ChooseProject from './src/choose_project'
import ChooseWorkshop from './src/choose_workshop'
import NewClient from './src/new_client'
import Participant from './src/participant'
import Confirm from './src/confirm'
import ViewVideo from './src/view_video'
import Advanced_search from './src/advanced_search'
class index extends Component {
    renderBody=()=>{
        const {modal_data,modal_title,modal_component,modal_type,modal_action}=this.props.general
        const {token,profile}=this.props

        switch (modal_type) {
            case 'confirm':
                return (
                    <>
                        {modal_component==='confirm_delete'&&<ConfirmDelete modal_title={modal_title} token={token} profile={profile} modal_data={modal_data} modal_action={modal_action}/>}
                        {modal_component==='confirm2'&&<Confirm modal_title={modal_title} token={token} profile={profile} modal_data={modal_data} modal_action={modal_action }/>}

                        {/* {modal_component==='confirm_unpublish'&&<ConfirmUnpublish modal_title={modal_title} token={token} profile={profile} modal_data={modal_data} modal_action={modal_action }success_msg={success_msg}/>} */}
                    </>
                )
                break;
            case 'alert':
                return <Alert token={token}/>
            default:
                return(
                        <>
                            <div className='modal-header'>
                                    <h2>{modal_title}</h2>
                                    <img src={Close} style={{width:20}} onClick={this.props.modalToggleReset}/>
                            </div>
                                {/* <hr className='modal-hr' size='1'/> */}
                            <div className='modal-body' >
                                   {modal_component==='rm'&&<AddRm modal_action={modal_action} modal_data={modal_data}/>}
                                   {modal_component==='contact'&&<Addcontact modal_action={modal_action} modal_data={modal_data}/>}
                                   {modal_component==='filter_client'&&<FilterClient modal_action={modal_action} modal_data={modal_data} token={token}/>}
                                   {modal_component==='add_folder'&&<AddFolder modal_action={modal_action} modal_data={modal_data} token={token} profile={profile}/>}
                                   {modal_component==='add_folder2'&&<AddFolder2 modal_action={modal_action} modal_data={modal_data} token={token} profile={profile}/>}
                                   {modal_component==='add_folder3'&&<AddFolder3 modal_action={modal_action} modal_data={modal_data} token={token} profile={profile}/>}
                                   {modal_component==='add_file'&&<AddFile modal_action={modal_action} modal_data={modal_data} token={token}/>}
                                   {modal_component==='choose_project'&&<ChooseProject modal_action={modal_action} modal_data={modal_data} token={token}/>}
                                   {modal_component==='choose_workshop'&&<ChooseWorkshop modal_action={modal_action} modal_data={modal_data} token={token}/>}
                                   {modal_component==='new_client'&&<NewClient modal_action={modal_action} modal_data={modal_data} token={token}/>}
                                   {modal_component==='participant'&&<Participant modal_action={modal_action} modal_data={modal_data} token={token} profile={profile}/>}
                                   {modal_component==='view_video'&&<ViewVideo modal_action={modal_action} modal_data={modal_data} token={token} profile={profile}/>}
                                   {modal_component==='advanced_search'&&<Advanced_search modal_action={modal_action} modal_data={modal_data} token={token} profile={profile}/>}
                            </div>
                        </>
                )
                break;
        }
    }
    render() {
        const {modal_open,modal_data,modal_title,modal_component,modal_size,modal_type,modal_action}=this.props.general
        const {token,profile}=this.props
        return (
            <div>
                {modal_type!=='profile'?
                <Modal
                    className='modal'
                    open={modal_open}
                    onClose={modal_component!=='upload_file'&&this.props.modalToggleReset}
                >
                   <div className='modal-content' style={{width:modal_size}}>
                        {this.renderBody()}
                   </div>
                </Modal>
                :
                <Modal
                    className='modal-profile'
                    open={modal_open}
                    onClose={this.props.modalToggleReset}
                >
                    
                   <div className='modal-content' style={{width:modal_size}}>
                        <Profile modalToggleReset={this.props.modalToggleReset} profile={profile} token={token}/>
                   </div>
                </Modal>
                }
            </div>
        )
    }
}
const mapStateToProps=(state)=>{
    return{
        general:state.general
    }
}
const mapDispatchToProp={
    modalToggleReset
}
export default connect(mapStateToProps,mapDispatchToProp)(index)