import React, { Component } from 'react'
import './style.css'
import Navbar from 'components/Navbar'
import Sidebar from 'components/Sidebar'
import Modal from 'components/Modal'
import Cookie from 'universal-cookie'
import Loading from 'components/Loading'
import {Modal as Modal2} from '@material-ui/core'
import Search from 'views/Search'
const cookie=new Cookie()

export default class index extends Component {
    state={
        width: window.innerWidth,
        search:false
    }
    componentWillMount() {
        window.addEventListener('resize', this.handleWindowSizeChange);
    }
    componentWillUnmount() {
        window.removeEventListener('resize', this.handleWindowSizeChange);
    }
    handleWindowSizeChange = () => {
        this.setState({ width: window.innerWidth });
    };

    searchToggle=(bool)=>{
        this.setState({search:bool})
    }
    render() {
        const token=cookie.get('login_cookie')
        const profile=cookie.get('profile_cookie')
        const isMobile = this.state.width <= 768;
        let {master}=this.props
        let show=false
        if(isMobile){
            // show=true
            // this.setState({show_modal:true})
        }
        // console.log(`this.state.search`, this.state.search)
        return (
            <div>
                 <Modal2
                        className='modal'
                        open={show}
                        // onClose={modalToggle}
                        style={{margin:0,width:'100%',zIndex:999999999999}}
                    >
                        <div className='modal-content' style={{width:400,padding:10}}>
                        <p className='semi-bold'>This application is not compatible for mobile device, access Knowledge Center application from desktop (PC/Laptop) browser.</p>

                        </div>
                    </Modal2>
                <Navbar searchToggle={this.searchToggle} profile={profile} token={token}/>
                <Sidebar searchToggle={this.searchToggle} token={token} profile={profile}/>
                <Loading/>
                <div className='content-wrapper'>
                
                    <Modal token={token} profile={profile}/>
                    {this.state.search?<Search/>:this.props.children}
                </div>
            </div>
        )
    }
}
