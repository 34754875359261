export const GET_MASTER_DATA='GET_MASTER_DATA'
export const GET_RM_SEARCH='GET_RM_SEARCH'
export const GET_CLIENT_SEARCH='GET_CLIENT_SEARCH'
export const GET_CONTACT='GET_CONTACT'
export const GET_EMPLOYEE='GET_EMPLOYEE'
export const GET_DETAIL_CLIENT='GET_DETAIL_CLIENT'

export const SET_TAB='SET_TAB'

export const SET_BREAD_CRUM='SET_BREAD_CRUM'

export const GET_TRIBE='GET_TRIBE'
export const GET_PLATFOFRM='GET_PLATFOFRM'

export const GET_TOPIC='GET_TOPIC'
