import React, { Component } from 'react'
import '../style.css'
import {Button,FormControl,InputLabel,OutlinedInput,InputAdornment
,Table,TableHead,TableCell,TableRow,TableBody} from '@material-ui/core'
import SearchImg from 'assets/icon/Search.png'
import filter from 'assets/icon/filter.svg'
import Eye from 'assets/icon/eye.svg'
import Edit from 'assets/icon/edit.svg'
import Close from 'assets/icon/close.svg'
import CevronRight from 'assets/icon/chevron-right.svg'
import CevronLeft from 'assets/icon/chevron-left.svg'
import {connect} from 'react-redux'
import Skeleton from 'components/Skeleton'
import { TablePagination } from '@trendmicro/react-paginations';
import '@trendmicro/react-paginations/dist/react-paginations.css';
import {getClient,setSearch,detailClient,setClientAction,clearState,uploadCsv} from 'redux/actions/client'
import {modalToggle} from 'redux/actions/general'
import {debounce} from 'lodash'
import { MuiThemeProvider, createMuiTheme,withStyles,
} from '@material-ui/core/styles'
import Close2 from 'assets/icon/close2.svg'
const themeButton = createMuiTheme({ 
    palette: { 
        primary: {
            main:'#a8e090',
            contrastText: '#FFFFFF',
        },
        secondary: {
            main:'#ff6e79',
            contrastText: '#FFFFFF',
        }
    } 
})
class index extends Component {
    state={
        page:1,
        pageLength:10,
        search:'',

        file:null,
        file_error:null,
        file_name:'',
        file_size:'',
        change_file:false
    }
    componentDidMount(){
        this.getClient()
    }
    getClient=()=>{
        const {page,pageLength,search}=this.state
        const {client,}=this.props
        const {client_pagination}=client
        if(client.list_client.length>0){
            // console.log('client_pagination', client_pagination)
            this.props.getClient(this.props.token,`/0/0/0/${client_pagination.page}/${client_pagination.perPage}/${client.search===''?'*':client.search}`)

        }else{
        this.props.getClient(this.props.token,`/0/0/0/${page}/${pageLength}/${client.search===''?'*':client.search}`)

        }
    }
    searchByType=debounce(async(value)=>{
        const {client,}=this.props
        const {client_pagination}=client
        this.props.getClient(this.props.token,`/0/0/0/1/10/${client.search===''?'*':client.search}`)
    },1000)
    searchToggle=async(e)=>{
        let {value}=e.target
        const {client}=this.props
        
        await this.props.setSearch(value)
        if(client.search.length>0){
            this.searchByType(value)
        }
        
    }
    handleKeyPress = e => {
        if (e.key === "Enter") {
            //     this.setState(state => ({
            //         district: option
            //     }));
            // this.getClient();
            const {client,}=this.props
            this.props.getClient(this.props.token,`/0/0/0/1/10/${client.search===''?'*':client.search}`)
        }
    };
    togglePagination=(page,pageLength)=>{
        console.log('pagination',pageLength)
        const {client,}=this.props
        this.setState({page,pageLength},()=>{
            this.props.getClient(this.props.token,`/0/0/0/${page}/${pageLength}/${client.search===''?'*':client.search}`)
        })

    }
    addFilter=()=>{
        this.props.modalToggle({ 
            modal_open: true,
            modal_title: `Filter Client`,
            modal_component: "filter_client",
            modal_size:300,
            modal_type:'add_filter',
            modal_data:null,
            modal_action:'add_filter'
        })
    }
    addClient=()=>{
        this.props.tabToggle('add_client')
        this.props.clearState()
        this.props.setClientAction('add_client')

    }
    detailClient=async(id,action)=>{
        console.log('id', id)
        let res=await this.props.detailClient(this.props.token,id)
        if(res){
            this.props.setClientAction(action)
            this.props.tabToggle('add_client')
        }
    }
    deleteClient=(id,name)=>{
        console.log('id,name', id,name)
        this.props.modalToggle({ 
            modal_open: true,
            modal_title: `list`,
            modal_component: "confirm_delete",
            modal_size:400,
            modal_type:'confirm',
            modal_data:{
                title:'Client',
                id:id,
                userId:this.props.profile.id,
                msg:`<p>Delete <b>${name}</b> will impact to project or workshop connected to <b>${name}</b>. make sure you have downloaded file project or workshop.</p>`
            },
            modal_action:'delete_client'
        })
    }
    handleFile=(evt)=>{
        const file = evt.target.files[0];
        const fileTypes = ['csv'];

        if (evt.target.files && file) {
            const extension = evt.target.files[0].name.split('.').pop().toLowerCase();
            const isSuccess = fileTypes.indexOf(extension) > -1;
            console.log('is',isSuccess)
            if (isSuccess) {
                let size=this.formatBytes(file.size)
                this.setState({
                    file: file,
                    file_error: null,
                    file_size:size,
                    file_name:file.name
                });
            }
            else {
                let size=this.formatBytes(file.size)
                this.setState({
                    file_size:size,
                    file_name:file.name,
                    file: file,
                    file_error:'File type not allowed. File type to be must *.csv'
                })
            }
        }
    }
    formatBytes=(bytes, decimals = 2)=> {
        if (bytes === 0) return '0 Bytes';
    
        const k = 1024;
        const dm = decimals < 0 ? 0 : decimals;
        const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    
        const i = Math.floor(Math.log(bytes) / Math.log(k));
    
        return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
    }
    onClickSave=()=>{
        let {file}=this.state
        let fd= new FormData()
        fd.append('files',file)
        this.props.uploadCsv(this.props.token,fd)
    }
    render() {
        let {tabToggle,client,general}=this.props
        let {file,file_size,file_name,file_error}=this.state
        // console.log('client', client)
        return (
            <div>
                 <div className='head-section'>
                    <div>
                        <h4>Client list</h4>
                    </div>
                    <div style={{display:'flex',alignItems:'center'}}>
                        <MuiThemeProvider theme={themeButton}>
                        <Button onClick={()=>this.addClient()} size='small' color='primary' variant='contained' className='head-add-section__btn remove-boxshadow'>Add new client</Button>
                        
                        {file!==null?
                        <>
                        &nbsp;&nbsp;&nbsp;&nbsp;
                        {file_error===null?
                        <div className='file-title'>
                            <h6><b>{file_name}</b>&nbsp; {file_size}</h6>
                        </div>
                        :
                        <div className='file-title'>
                            <img src={Close2}  style={{cursor:'pointer'}} onClick={()=>this.setState({file:null})}/>
                            &nbsp;&nbsp;
                        <h6><b>{file_name}</b>&nbsp; {file_size}<br/><span style={{color:'red'}}>{file_error}</span></h6>
                        </div>
                        }
                        <Button onClick={()=>this.onClickSave()} size='small' color='primary' variant='contained' className='head-add-section__btn'>Save</Button>
                        <Button component="label" size='small' color='secondary' variant='contained' className='head-add-section__btn'>
                            Change file
                            <input
                                type="file"
                                style={{ display: "none" }}
                                onChange={this.handleFile}
                            />
                        </Button>
                        </>
                        :
                        <Button  component="label" size='small' color='primary' variant='contained' className='head-add-section__btn remove-boxshadow'>
                            Upload client data
                            <input
                                type="file"
                                style={{ display: "none" }}
                                onChange={this.handleFile}
                            />
                        </Button>
                        }
                        </MuiThemeProvider>
                    </div>
                </div>
                <div className='card-content'>
                    <div className='card-table'>
                       <div className='card-table__head'>
                            <FormControl variant="outlined">
                                <InputLabel htmlFor="input-with-icon-textfield">Search</InputLabel>
                                <OutlinedInput
                                    size='small'
                                    style={{height:30,width:200,}}
                                    value={client.search}
                                    id="input-with-icon-textfield"
                                    name='password'
                                    onChange={this.searchToggle}
                                    onKeyPress={e =>
                                        this.handleKeyPress(e)
                                    }
                                    required
                                    startAdornment={
                                    <InputAdornment position="start">
                                       <img alt="search" src={SearchImg} style={{width:15}}/>
                                    </InputAdornment>
                                    }
                                    labelWidth={50}
                                />
                            </FormControl>
                             <div style={{display:'flex',alignItems:'center',}}>
                                {client.client_filter.industry.value!==0&&<p className='pipeline-filterby'><b>Industry: {client.client_filter.industry.label}</b>&nbsp;&nbsp;</p>}
                                {client.client_filter.segment.value!==0&&<p className='pipeline-filterby'><b>Segment: {client.client_filter.segment.label}</b>&nbsp;&nbsp;</p>}
                                {client.client_filter.rm.value!==0&&<p className='pipeline-filterby'><b>Relationship manager: {client.client_filter.rm.label}</b>&nbsp;&nbsp;</p>}
                                <button onClick={this.addFilter} className='card-table__head_btn'><img src={filter} style={{width:20}}/>&nbsp;&nbsp;Filter</button>
                            </div>
                       </div>
                       <div className='card-table__content'>
                       <Table  size="small" aria-label="a dense table">
                            <TableHead>
                            <TableRow>
                                <TableCell align="left">Company name</TableCell>
                                <TableCell align="left">Industry</TableCell>
                                <TableCell align="left">Address</TableCell>
                                <TableCell align="left">Phone No.</TableCell>
                                <TableCell align="center">Action</TableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                                {general.isLoadingTable?
                                    <Skeleton count={5}/>
                                :
                                client.list_client.map((data,i)=>(
                                    <TableRow key={i}>
                                        <TableCell style={{maxWidth:200,minHeight:100,}}>{data.company}</TableCell>
                                        <TableCell >{data.industry}</TableCell>
                                        <TableCell style={{maxWidth:200,minHeight:100,}}>{data.address}</TableCell>
                                        <TableCell >{data.phone}</TableCell>
                                        <TableCell style={{width:100,minHeight:100,}} align="right">
                                            <img src={Eye} onClick={()=>this.detailClient(data.id,'see_client')} className='icon-action'/>
                                            <img src={Edit} onClick={()=>this.detailClient(data.id,'edit_client')} className='icon-action'/>
                                            <img src={Close} onClick={()=>this.deleteClient(data.id,data.company)} className='icon-action'/>
                                        </TableCell>
                                    </TableRow>
                                ))
                               
                                }
                            </TableBody>
                        </Table>
                        <div className='card-table__pagination'>
                        {client.client_pagination!==null&&
                        <TablePagination
                                className="card-pagination"
                                type="reduced"
                                page={client.client_pagination.page}
                                pageLength={client.client_pagination.perPage}
                                totalRecords={client.client_pagination.total}
                                totalRecords={client.client_pagination.total}
                                onPageChange={({ page, pageLength }) => {
                                    this.togglePagination(page,pageLength)
                                }}
                                prevPageRenderer={() => <img src={CevronLeft} style={{width:10}}/>}
                                nextPageRenderer={() => <img src={CevronRight}/>}
                            />
                        }
                    </div>
                       </div>
                   </div>
                </div>
            </div>
        )
    }
}
const mapStateToProps=(state)=>({
    general:state.general,
    client:state.client
})
const mapDispatchToProps={
    // addClient
    setSearch,
    getClient,
    modalToggle,
    detailClient,
    setClientAction,
    clearState,
    uploadCsv
}
export default connect(mapStateToProps,mapDispatchToProps)(index)