import React,{useState} from 'react'
import './style.css'
import PDFicon from 'assets/icon/PDFicon.svg'
import Allfileicon from 'assets/icon/Allfileicon.svg'
import Musicicon from 'assets/icon/Musicicon.svg'
import Movieicon from 'assets/icon/Movieicon.svg'
import Imageicon from 'assets/icon/Imageicon.svg'
import Wordicon from 'assets/icon/Wordicon.svg'
import PPTicon from 'assets/icon/PPTicon.svg'
import ExcelIcon from 'assets/icon/ExcelIcon.svg'
import CSVicon from 'assets/icon/CSVicon.svg'
import {Button, Backdrop} from '@material-ui/core'
import moment from 'moment'
import CevronRight from 'assets/icon/chevron-right.svg'
import CevronLeft from 'assets/icon/chevron-left.svg'
import { TablePagination } from '@trendmicro/react-paginations';
import { useDispatch, useSelector } from 'react-redux'
import { groupBy } from 'lodash'
import { viewFile,viewVideo } from 'redux/actions/files'
import Skeleton from 'react-loading-skeleton'
import Cookie from 'universal-cookie'
import { getSearch } from 'redux/actions/search'
import EmptyFolder from 'assets/image/EmptyFolder.png'

const cookie=new Cookie()
const token=cookie.get('login_cookie')
const profile=cookie.get('profile_cookie')

export default function Index() {
    const dispatch = useDispatch()
    const master = useSelector(state => state.master)
    const search = useSelector(state => state.search)
    const general = useSelector(state => state.general)
    let {result,pagination}=search
    const [more, setmore] = useState({})
    const [tribeId, settribeId] = useState(0)
    let rgrup=Object.entries(groupBy(result,'tribeId'))
    const renderIcon=(type)=>{
        switch (type) {
            case 'pdf':
                return PDFicon
                break;
            case 'doc':
                return Wordicon
                break;
            case 'docx':
                return Wordicon
                break;
            case 'ppt':
                return PPTicon
                break;
            case 'pptx':
                return PPTicon
                break;
            case 'xls':
                return ExcelIcon
                break;
            case 'xlsx':
                return ExcelIcon
                break;
            case 'png':
                return Imageicon
                break;
            case 'jpg':
                return Imageicon
                break;
        
            default:
                return Allfileicon
                break;
        }
    }
    const File=(props)=>{
        return(
            <div className='card-access'>
                <div className='card-access-body' style={{height:'unset'}}>
                    <div className='div-flex div-space-between' style={{position:'relative'}}>
                        <p  className='card-access-title' style={{margin:0,width:'85%',overflowWrap:'break-word'}} title={props.data.filename}>{props.data.filename.length>40?`${props.data.filename.substring(0,40)}...`:props.data.filename}</p>
                        <img style={{width:20,position:'absolute',right:0}} src={renderIcon(props.data.fileType)}/>
                    </div>
                    <div>
                    <p style={{margin:'6px 0'}} className='card-access-subtitle'>{props.data.projectName}</p>
                    <div className='div-flex div-space-between div-align-center' style={{position:'relative'}}>
                    <p className='card-access-subtitle'>{moment(props.data.startDate).format('YYYY')}</p>
                    
                    </div>
                    </div>
                </div>
    
                <div style={{width:'100%',height:1,backgroundColor:'#ccc'}}></div>
                <div className='card-access-footer'>
                    <Button onClick={()=>viewFiles(props.data.id,props.data)} size='small' style={{height:30,fontSize:12,margin:0,width:'20px!important'}} color='primary' variant='text' className='remove-capital'>View</Button>
                </div>
            </div>
        )
    }
    const togglePagination=(page,pageLength,r)=>{
        setmore({})
        dispatch(getSearch(`/${r[0]}/${search.doctype}/${search.filetype.length===0?'*':search.filetype}/${search.client.length===0?'*':search.client}/${search.keyword}/${page}/${pageLength}`))
    }
    // console.log(`more`, more)
    const viewFiles=(id,file)=>{
        if(file.fileType==='mp4'){
            dispatch(viewVideo(token,`/4/${id}/${profile.id}`))

        }else{
            dispatch(viewFile(token,`/4/${id}/${profile.id}`))
        }
    }
    const renderTribe=(id)=>{
        let filter=master.tribes.filter((d)=>{
            return d.id===parseInt(id)
        })
        // console.log(`filter`, filter,id)
        if(filter.length>0){
            return filter[0].text
        }else{
            return id
        }
    }
    const moreToggle=(id)=>{
        setmore({...more,[id]:true})
    }
    // console.log(`pagination.page`, pagination.page)
    return (
        <div>
            <br/>
            {general.isLoadingSearch?<>
            <div className="s-c-result">
                <div style={{width:'25%'}}>
                    <Skeleton count={1} height={100}/>
                </div>
                <div style={{width:'70%'}}>
                <Skeleton count={1} height={100}/>

                </div>
            </div>
            <div className="s-c-result">
                <div style={{width:'25%'}}>
                    <Skeleton count={1} height={100}/>
                </div>
                <div style={{width:'70%'}}>
                <Skeleton count={1} height={100}/>

                </div>
            </div>
            </>:
            rgrup.length===0?
            <div  className='empty-folder div-flex div-align-center'>
                <img src={EmptyFolder} style={{width:'50%'}}/>
                <h3>Your search did not match any documents</h3>
                {/* <h6>Start upload your document</h6> */}
            </div>
            :
            <>
            {rgrup.map((d,i)=>(
                <div key={i}>
                    <h3 style={{color:'#777777'}}>Search results in {renderTribe(d[0])}</h3>
                    <div style={{backgroundColor:'#cccccc',width:'100%',height:1.5}}></div>
                    <br/>
                    {d[1].slice(0,pagination.page>1?d[1].length:more[i]?d[1].length:2).map((r,i2)=>(
                        <div className="s-c-result" key={i2}>
                            <div style={{width:'25%'}}>
                            <File data={r}/>
                            </div>
                            <div className="s-c-info" style={{width:'70%'}}>
                                <div style={{display:'flex'}}>
                                    <p style={{width:200}}>Project / Workshop name </p>
                                    <p style={{color:'#65b7ff'}}><span style={{color:'black'}}>:</span> {r.projectName}</p>
                                </div>
                                <div style={{display:'flex'}}>
                                    <p style={{width:200}}>Client</p>
                                    <p>: {r.client}</p>
                                </div>
                                <div>
                                    <p >Content:</p>
                                    <p>{r.content}</p>
                                </div>
                            </div>

                        </div>
                    ))}
                    <br/>
                    <div className='card-table__pagination'>
                        {(more[i]&&pagination.total!==0)&&
                        <TablePagination
                                className="card-pagination"
                                type="reduced"
                                page={pagination.page}
                                pageLength={pagination.perPage}
                                totalRecords={pagination.total}
                                totalRecords={pagination.total}
                                onPageChange={({ page, pageLength }) => {
                                    togglePagination(page,pageLength,d)
                                }}
                                prevPageRenderer={() => <img src={CevronLeft} style={{width:10}}/>}
                                nextPageRenderer={() => <img src={CevronRight}/>}
                            />
                        } 
                    </div>
                    <br/>
                    {(d[1].length>2&&pagination.page<2&&!more[i])&&<><div style={{textAlign:'center'}}>
                        <Button onClick={()=>moreToggle(i)} className='btn-rounded btn-remove-capital' variant="contained" color="primary">See More</Button>

                    </div>
                    <br/></>}
                </div>
            ))}
            
            
                </>
            }
        </div>
    )
}
