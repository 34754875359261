import React from 'react'
import {Button,FormControl,InputLabel,OutlinedInput,InputAdornment
    ,TextField,Select,MenuItem,FormControlLabel} from '@material-ui/core'
import { MuiThemeProvider, createMuiTheme,withStyles,makeStyles
} from '@material-ui/core/styles'
import image1 from './insight1.jpg'
import {useSelector} from 'react-redux'
const themeButton = createMuiTheme({ 
    palette: { 
        primary: {
            main:'#a8e090',
            contrastText: '#FFFFFF',
        },
        secondary: {
            main:'#ffc466',
            contrastText: '#FFFFFF',
        }
    } 
})
export default function Card_prev(props) {
    const insight = useSelector(state => state.insight)
    const {insight_payload}=insight
    let a=insight_payload.content.match(/<p>(.*?)<\/p>/g); 
    let prev
    if(a!==null){
        prev= a[0].replace(/<\/?[^>]+(>|$)/g, "")

    }else{
        prev=''
    }
    console.log('str', prev)
    return (
        <div>
            <div className='head-section'>
                    <div>
                        <h4>Card preview</h4>
                    </div>
                    <div style={{display:'flex',alignItems:'center'}}>
                        <MuiThemeProvider theme={themeButton}>
                        <Button onClick={()=>props.tabToggle('add_insight')} size='small' color='secondary' variant='outlined' className='head-add-section__btn remove-boxshadow'>Back</Button>
                        <Button onClick={()=>props.tabToggle('detail_preview')} size='small' color='secondary' variant='contained' className='head-add-section__btn remove-boxshadow'>Detail insight preview</Button>
                        
                        
                        </MuiThemeProvider>
                    </div>
            </div>
            <div className='div-flex div-space-between'>
                <div className='preview-card-big'>
                    <div className="overlay">
                        <img src={insight_payload.thumbnail_url} alt="insight" className="img-box-big"/>
                        <div className="caption">
                            <div style={{display:'flex',justifyContent:'space-between'}}>
                                <h3>{insight_payload.category!==null&&insight_payload.category.text}</h3>
                            </div>
                            <h1>{insight_payload.title}</h1>
                            <p>{prev.substring(0,150)} …</p>
                            <div className='grid2-footer'>
                            <Button style={{textTransform:'none',fontWeight:'bold',padding:0}} color="primary" onClick={()=>null}>Read more </Button>
                            </div>
                            
                        </div>
                    </div>
                </div>
                <div className='preview-card-small'>
                    <div className='overlay-small'>
                        <img src={insight_payload.thumbnail_url} alt="event" className="img-box-small"  />
                        <div className='card'>
                                <h6 >{insight_payload.category!==null&&insight_payload.category.text}</h6>

                                <h3 className='title-splice'>{insight_payload.title.length>40?`${insight_payload.title.substring(0,39)}...`:insight_payload.title}</h3>
                                <div className='grid2-footer'>
                                    <Button style={{textTransform:'none',fontWeight:'bold',padding:0}} color="primary" onClick={()=>null}>Read more </Button>

                                </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
