import React,{useState,useEffect} from 'react'
import './style.css'
import { MuiThemeProvider, createMuiTheme,withStyles,makeStyles
} from '@material-ui/core/styles'
import {Button,Modal,TextField,FormControl,InputLabel,OutlinedInput,MenuItem,Select,
    FormHelperText } from '@material-ui/core'
import AutoCompleteSelect from 'components/Select'
import {
    DatePicker,
    TimePicker,
    DateTimePicker,
    MuiPickersUtilsProvider,
    } from '@material-ui/pickers';
    import MomentUtils from '@date-io/moment';
import Participants from './participants'
import Upload from './upload_file'
import { useDispatch, useSelector } from "react-redux";
import {debounce,isEmpty} from 'lodash'
import ChipInput from 'material-ui-chip-input'
import {setFilesAction,putProject,getProjWorkModal,setAddProjectPayload,getProduct,postProject,setProjectId} from 'redux/actions/files'
import {getTribe,getEmployee,getClient,getContact,postTrainer} from 'redux/actions/master'
import {modalToggle} from 'redux/actions/general'
import {getClient as getForClient,setClientAction,clearState} from 'redux/actions/client'

const themeButton2 = createMuiTheme({ 
    palette: { 
        primary:{
            main:'#ffc466',
            contrastText: '#FFFFFF',
        },
        secondary:{
            main:'#65b7ff',
            contrastText: '#FFFFFF',
        },

    } 
})

const useStyles = makeStyles(theme => ({
    textField: {
      [`& fieldset`]: {
        borderRadius: 10,

      },
      width:'100%',
      marginBottom:15,
  },
 
  
}));
export default function Workshop(props) {
    const classes=useStyles()
    const dispatch=useDispatch()
    const [search2,setSearch2]=useState('')
    const [loading,setLoading]=useState(false)
    const [tab,tabActive]=useState('info_workshop')
    const [textSelect,setTextSelect]=useState('Type company name')

    const master=useSelector(state=>state.master)
    const files=useSelector(state=>state.files)

    const {add_project}=files

    const tabToggle=(slug)=>{
        tabActive(slug)
    }
    useEffect(()=>{
        dispatch(getEmployee(props.token))
        dispatch(getTribe(props.token))
    },[])
    const searchToggle2=debounce(async (value)=>{
        setSearch2(value)
        // setTextSelect('No options')

        if(value!==search2&&value.length>0){
            // console.log('value eh', value,value.length)
            setLoading(true)
            let res=await dispatch(getClient(props.token,value))
            if(isEmpty(res)){
                setTextSelect('No options')
            }
            setLoading(false)
        }
        
    },1000)
    const chooseWorkshop=async ()=>{
        
        
        dispatch(modalToggle({ 
            modal_open: true,
            modal_title: "Select workshop",
            modal_component: "choose_workshop",
            modal_size:800,
            modal_action:'choose_workshop'
        })) 
    
    
}
const onSelectCompany=(data)=>{
    dispatch(setAddProjectPayload('clientId',data))

    // dispatch(getContact(props.token,data.value))
}
    const handleDisable=()=>{
        if(add_project.tribeId!==null&&add_project&&add_project.name!==''){
            return false
        }else{
            return true
        }
    }
    const addWorkshop=async ()=>{
        let new_project_member_id=[]
        let new_clientProjectMemberIds=[]
        let newProd=[]
        let new_train=[]
        add_project.projectMemberIds.map((data)=>{
            new_project_member_id.push(data.value)
        })
        add_project.clientProjectMemberIds.map((data)=>{
            new_clientProjectMemberIds.push(data.id)
        })
        add_project.products.map((data)=>{
            newProd.push(data.id)
        })
        add_project.trainerId.map(data=>{
            new_train.push(data.value)
        })
        if(files.project_id!==null){
            let data={
                id:files.project_id,
                name:add_project.name,
                status:'',
                venue:add_project.venue,
                startDate:add_project.startDate.format('YYYY-MM-DD'),
                endDate:add_project.endDate.format('YYYY-MM-DD'),
                keyWords:add_project.keyWords,
                clientId:0,
                tribeId:add_project.tribeId.id,
                workshopTypeId:add_project.workshopTypeId,
                userId:props.profile.id,
                trainerIds:new_train,
                projectAdvisorId:0,
                projectLeaderId:0,
                projectMemberIds:[],
                clientProjectOwnerId:0,
                clientProjectLeaderId:0,
                clientProjectMemberIds:[],
                productIds:[]
            }
            await dispatch(putProject(props.token,data))
            tabToggle('participant')
        }else{
        let data={
            name:add_project.name,
            status:'',
            venue:add_project.venue,
            startDate:add_project.startDate.format('YYYY-MM-DD'),
            endDate:add_project.endDate.format('YYYY-MM-DD'),
            keyWords:add_project.keyWords,
            clientId:0,
            tribeId:add_project.tribeId.id,
            workshopTypeId:add_project.workshopTypeId,
            userId:props.profile.id,
            trainerIds:new_train,
            projectAdvisorId:0,
            projectLeaderId:0,
            projectMemberIds:[],
            clientProjectOwnerId:0,
            clientProjectLeaderId:0,
            clientProjectMemberIds:[],
            productIds:[]
        }
        let res=await dispatch(postProject(props.token,data))
        if(res){
            dispatch(setProjectId(res.project.id))
            tabToggle('participant')
            
        }
    }
        // tabToggle('participant')
    }
    const deleteKeyword=(chip,index)=>{
        let filter=add_project.keyWords.filter((data)=>{
            return data!==chip
        })
        dispatch(setAddProjectPayload('keyWords',filter))
    }
    const onKeyDown=async (e)=>{
        if(e.keyCode == 13){
           let filterisnotexist=master.employee.filter(emp=>{
               return emp.label.toLowerCase().includes(e.target.value.toLowerCase())
           })
           let data={
                name:e.target.value,
                userId:props.profile.id
            }
           if(isEmpty(filterisnotexist)){
                
                let res=await dispatch(postTrainer(props.token,data))
                if(res){
                    let new_trainer=[...add_project.trainerId,{label:res.text,value:res.id}]
                    dispatch(setAddProjectPayload('trainerId',new_trainer))
                }
           }else{
               let filterpersis=filterisnotexist.filter((emp)=>{
                   return emp.label.toLowerCase()===e.target.value.toLowerCase()
               })
               if(isEmpty(filterpersis.length)){
                    let res=await dispatch(postTrainer(props.token,data))
                    if(res){
                        let new_trainer=[...add_project.trainerId,{label:res.text,value:res.id}]
                        dispatch(setAddProjectPayload('trainerId',new_trainer))
                    }
               }
           }
           // put the login here
        }
     }
    console.log('add_project.trainerId', add_project.trainerId)
    return (
        <div>
            <div className='add-file-grid'>
                <div>
                    <div className='card-content' style={{padding:10}}>
                        {tab==='info_workshop'&&
                        <>
                        <div className='div-flex div-space-between div-align-center' style={{marginBottom:10}}>
                            <p className='semi-bold'>Workshop information</p>
                            <MuiThemeProvider theme={themeButton2}>
                                <Button onClick={chooseWorkshop} size='small' variant='text' color='secondary' className='btn-remove-capital'>Choose Workshop</Button>
                            </MuiThemeProvider>
                        </div>
                        <TextField
                            label='Workshop title'
                            // type='text'
                            value={add_project.name}

                            onChange={(e)=>dispatch(setAddProjectPayload('name',e.target.value))}
                            color='primary'
                            variant='outlined'
                            size='small'
                            name='name'
                            className={classes.textField}

                        />
                        <TextField
                            label='Venue'
                            // type='text'
                            value={add_project.venue}

                            onChange={(e)=>dispatch(setAddProjectPayload('venue',e.target.value))}
                            color='primary'
                            variant='outlined'
                            size='small'
                            name='name'
                            className={classes.textField}

                        />
                        <div style={{width:'100%'}}>
                            <AutoCompleteSelect
                                onChange={(event,value)=>dispatch(setAddProjectPayload('trainerId',value))}
                                options={master.employee}
                                value={add_project.trainerId}
                                getOptionLabel={(option) => option.label}
                                label={<>Trainer</>}
                                onKeyDown={onKeyDown}
                                multiple
                            />
                        </div>
                        <div className='div-flex div-space-between'>
                            <div style={{width:'49%'}}>
                            <AutoCompleteSelect
                                onChange={(event,value)=>dispatch(setAddProjectPayload('tribeId',value))}
                                options={master.tribes}
                                value={add_project.tribeId}
                                getOptionLabel={(option) => option.text}
                                label={<>Tribe</>}

                            />
                            </div>
                            <div style={{width:'49%'}}>
                            <FormControl style={{width:'100%'}} variant="outlined" size="small" className='add-proposal__field' >
                                <InputLabel  htmlFor="category">Workshop Type</InputLabel>
                                <Select  color='primary' name='segmentId'  value={files.add_project.workshopTypeId}  onChange={(e)=>dispatch(setAddProjectPayload('workshopTypeId',e.target.value))} labelId="label" id="select"  labelWidth={100} className='field-radius'>
                                    <MenuItem value={1}>Public</MenuItem>
                                    <MenuItem value={2}>In-house</MenuItem>
                                </Select>
                            </FormControl>
                            </div>
                        </div>
                        
                        <MuiPickersUtilsProvider utils={MomentUtils}>
                        <div className='div-flex div-space-between'>
                            <div style={{width:'49%'}}>
                            <DatePicker format={'DD MMM YYYY'} color='primary' value={add_project.startDate} onChange={(data)=>dispatch(setAddProjectPayload('startDate',data))}  className={classes.textField}  label='Start date' clearable={true} size='small' inputVariant='outlined'  />
                            </div>
                            <div style={{width:'49%'}}>
                            <DatePicker format={'DD MMM YYYY'} color='primary' value={add_project.endDate} onChange={(data)=>dispatch(setAddProjectPayload('endDate',data))}  className={classes.textField}  label='End date' clearable={true} size='small' inputVariant='outlined'  />
                            </div>
                        
                        

                        </div>
                        </MuiPickersUtilsProvider>
                        {/* <AutoCompleteSelect
                            color='primary'
                            noOptionsText={textSelect}
                            loading={loading}
                            disableClearable={true}
                            onChange={(event,value)=>onSelectCompany(value)}
                            options={master.client}
                            getOptionLabel={(option) => option.label}
                            onInputChange={(event,e)=>searchToggle2(e)}
                            label={<>Client company name</>}
                            value={add_project.clientId}
                        /> */}
                        <ChipInput
                            label="Keyword"
                            value={add_project.keyWords}
                            onAdd={(chip) =>dispatch(setAddProjectPayload('keyWords',[...add_project.keyWords,chip]))}
                            // onAdd={(chip) =>console.log('chip', chip)}
                            onDelete={(chip, index) => deleteKeyword(chip,index)}
                            variant="outlined"
                            className={classes.textField}
                        />
                        
                        <div style={{display:'flex',justifyContent:"flex-end"}}>
                        <MuiThemeProvider theme={themeButton2}>
                        <Button disabled={handleDisable()} onClick={()=>addWorkshop()} size='small' variant='contained' color='primary' className='btn-remove-capital  btn-rounded'>Go to participant</Button>

                        </MuiThemeProvider>
                        </div>
                        </>}
                        {tab==='participant'&&<Participants tabToggle={tabToggle} token={props.token} profile={props.profile}/>}
                        {tab==='upload'&&<Upload tabToggle={tabToggle} token={props.token} profile={props.profile}/>}

                    </div>
                </div>
                <div style={{paddingLeft:20}}>
                    <div className='add-file-timeline-container2'>
                        <div className={`add-file-timeline-item ${tab==='info_workshop'||files.project_id!==null?'timeline-active':''}`}>
                            <h2>Complete workshop information</h2>
                            <p>Before Upload file you must define workshop. Title of workshop, Tribe in charge, etc.</p>
                        </div>
                        <div className={`add-file-timeline-item ${tab==='participant'||files.participant.length>0?'timeline-active':''}`}>
                            <h2>Workshop participant</h2>
                            <p>Information on workshop participants who attended</p>
                        </div>
                        <div className={`add-file-timeline-item ${tab==='upload'&&'timeline-active'}`}>
                            <h2>Upload file</h2>
                            <p>Upload material file, you also can create nested folder here.</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
