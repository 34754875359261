import React ,{useCallback,useMemo,useState,useEffect}from 'react'
import './style.css'
import ChevronRight from 'assets/icon/Chevron-right-line.svg'
import AddFolder from 'assets/icon/AddFolder.svg'
import ChevronDown from 'assets/icon/ChevronDown.svg'

import close from 'assets/icon/close.svg'
import { MuiThemeProvider, createMuiTheme,withStyles,makeStyles
} from '@material-ui/core/styles'
import {Button} from '@material-ui/core'
import {useDropzone} from 'react-dropzone'
import {modalToggle} from 'redux/actions/general'
import {setUpload,uploadFile,uploadFileBase64,getProject} from 'redux/actions/files'
import moment from 'moment'
import {useSelector,useDispatch} from 'react-redux'
import {handleFile,getBase64} from 'components/handleFile'
import {add, groupBy} from 'lodash'
const baseStyle = {
    flex: 1,
    display: 'flex',
    justifyContent:'center',
    // flexDirection: 'column',
    alignItems: 'center',
    padding: '20px',
    borderWidth: 2,
    borderRadius: 2,
    borderColor: '#eeeeee',
    borderStyle: 'dashed',
    backgroundColor: '#fafafa',
    color: '#bdbdbd',
    outline: 'none',
    transition: 'border .24s ease-in-out',
    cursor:'pointer'
  };
  
  const activeStyle = {
    borderColor: '#2196f3'
  };
  
  const acceptStyle = {
    borderColor: '#65B7FF'
  };
  
  const rejectStyle = {
    borderColor: '#ff1744'
  };
const themeButton2 = createMuiTheme({ 
    palette: { 
        primary:{
            main:'#ffc466',
            contrastText: '#FFFFFF',
        },
        secondary:{
            main:'#65b7ff',
            contrastText: '#FFFFFF',
        },

    } 
})

export default function Upload_file(props) {
    const dispatch=useDispatch()
    const files = useSelector(state => state.files)
    const [list_file,setFile]=useState([])
    const {detail_after_post,upload,add_project}=files
    const [active_parent,setActiveParent]=useState(0)
    const [breadcrump,setBreadCrump]=useState([
        {
            text:add_project.tribeId.text,
            // id:add_project.tribeId.id
        },
        {
            text:add_project.clientId!==null&&add_project.clientId.label,
            // id:add_project.clientId.value
        },
        {
            text:moment(add_project.startDate).format('YYYY')
        },
        {
            text:add_project.name,
            id:0,
        },
    ])
    useEffect(() => {
        if(files.files_action==='add_workshop'){
            setBreadCrump([
                {
                    text:add_project.tribeId.text,
                    // id:add_project.tribeId.id
                },
                {
                    text:moment(add_project.startDate).format('YYYY')
                },
                {
                    text:add_project.name,
                    id:files.project_id
                },
            ])
        }
    }, [])
    
    const onDrop = useCallback(acceptedFiles => {
        let new_dataup=[]
        acceptedFiles.map((file)=>{
            let fil={
                files:file,
                type:'file',
                parent:active_parent,
                exist:false
            }
            
            
           
            new_dataup.push(fil)
        })
        let data=[
            ...files.upload,
            ...new_dataup
        ]
        dispatch(setUpload(data))
       
      }, [files.upload,active_parent])
      const {acceptedFiles,getRootProps, getInputProps, isDragActive,isDragAccept,isDragReject} = useDropzone({onDrop})
      const style = useMemo(() => ({
        ...baseStyle,
        ...(isDragActive ? activeStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {})
      }), [
        isDragActive,
        isDragReject,
        isDragAccept
      ]);
      const removeFile=(data,index)=>{
        if(data.exist){
            dispatch(modalToggle({ 
                modal_open: true,
                modal_title: `File / Folder`,
                modal_component: "confirm_delete",
                modal_size:400,
                modal_type:'confirm',
                modal_data:{
                    title:'File / Folder',
                    id:data.id,
                    userId:props.profile.id,
                    msg:`<p>Delete <b>${data.files.name}</b> will impact to project or workshop connected to <b>${data.files.name}</b>. make sure you have downloaded file project or workshop.</p>`,
                    slugget:`/${files.project_id}/${breadcrump[breadcrump.length-1].id}`,
                    index:index
                },
                modal_action:'delete_folderfile2'
            }))
        }else{
            files.upload.splice(index,1)
            let data=files.upload
           dispatch(setUpload(data))
        }
        
         
      }
     
      const openFolder=async (data)=>{
        let res=await dispatch(getProject(props.token,`/${files.project_id}/${data.id}`))
            if(res){
                let new_upload=[]
                res.content.folders.map((data2,i)=>{
                    new_upload.push({
                        files:{name:data2.name},
                        type:'folder',
                        id:data2.id,
                        parent:data.id,
                        exist:true
                    })
                })
                res.content.files.map((data2,i)=>{
                    new_upload.push({
                        files:{name:data2.name},
                        type:'file',
                        parent:data.id,
                        exist:true
                    })
                })
                dispatch(setUpload([...files.upload,...new_upload]))
                let new_bread={
                    id:data.id,
                    text:data.files.name
                }
                setActiveParent(data.id)
                setBreadCrump([...breadcrump,new_bread])
            }
        
      }
      const filesList=()=>{
          let map=files.upload.map((data,i)=>{
              if(data.parent===active_parent){

                  return(
                    <div key={data.name} className='div-flex div-space-between div-align-center' style={{marginBottom:10}}>
                    {data.type==='file'&&
                    <>
                    <div className='div-flex div-align-center'>
                        <div style={{width:15,height:15,backgroundColor:'#dddddd',borderRadius:'100%'}}></div>
                        <p style={{marginLeft:10}} className='semi-bold-without-margin' >{data.files.name}</p>
                    </div>
                    <div className='div-flex'>
                        <p style={{fontSize:12,margin:'0px 20px 0px 0px',color:'#bbbbbb'}}>{data.files.size} {data.exist?'':'bytes'}</p>
                        <img onClick={()=>removeFile(data,i)} src={close} style={{width:15,cursor:'pointer'}}/>
                    </div>
                    </>
                    }
                    {data.type==='folder'&&
                    <>
                        <div className='div-flex div-align-center'>
                            <div style={{width:15,height:15,backgroundColor:'#dddddd',borderRadius:'100%'}}></div>
                            <p style={{marginLeft:20}} className='semi-bold-without-margin-blue' >{data.files.name}/</p>
                        </div>
                        <div className='div-flex'>
                            <p onClick={()=>openFolder(data)} style={{fontSize:12,margin:'0px 20px 0px 0px',color:'#65B7FF',fontWeight:'bold',cursor:'pointer'}}>Open</p>
                            <img onClick={()=>removeFile(data,i)} src={close} style={{width:15,cursor:'pointer'}}/>
                        </div>
                    </>
                    }
                </div>
                  )
              }
          })
          return map
      }
      
      console.log('active_parent', active_parent)
      const addFolder=()=>{
        dispatch(modalToggle({
            modal_open: true,
            modal_title: "Create Folder",
            modal_component: "add_folder2",
            modal_data:{
                parentId:active_parent,
                projectId:files.project_id,
                onegml:0,
                active_parent:active_parent
            } ,
            modal_size:300,
            modal_action:'add_folder2'
        }))
      }
      const toFolder=async (data)=>{
        let bred=[]
        
            for (let index = 0; index < breadcrump.length; index++) {
                bred.push(breadcrump[index])
                if(Object.is(data,breadcrump[index])){
                    break
                }
            }
            setActiveParent(data.id)
            setBreadCrump(bred)
       
      }
      const renderBreadCrum=()=>{
        if(breadcrump.length<5){
            let map=breadcrump.map((data,i)=>(
                <div key={i} className='div-flex' >
                    <p  style={{margin:'0px 10px 5px 0px'}} title={data.text}>{data.text.length>17?`${data.text.substring(0,17)}...`:data.text}</p>
                    <img src={ChevronRight} style={{width:10,marginRight:10}}/>
                </div>
             ))
             return map
        }else{
            let new_bread=breadcrump.slice(-4)
            let map=new_bread.map((data,i)=>(
                <div key={i} className='div-flex' style={{cursor:'pointer'}} onClick={()=>i>1?toFolder(data):null}>
                    <p  style={{margin:'0px 10px 5px 0px'}} title={data.text}>{data.text.length>5?`${i===0?'...':''}${data.text.substring(0,5)}...`:i===0?`...${data.text}`:data.text}</p>
                    <img src={ChevronRight} style={{width:10,marginRight:10}}/>
                </div>
             ))
             return map
        }
         
      }
      const toBase64 = file => new Promise((resolve, reject) => {
        console.log('file', file)
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        // reader.loadend = () => resolve(reader.result);
        reader.onerror = error => reject(error);
    });
      const saveFile=async ()=>{
          let fileOnly=[]
          files.upload.map((data)=>{
              if(data.type==='file'&&!data.exist){
                fileOnly.push(data)
              }
          })
          let grouping=groupBy(fileOnly,(fileOnly)=>{
              return fileOnly.parent
          })
          var result = Object.keys(grouping).map((key) => [Number(key), grouping[key]]);
          console.log('result', result)
          if(result.length>0){
            result.map((data)=>{
                let new_file=[]
                let map=data[1].map(async (file)=>{
                  let res=await toBase64(file.files)
                  new_file.push({filename:file.files.name,fileBase64:res.split("base64,")[1]})
                })
                Promise.all(map).then((result)=>{
              
                      let datasent={
                          onegml:0,
                          projectId:files.project_id,
                          parentId:data[0]===files.project_id?0:data[0],
                          userId:props.profile.id,
                          files:new_file,
                          ownerId:0
                      }
                      dispatch(uploadFileBase64(props.token,datasent,null,breadcrump[breadcrump.length-1]))
                  })
            })
          }else{
            dispatch(modalToggle({ 
                modal_open: true,
                modal_title: "Upload file",
                modal_component: "upload_file",
                modal_size:400,
                modal_type:'alert',
                modal_data:{
                    msg:`<p>Updated success</p> `,
                    projectId:files.project_id,
                    parentId:breadcrump[breadcrump.length-1].id,
                    breadcrump
                },
                modal_action:'success_upload',
                // success_msg:success_msg
            }))
          }
          
      }
      const back=()=>{
          if(files.files_action==='add_project'){
              props.tabToggle('info')
          }else{
              props.tabToggle('participant')
          }
      }
    //   console.log('files.detail_after_post', files.detail_after_post)
    return (
        <div >
            <div className='upload-breadcrump div-flex div-space-between' style={{borderBottom:'1px solid #cccccc',height:45,marginBottom:20}}>
                <div className='div-flex'>
                <div className='breadcrump-upload div-flex div-space-between div-align-center'>
                    {renderBreadCrum()}
                    
                </div>
                </div>
                <img onClick={addFolder} src={AddFolder} style={{width:20,cursor:'pointer'}}/>
            </div>
           
            <div {...getRootProps({style})}>
            <input {...getInputProps()} />
                <p className='semi-bold'><span style={{color:'#65B7FF'}}>Add file here,</span> Or drag and drop file here</p>
            </div>
            <br/> 
            <div style={{minHeight:100}}>
                {filesList()}
            </div>
            <MuiThemeProvider theme={themeButton2}>
            <div className='div-flex div-space-between'>
            <Button onClick={()=>back()} size='small' variant='outlined' color='primary' className='btn-remove-capital  btn-rounded'>Back</Button>
            <Button onClick={()=>saveFile()} size='small' variant='contained' color='primary' className='btn-remove-capital  btn-rounded'>Save</Button>
            </div>
            </MuiThemeProvider>
        </div>
    )
}
