import React,{useEffect} from 'react'
import Layout from 'components/Layouts'
import {Button} from '@material-ui/core'
import { MuiThemeProvider, createMuiTheme,withStyles,
} from '@material-ui/core/styles'
import Plus from 'assets/icon/Plus.svg'
import ChevronDown from 'assets/icon/ChevronDown.svg'
import ChevronRight from 'assets/icon/Chevron-right-line.svg'
import Grid from 'assets/icon/Grid.svg'
import List from 'assets/icon/List.svg'
import './style.css'
import { useDispatch, useSelector } from "react-redux";
import {setBreadCrum} from 'redux/actions/master'
import Folder from './folder'
import {modalToggle} from 'redux/actions/general'
import AddFile from './add_file'
import Client from './client'
import {setFilesAction,getQuick,getLast,setYear,setTribeId,setClientId,getFilterProject,setFile,setFolder,getProject,updateFile,uploadFile} from 'redux/actions/files'
import OneGML from '../OneGMl'
import moment from 'moment'
import AddFolder from 'assets/icon/AddFolder.svg'
import AddFiles from 'assets/icon/AddFile.svg'
import {handleFile,getBase64,handleFileMulti} from 'components/handleFile'
const themeButton = createMuiTheme({ 
    palette: { 
        primary: {
            main:'#ffc466',
            contrastText: '#FFFFFF',
        },
        secondary: {
            main:'#ff6e79',
            contrastText: '#FFFFFF',
        }
    } 
})
export default function Index(props) {
    const dispatch=useDispatch()
    const master=useSelector(state=>state.master)
    const files=useSelector(state=>state.files)
    const {bread_crum,files_action,tribe_id,client_id,year}=files
    
    const toFolder=(data,i)=>{
        let bred=[]
        for (let index = 0; index < bread_crum.length; index++) {
            
            bred.push(bread_crum[index])
            if(Object.is(data,bread_crum[index])){
                if(index>2){
                    if(index===3){
                        dispatch(getProject(props.token,`/${files.project_id}/${0}`))
                    }else{
                        dispatch(getProject(props.token,`/${files.project_id}/${data.id}`))
                        
                    }
                }else{
                    // alert('helli ',index)
                    if(index===0){
                        dispatch(setTribeId(data.id))
                        dispatch(setClientId(0))
                        dispatch(setYear(moment().format('YYYY')))
                        dispatch(setFile([]))
                        dispatch(setFolder([]))
                        dispatch(getFilterProject(props.token,`/${data.id}/${0}/${0}/0`))
    
                    }
                    if(index===1){
                        dispatch(setTribeId(tribe_id))
                        dispatch(setClientId(data.id))
                        dispatch(setYear(moment().format('YYYY')))
                        dispatch(setFile([]))
                        dispatch(setFolder([]))
                        dispatch(getFilterProject(props.token,`/${tribe_id}/${data.id}/${0}/0`))
    
                    }
                    if(index===2){
                        dispatch(setTribeId(tribe_id))
                        dispatch(setClientId(client_id))
                        dispatch(setYear(data.text))
                        dispatch(setFile([]))
                        dispatch(setFolder([]))
                        dispatch(getFilterProject(props.token,`/${tribe_id}/${client_id}/${data.text}/0`))
    
                    }
                    if(index===3){
                        dispatch(setFile([]))
                        dispatch(setFolder([]))
                    }
                }
                
                break
            }
        }
        dispatch(setBreadCrum(bred))

    }
    const RenderBreadCrum=()=>{
        if(bread_crum.length<4){
            let a= bread_crum.map((data,i)=>(
                <div key={i} className='div-flex div-space-between div-align-center' style={{paddingRight:20}}>
                    <h3  onClick={()=>toFolder(data,i)} style={{marginRight:20,cursor:'pointer',color:bread_crum.length-1===i?'#252525':'#777777'}}>{data.text.length>35?`${data.text.substring(0,35)}...`:data.text}</h3>
                    <img style={{width:15}} src={bread_crum.length-1===i?ChevronDown:ChevronRight} />
                </div>
            ))
            return a
        }else{
            let new_bread=bread_crum.slice(-3)
           return  new_bread.map((data,i)=>(
                <div key={i} className='div-flex div-space-between div-align-center' style={{paddingRight:20}}>
                    <h3   onClick={()=>toFolder(data,i)} style={{marginRight:20,cursor:'pointer',color:bread_crum.length-1===i?'#252525':'#777777'}}>{i===0?`...${data.text.length>35?data.text.substring(2,data.text.length-33):data.text}`:data.text.length>35?`${data.text.substring(0,35)}...`:data.text}</h3>
                    <img style={{width:15}} src={new_bread.length-1===i?ChevronDown:ChevronRight} />
                </div>
            ))
        }
    }
    const tabToggle=(slug1,slug2)=>{
        dispatch(setFilesAction(slug1,slug2))
    }
    const addFolder=()=>{
        dispatch(modalToggle({
            modal_open: true,
            modal_title: "Create Folder",
            modal_component: "add_folder3",
            modal_data:{
                parentId:bread_crum.length<5?0:bread_crum[bread_crum.length-1].id,
                projectId:files.project_id,
                onegml:0,
                name:'',
                description:''
            } ,
            modal_size:300,
            modal_action:'add_folder3'
        }))
      }
      const onChangeFile=(evt)=>{
        let new_file=[]
        let new_single
        var file = evt.target.files;
        // console.log('file', file)
        if(file.length>1){
            let multi=handleFileMulti(file)
            multi.map(file=>{
                new_file.push(file.file)
            })
        }else{
            new_single= handleFile(evt).file
            // new_file.push(single.file)
        }
        
        let fd=new FormData()
        fd.append('onegml',0)
        fd.append('projectId',files.project_id)
        fd.append('parentId',bread_crum.length<5?0:bread_crum[bread_crum.length-1].id)
        fd.append('userId',props.profile.id)
        fd.append('files',new_single)
        dispatch(uploadFile(props.token,fd,`/${files.project_id}/${bread_crum.length<5?0:bread_crum[bread_crum.length-1].id}`))
        
    }
    return (
        <div>
            {files_action==='onegml'?(<OneGML tabToggle={tabToggle} token={props.token} profile={props.profile}/>):
            <Layout>
                <br/>
                {files_action==='files'&&(
                    <>
                    {bread_crum.length>0&&<>
                    <div className='div-flex div-space-between div-align-center gml-header'>
                        <div className='div-flex' style={{width:'100%'}}>
                            <RenderBreadCrum/>
                        </div>
                        <div style={{display:'flex'}}>
                        {files.bread_crum.length>3&&<div>
                        <Button component='label' onClick={()=>null} size='small'  variant='text'  >
                            <img  style={{width:18,cursor:'pointer'}} src={AddFiles} />

                            <input
                                type="file"
                                // multiple="multiple"
                                style={{ display: "none" }}
                                onChange={onChangeFile}
                            />
                            </Button>
                        </div>}
                        &nbsp;
                        {files.bread_crum.length>3&&<img onClick={addFolder}  style={{width:18,cursor:'pointer'}} src={AddFolder} />}
                        {/* &nbsp;&nbsp;&nbsp;

                        <img style={{width:18}} src={Grid} />
                        &nbsp;&nbsp;&nbsp;
                        <img style={{width:18}} src={List} /> */}
                        </div>
                    </div>
                    <div style={{width:'100%',height:1.3,backgroundColor:'#CCCCCC'}}></div>
                    </>}
                    <Folder token={props.token} profile={props.profile}/></>
                )}
                
                {files_action==='add_project'&&(<AddFile tabToggle={tabToggle} token={props.token} profile={props.profile}/>)}
                {files_action==='add_workshop'&&(<AddFile tabToggle={tabToggle} token={props.token} profile={props.profile}/>)}
                {files_action==='add_client'&&(<Client tabToggle={tabToggle} token={props.token} profile={props.profile}/>)}
                
            </Layout>
            }
        </div>
    )
}
