import React from 'react'
import './style.css'
import { MuiThemeProvider, createMuiTheme,withStyles,makeStyles
} from '@material-ui/core/styles'
import {Button,Modal,TextField,FormControl,InputLabel,OutlinedInput,MenuItem,Select,
    FormHelperText } from '@material-ui/core'
import AutoCompleteSelect from 'components/Select'
import { useDispatch, useSelector } from "react-redux";

import {
    DatePicker,
    TimePicker,
    DateTimePicker,
    MuiPickersUtilsProvider,
    } from '@material-ui/pickers';
    import MomentUtils from '@date-io/moment';
import Project from './project'
import Workshop from './workshop'
import {setFilesAction,resetAddProject} from 'redux/actions/files'
const themeButton2 = createMuiTheme({ 
    palette: { 
        primary:{
            main:'#ffc466',
            contrastText: '#FFFFFF',
        },
        secondary:{
            main:'#65b7ff',
            contrastText: '#FFFFFF',
        },

    } 
})

const useStyles = makeStyles(theme => ({
    textField: {
      [`& fieldset`]: {
        borderRadius: 10,

      },
      width:'100%',
      marginBottom:15,
  },
 
  
}));
export default function Add_file(props) {
    const classes=useStyles()
    const files=useSelector(state=>state.files)
    const dispatch=useDispatch()

    const tabFiles=(slug,slug2)=>{
        dispatch(resetAddProject())
        dispatch(setFilesAction(slug,slug2))
    }
    return (
        <div>
            <div className='head-section'>
                <div className='div-flex div-align-center div-space-between add-file-head' style={{width:350}}>
                    <h4>Add new file</h4>
                    <div className={`div-flex div-align-center div-justify-center btn-add-file ${files.files_action==='add_project'&&'active'}`}>
                        <p onClick={()=>tabFiles('add_project','add_project')}>Project</p>
                    </div>
                    <div className={`div-flex div-align-center div-justify-center btn-add-file ${files.files_action==='add_workshop'&&'active'}`}>
                        <p onClick={()=>tabFiles('add_workshop','add_workshop')}>Workshop</p>
                    </div>
                </div>
            </div>
            {files.files_action==='add_project'&&<Project tabToggle={props.tabToggle} token={props.token} profile={props.profile}/>}
            {files.files_action==='add_workshop'&&<Workshop tabToggle={props.tabToggle} token={props.token} profile={props.profile}/>}
        </div>
    )
}
