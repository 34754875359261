import React,{useState,useEffect} from 'react'
import {Button,TextField,FormControl,InputLabel,OutlinedInput,MenuItem,Select,
    FormHelperText,Modal,FormControlLabel,Checkbox,Table,TableHead,TableRow,TableCell,TableBody} from '@material-ui/core'
import { MuiThemeProvider, createMuiTheme,withStyles,makeStyles } from '@material-ui/core/styles'
import AutoCompleteSelect from 'components/Select'
import {postFolder,postFolder2,putFolder,setUpload} from 'redux/actions/files'
import {useDispatch,useSelector} from 'react-redux'
import {modalToggleReset} from 'redux/actions/general'
import ChipInput from 'material-ui-chip-input'

const themeField = createMuiTheme({  
    palette: {  
        primary: {
            main:'#a8e090',
            contrastText: '#FFFFFF',

        },
        secondary:{
            main:'#65b7ff',
            contrastText: '#FFFFFF',

        }
    } 
})
const themeButton = createMuiTheme({ 
    palette: { 
        primary: {
            main:'#a8e090',
        },
        secondary:{
            main:'#ffc466',
            contrastText: '#FFFFFF',

        }
    } 
})
const useStyles = makeStyles(theme => ({
    textField: {
      [`& fieldset`]: {
        borderRadius: 10,
      },
      width:'100%',
      marginBottom:15
  },
    textField2: {
      [`& fieldset`]: {
        borderRadius: 10,
      },
      width:'100%',
    //   marginBottom:15
  }
  
}));
export default function Add_folder2(props) {
    const classes=useStyles()
    const dispatch=useDispatch()
    const master=useSelector(state=>state.master)
    const files=useSelector(state=>state.files)
    const {bread_crum}=files
    const [state,setState]=useState({
        name:'',
        owner:null,
        deskripsi:''
    })
    useEffect(()=>{
        console.log('props.modal_data', props.modal_data)
        if(props.modal_data!==null){
            setState({
                ...state,
                name:props.modal_data.name,
                deskripsi:props.modal_data.description
            })
        }
    },[])
    const addFolder=async ()=>{
        if(props.modal_action==='edit_folder3'){
            let data_edit={
                id:props.modal_data.id,
                name:state.name,
                parentId:bread_crum.length<5?0:bread_crum[bread_crum.length-1].id,
                projectId:props.modal_data.projectId,
                onegml:props.modal_data.onegml,
                description:state.deskripsi,
                userId:props.profile.id,
            }
            dispatch(putFolder(props.token,data_edit,`/${files.project_id}/${bread_crum.length<5?0:bread_crum[bread_crum.length-1].id}`))
        }else{
            let data={
                id:0,
                name:state.name,
                parentId:props.modal_data.parentId,
                projectId:props.modal_data.projectId,
                onegml:props.modal_data.onegml,
                userId:props.profile.id,
                description:state.deskripsi,

            }
            
            dispatch(postFolder2(props.token,data,`/${files.project_id}/${bread_crum.length<5?0:bread_crum[bread_crum.length-1].id}`))
        }
        
       
    }
    const handleDisable=()=>{
        if(state.deskripsi!==''&&state.name!==''){
            return false
        }else{
            return true
        }
    }
    console.log('state.deskripsi', state.deskripsi)
    return (
        <div>
             <MuiThemeProvider theme={themeField}>
                    <TextField
                        label='Folder name'
                        type='text'
                        value={state.name}
                        onChange={(e)=>setState({...state,name:e.target.value})}
                        variant='outlined'
                        size='small'
                        className={classes.textField}

                    />
                    <TextField
                        label='Deskripsi'
                        type='text'
                        value={state.deskripsi}
                        onChange={(e)=>setState({...state,deskripsi:e.target.value})}
                        variant='outlined'
                        size='small'
                        className={classes.textField}
                        multiline
                    />
             </MuiThemeProvider>
             <MuiThemeProvider theme={themeButton}>
                <div className='modal-footer'>
                    <Button disabled={handleDisable()}  onClick={addFolder}  size='small' color='secondary' variant='contained' className='btn-remove-capital btn-rounded' style={{width:120,height:30}}>{props.modal_action==='edit_folder'?'Update':'Save'}</Button>
                </div>
            </MuiThemeProvider>
        </div>
    )
}
