import * as actionTypes from "../constants/search";

const initialState={
    result:[],
    pagination:{
        page:1,
        perPage:2,
        total:0
    },
    keyword:'',
    doctype:0,
    filetype:'*',
    client:'*'
}
export default (state=initialState,action)=>{

    switch (action.type) {
        
        case actionTypes.SET_SEARCH:
            return{
                ...state,
                [Object.keys(action.payload)]:Object.values(action.payload)[0]
            }
    
            default:

                return state
    }
}